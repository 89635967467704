import { ACCOMMODATION_ERROR } from "../actions/types";

const DEFAULT_STATE = {
  error: "",
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACCOMMODATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
