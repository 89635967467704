import React, { useEffect } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress, Typography, Box } from "@material-ui/core";

import { connect } from "react-redux";
import * as actions from "../actions";

import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";

const AutoLogOut = (props) => {
  useEffect(() => {
    let timer1 = setTimeout(() => callbackLogOut(), 3000);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const callbackLogOut = () => {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    props.history.replace(logoutPath);
  };

  return (
    <>
      <Box textAlign="center">
        <Typography variant="body1">
          Vei reautentificat în câteva momente
        </Typography>
      </Box>
    </>
  );
};

const enhance = compose(withRouter, connect(null, actions));

export default enhance(AutoLogOut);
