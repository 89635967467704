import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Grid, Fab, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  root: {
    // right: "auto"
    position: "fixed",
    bottom: "1.5rem",
    textTransform: "none"
  }
}));

const ExtendedFab = props => {
  const classes = useStyles();
  const { action, text, disabled } = props;
  const Icon = props.icon;
  useEffect(() => {}, []);

  return (
    <>
      <Grid container justify="center">
        <Fab
          classes={{ root: classes.root }}
          color="primary"
          variant="extended"
          size="medium"
          onClick={action}
          disabled={disabled}
        >
          {Icon && <Icon className={classes.extendedIcon} />}
          <Typography variant="body1" noWrap>
            {text}
          </Typography>
        </Fab>
      </Grid>
    </>
  );
};

ExtendedFab.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  icon: PropTypes.object,
  disabled: PropTypes.bool
};

export default ExtendedFab;
