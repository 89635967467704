import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import { connect } from "react-redux";

import * as actions from "../../actions";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  makeStyles,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core";

import LocationCityIcon from "@material-ui/icons/LocationCity";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
  },
  fullWidth: {
    width: "100%",
  },
  listItem: {
    backgroundColor: theme.palette.background.default,
  },
  iconTextField: {
    margin: "30px 16px 0px 0px",
  },
}));

const SelectComplex = ({
  fullWidth,
  value,
  setValue,
  options,
  camine,
  setCamine,
  label,
  setCaminError,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  let initialValue = null;
  let initialCamine = null;

  const displayedOptions = options.map((e) => ({
    value: e.id,
    text: e.spatiiCazare.length === 1 ? e.spatiiCazare[0].denumire : e.denumire,
  }));

  useEffect(() => {
    if (value && camine) {
      initialValue = value;
      initialCamine = camine;
    }
  }, []);

  useEffect(() => {
    setCaminError("");
    if (value && value === initialValue) {
      setCamine(initialCamine);
    } else if (value && value !== initialValue) {
      setCamine(options.find((e) => e.id == value).spatiiCazare);
    } else {
      setCamine(null);
    }
  }, [value]);

  const handleClickArrowUp = (i) => {
    if (i > 0) {
      let tempCamine = [...camine];
      let aux = tempCamine[i - 1];
      tempCamine[i - 1] = tempCamine[i];
      tempCamine[i] = aux;
      setCamine(tempCamine);
    }
  };

  return (
    <>
      <Box margin="1rem .5rem 1rem">
        <Box display="flex" width="100%" flexDirection="column">
          <Box display="flex">
            <LocationCityIcon className={classes.iconTextField} />
            <FormControl
              className={fullWidth ? classes.fullWidth : classes.formControl}
            >
              <InputLabel id="demo-simple-select-label">{label}</InputLabel>
              <Select
                disabled={disabled ? disabled : undefined}
                native
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value ? value : "-"}
                onChange={(e) => {
                  e.target.value === "-"
                    ? setValue(null)
                    : setValue(e.target.value);
                }}
              >
                <option key={-1} value={"-"}>
                  -
                </option>
                {displayedOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
          {camine && camine.length > 1 ? (
            <>
              <Box margin="0.5rem">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                >
                  Prioritate în cadrul complexului
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                >
                  {" "}
                  (<b>modificabil</b>)
                </Typography>
              </Box>
              <List disablePadding>
                <Divider />
                {camine.map((e, i) => (
                  <>
                    <ListItem key={i} dense className={classes.listItem}>
                      <ListItemText primary={`${i + 1}. ${e.denumire}`} />
                      <ListItemSecondaryAction>
                        {i > 0 ? (
                          <IconButton
                            size="small"
                            onClick={() => handleClickArrowUp(i)}
                          >
                            <ArrowUpwardIcon
                              // fontSize="small"
                              style={{ color: green[500] }}
                            />
                          </IconButton>
                        ) : null}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

function mapStateToProps (state) {
  return {};
}

const enhance = compose(
  withRouter,
  withSnackbar,
  connect(mapStateToProps, actions)
);

export default enhance(SelectComplex);
