import React from "react";
import { Card, Box, Typography, Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  card: {
    padding: "1rem",
    margin: "1rem 0 1rem",
    width: "20rem"
  }
});

const InfoCard = props => {
  const classes = useStyles();
  const { icon, title, rows } = props;

  return (
    <>
      <Card className={classes.card} elevation={4}>
        <Box width="100%" display="flex" justifyContent="center">
          {icon}
        </Box>
        <Box
          marginTop="1rem"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography align="center" component="span" variant="h6">
            {title}
          </Typography>
          <Box marginTop="1rem">
            {rows.map((e, i) => (
              <Typography
                key={i}
                align="center"
                component="span"
                display="block"
                variant="body1"
              >
                {e}
              </Typography>
            ))}
          </Box>
        </Box>
      </Card>
    </>
  );
};

InfoCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired
};

export default InfoCard;
