import {
  FETCH_CAZARI_REQUESTED,
  FETCH_CAZARI_ERROR,
  FETCH_CAZARI_SUCCESS,
  FETCH_CAZARE_BY_CERERE_ID_SUCCESS,
  FETCH_CAZARE_BY_ID_SUCCESS,
} from "./types";
import { get } from "../../utils/restUtilities";
import strings from "../../utils/strings";
import { normalize } from "normalizr";
import { cazari, cazariByCerereId } from "./normalizrSchema";

export const fetchCazari = () => async (dispatch) => {
  dispatch({ type: FETCH_CAZARI_REQUESTED });

  try {
    const res = await get(`/api/cazari-studenti`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CAZARI_SUCCESS,
          payload: {
            byId: normalize(res.content, cazari),
            byCerereId: normalize(res.content, cazariByCerereId),
          },
        });
        break;
      default:
        dispatch({ type: FETCH_CAZARI_ERROR, payload: strings.REQUEST_ERROR });
    }
  } catch (err) {
    dispatch({ type: FETCH_CAZARI_ERROR, payload: strings.REQUEST_ERROR });
  }
};

export const fetchCazareById = (cazareId) => async (dispatch) => {
  dispatch({ type: FETCH_CAZARI_REQUESTED });
  try {
    const res = await get(`/api/cazari-studenti/${cazareId}`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CAZARE_BY_ID_SUCCESS,
          payload: res.content,
        });
        break;
      default:
        dispatch({ type: FETCH_CAZARI_ERROR, payload: strings.REQUEST_ERROR });
    }
  } catch (err) {
    dispatch({ type: FETCH_CAZARI_ERROR, payload: strings.REQUEST_ERROR });
  }
};

export const fetchCazareByCerereId = (cerereId) => async (dispatch) => {
  dispatch({ type: FETCH_CAZARI_REQUESTED });
  try {
    const res = await get(`/api/cereri-cazare/${cerereId}/cazare`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CAZARE_BY_CERERE_ID_SUCCESS,
          payload: { ...res.content, cerereId },
        });
        break;
      default:
        dispatch({ type: FETCH_CAZARI_ERROR, payload: strings.REQUEST_ERROR });
    }
  } catch (err) {
    dispatch({ type: FETCH_CAZARI_ERROR, payload: strings.REQUEST_ERROR });
  }
};
