//MUI
import { LinearProgress } from "@material-ui/core";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../actions";
import { PROFIL_SET_USER } from "../../actions/types";
import appRoutes from "../../utils/appRoutes";
import checkRole from "../../utils/checkRole";
import { snackbarWarning } from "../../utils/snackbarVariants";
import strings from "../../utils/strings";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";

class AuthorizeRoleRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
    };
  }

  async componentDidMount() {
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state; //user
    //todo: randat pagina NotFound pentru userii care intra pe rute de admin si nu au claim necesar
    // const redirectUrl = "/";
    const redirectUrl = appRoutes.COMPLETE_PROFILE;
    const redirectUrlNotAuthed = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(window.location.href)}`;

    const { user } = this.props;

    if (!ready) {
      return (
        <div
        //  style={{ marginTop: 20 }}
        >
          {/* <LinearProgress /> */}
        </div>
      );
    } else {
      // console.log("AuthRoleRoute: ", this.props.role);
      // console.log("AuthRoleRoute user: ", user);

      const { component: Component, ...rest } = this.props;
      if (!authenticated) {
        return <Redirect to={redirectUrlNotAuthed} />;
      } else {
        return (
          <Route
            {...rest}
            render={(props) => {
              if (
                // user &&
                // user[
                //   "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                // ] &&
                // user[
                //   "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                // ].indexOf(this.props.role) > -1
                checkRole(user, this.props.role)
              ) {
                //verify if the user has the specified role
                return <Component {...props} />;
              } else {
                this.props.enqueueSnackbar(
                  strings.COMPLETE_YOUR_PROFILE_DESCRIPTION,
                  snackbarWarning
                );
                return <Redirect to={redirectUrl} />;
              }
            }}
          />
        );
      }
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();

    if (!this.props.user) {
      // console.log("NOT FOUND USER IN REDUX -> setting");
      const user = await authService.getUser();
      //this.setState({ user })
      this.props.setAction(user, PROFIL_SET_USER);
    } else {
      // console.log("FOUND USER IN REDUX");
    }

    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false }); //user: null
    await this.populateAuthenticationState();
  }
}

function mapStateToProps(state) {
  return {
    user: state.profile.user,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, actions),
  withSnackbar
);

export default enhance(AuthorizeRoleRoute);
