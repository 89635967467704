import {
  Box,
  FormControl,
  InputLabel,
  makeStyles,
  Select
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 150
  },
  fullWidth: {
    width: "100%"
  }
}));

const SelectCustom = props => {
  const classes = useStyles();
  const {
    label,
    value,
    setValue,
    options,
    defaultOption,
    fullWidth,
    disabled,
    margin
  } = props;

  useEffect(() => {
    if (defaultOption) {
      setValue(options[0].value);
    }
  }, []);

  return (
    <>
      <Box textAlign="center" margin={margin ?? "1rem .5rem 1rem"}>
        <FormControl
          className={fullWidth ? classes.fullWidth : classes.formControl}
        >
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            disabled={disabled ? disabled : undefined}
            native
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value ?? "-"}
            onChange={e => {
              e.target.value === "-"
                ? setValue(null)
                : setValue(e.target.value);
            }}
          >
            {!defaultOption && (
              <option key={-1} value={"-"}>
                -
              </option>
            )}
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option?.text ?? option?.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

SelectCustom.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  defaultOption: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectCustom;
