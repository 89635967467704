import React, { Component } from "react";

import { Link } from "react-router-dom";
import authService from "./AuthorizeService";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { Icon, MenuItem } from "@material-ui/core";
import strings from "../../utils/strings";
import appRoutes from "../../utils/appRoutes";

export class LoginMenu extends Component {
  constructor(props) {
    super(props);
    // console.log("props :", props);
    this.state = {
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount () {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount () {
    authService.unsubscribe(this._subscription);
  }

  async populateState () {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser()
    ]);
    this.setState({
      isAuthenticated,
      userName: user && user.name
    });
  }

  render () {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true }
      };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  authenticatedView (userName, profilePath, logoutPath) {
    return (
      <>
        <Link
          to={appRoutes.PROFILE}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem
            onClick={() => {
              this.props.handleMenuClose();
            }}
          >
            <Icon style={{ marginRight: '.5rem' }}>account_circle</Icon>
            {strings.PROFILE}
          </MenuItem>
        </Link>
        <Link
          to={logoutPath}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>
            <Icon style={{ marginRight: '.5rem' }}>exit_to_app</Icon>
            Sign out
          </MenuItem>
        </Link>
      </>
    );
  }

  anonymousView (registerPath, loginPath) {
    return (
      <>
        <Link
          to={registerPath}
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem>
            <Icon style={{ marginRight: '.5rem' }}>person_add</Icon>
            {strings.REGISTER}
          </MenuItem>
        </Link>
        <Link to={loginPath} style={{ textDecoration: "none", color: "black" }}>
          <MenuItem>
            <Icon style={{ marginRight: '.5rem' }}>person_outline</Icon>
            {strings.LOGIN}
          </MenuItem>
        </Link>
      </>
    );
  }
}
