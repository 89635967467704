import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../actions";
import strings from "../../utils/strings";
import { makeStyles, LinearProgress, Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getCazari } from "../../store/cazari/selectors";
import { fetchCazari } from "../../store/cazari/actions";
import Container from "../utils/Container";
import ListItemCustom from "../utils/ListItemCustom";
import moment from "moment";
import { formatMomentDateWithoutHours } from "../../utils/formatDate";
// import { PDFViewer } from "@react-pdf/renderer";
// import ContractGenerator from "./ContractGenerator";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: "8px 4px 8px",
    fontSize: ".75rem",
    padding: "4px",
  },
  avatarPrezent: {
    backgroundColor: "#52A832",
  },
  avatarTrecut: {
    backgroundColor: "#8f0e00",
  },
  avatarViitor: {
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const Cazari = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const cazari = useSelector((state) => getCazari(state));
  const isFetching = useSelector((state) => state.cazari.isFetching);

  useEffect(() => {
    dispatch(setTitle(strings.CAZARI));
    dispatch(fetchCazari());
  }, []);

  const handleClick = (id) => {
    history.push(`${history.location.pathname}/${id}`);
  };

  const now = moment();
  if (isFetching) {
    return (
      <>
        <LinearProgress />
      </>
    );
  } else {
    return (
      <>
        {/* <PDFViewer width="100%" height="400px">
          <ContractGenerator
            serie="Ci"
            numar="10000"
            data="24.10.2020"
            numeComplet="Iordache C Cătălin"
            codFacultate="CSIE"
            numeCicluStudii="MASTER"
            denumireAnStudiu="2"
            forma="Buget"
            localitate="Bacau"
            judet="Bacau"
            tara="Bacau"
            adresa="Aleea Ghioceilor"
            serieCI="AB"
            numarCI="123456"
            emitentCI="SPCLEP Bacau"
            dataCI="27.10.2019"
            cnp="1970227000000"
            telefon="0712345678"
            email="cata@li.n"
            dataStart="24.09.2020"
            dataStop="24.06.2021"
          />
        </PDFViewer> */}

        <Container
          emptyStateTitle={strings.EMPTY_CAZARI}
          // searchField=""
          items={cazari}
          mapFunction={(cazare, index) => (
            <ListItemCustom
              key={index}
              action={handleClick}
              id={cazare.id}
              avatar={
                <Avatar
                  className={
                    moment(cazare.dataStart) < now && cazare.dataStop > now
                      ? classes.avatarPrezent
                      : moment(cazare.dataStop) < now
                      ? classes.avatarTrecut
                      : classes.avatarViitor
                  }
                >
                  C
                </Avatar>
              }
              primaryText={`${cazare.locCamera?.denumireSpatiu} - Camera ${cazare.locCamera?.numarCamera}`}
              secondaryText={`${formatMomentDateWithoutHours(
                cazare.dataStart
              )} - ${formatMomentDateWithoutHours(cazare.dataStop)}`}
            />
          )}
        />
      </>
    );
  }
};

export default Cazari;
