import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";

import strings from "../../utils/strings";
import DrawerListItem from "./DrawerListItem";

const useStyles = makeStyles({
  list: {
    width: "20rem",
  },
});

const DrawerList = (props) => {
  const classes = useStyles();
  const { setIsOpen, items } = props;

  return (
    <>
      <List className={classes.list}>
        <ListItem>
          <ListItemText
            primary={
              <Typography align="center" variant="h6">
                {strings.APP_NAME}
              </Typography>
            }
          ></ListItemText>
        </ListItem>

        <Divider />

        {items &&
          items.map((e, i) =>
            e.path ? (
              <DrawerListItem
                path={e.path}
                icon={e.icon}
                setIsOpen={setIsOpen}
                text={e.text}
                key={i}
              />
            ) : (
              <Divider />
            )
          )}

        <Divider />
      </List>
    </>
  );
};

export default DrawerList;
