import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  formControlLabel: {
    marginBottom: ".5rem"
    // marginLeft: "0"
  }
}));

const CheckboxCustom = ({ value, setValue, disabled, label }) => {
  const classes = useStyles();
  return (
    <>
      <FormControlLabel
        classes={{ root: classes.formControlLabel }}
        control={
          <Checkbox
            checked={value}
            disabled={disabled}
            onChange={e => setValue(e.target.checked)}
          />
        }
        label={<Typography variant="body2">{label}</Typography>}
      />
    </>
  );
};

export default CheckboxCustom;
