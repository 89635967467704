/*
 * I. Infrastructure classes: EnumValue, Enum
 */

class EnumValue {
  /**
   * Enum element. An Enum object contains a list of such elements.
   * @param {id} id Unique numeric value. Must correspond to the server (C# enum) value.
   * @param {string} name Unique string value. Must correspond to the server (C# enum) value.
   * @param {string} description Description that can be shown inside the user interface
   */
  constructor(id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  /**
   * Determines is the current value is present in the list.
   *
   * @param {EnumValue[]} values List of possible values.
   * @returns {boolean} True if the current value is found in the list.
   */
  isOneOf(values) {
    for (let value of values) {
      if (value === this) {
        return true;
      }
    }

    return false;
  }

  toString() {
    return `${this.name} (#${this.id})`;
  }
}

class Enum {
  /**
   * Enum class (list of EnumValue)
   */
  constructor() {
    this.valori = [];
  }

  /**
   * Internal method - adds a new enum value to the array
   * @param {EnumValue} value - the enum value to add
   * @returns {EnumValue} - the received value
   */
  __addValue(value) {
    this.valori.push(value);
    return value;
  }

  /**
   *
   * @param {string} name test
   * @returns {number} id
   */
  getId(name) {
    name = (name || "").toUpperCase().trim();
    const resultItem = this.valori.find(
      item => item.name.toUpperCase() === name
    );
    return resultItem ? resultItem.id : null;
  }

  /**
   *
   * @param {string} name test
   * @returns {int} position
   */
  getIndexOf(name) {
    name = (name || "").toUpperCase().trim();
    return this.valori.findIndex(item => item.name.toUpperCase() === name);
  }

  /**
   *
   * @param {number} id id
   * @returns {string} name
   */
  getName(id) {
    const resultItem = this.valori.find(item => item.id === id);
    return resultItem ? resultItem.name : "-";
  }

  /** added by Catalin
   *
   * @param {number} id id
   * @returns {string} description
   */
  getDescription(id) {
    const resultItem = this.valori.find(item => item.id === id);
    return resultItem ? resultItem.description : "-";
  }

  /**
   *
   * @param {number} id id
   * @returns {EnumValue} enum value
   */
  getById(id) {
    return this.valori.find(item => item.id === id);
  }

  /**
   *
   * @param {string} name name
   * @returns {EnumValue} enum value
   */
  getByName(name) {
    name = (name || "").toUpperCase().trim();
    return this.valori.find(item => item.name.toUpperCase() === name);
  }

  /**
   * @returns {EnumValue[]} all enum values
   * */
  getValues() {
    return [...this.valori];
  }

  getOptions() {
    return this.valori
      .filter(valoare => typeof valoare.id !== "undefined")
      .map(valoare => ({
        value: valoare.id,
        label: valoare.name
      }));
  }
}

class EnumStatusCovid extends Enum {
  constructor() {
    super();
    this.UNDEFINED = this.__addValue(new EnumValue(undefined, "-", "-"));
    this.VACCINAT = this.__addValue(new EnumValue(1, "Vaccinat", "Vaccinat"));
    this.NEVACCINAT = this.__addValue(
      new EnumValue(2, "Nevaccinat", "Nevaccinat")
    );
    this.VINDECAT = this.__addValue(
      new EnumValue(3, "Vindecat", "Vindecat (trecut prin boală)")
    );
  }
}

class EnumAcordColegNevaccinat extends Enum {
  constructor() {
    super();
    this.UNDEFINED = this.__addValue(new EnumValue(undefined, "-", "-"));
    this.FALSE = this.__addValue(new EnumValue(false, "Nu", "Nu"));
    this.TRUE = this.__addValue(new EnumValue(true, "Da", "Da"));
  }
}

class EnumAcordColocatarAltStatus extends Enum {
  constructor() {
    super();
    this.UNDEFINED = this.__addValue(new EnumValue(undefined, "-", "-"));
    this.FALSE = this.__addValue(new EnumValue(false, "Nu", "Nu"));
    this.TRUE = this.__addValue(new EnumValue(true, "Da", "Da"));
  }
}

class EnumTipSesiune extends Enum {
  constructor() {
    super();
    this.CAZARE = this.__addValue(new EnumValue("cazare", "Cazare", "Cazare"));
    this.REDISTRIBUIRE = this.__addValue(
      new EnumValue("redistribuire", "Redistribuire", "Redistribuire")
    );
  }
}

class EnumTipListareRepartizari extends Enum {
  constructor() {
    super();
    this.PE_FACULTATI = this.__addValue(
      new EnumValue(1, "Pe facultăți", "Pe facultăți")
    );
    this.PE_CAMINE = this.__addValue(
      new EnumValue(2, "Pe cămine", "Pe cămine")
    );
  }
}

class EnumStatusObligatiePlata extends Enum {
  constructor() {
    super();
    this.NEACHITAT = this.__addValue(new EnumValue(1, "Neachitată", ""));
    this.ACHITAT = this.__addValue(new EnumValue(2, "Achitată", ""));
    this.ANULAT = this.__addValue(new EnumValue(3, "Anulată", ""));
  }
}

class EnumMotiv extends Enum {
  constructor() {
    super();
    this.EXAMEN = this.__addValue(new EnumValue(1, "Susținere examen de licență/disertație", ""));
    this.ANGAJARE = this.__addValue(new EnumValue(2, "Angajare", ""));
    this.VOLUNTARIAT = this.__addValue(new EnumValue(3, "Voluntar în cadrul ASE", ""));
    this.PRACTICA = this.__addValue(new EnumValue(4, "Stagiu de Practică", ""));
    this.REZULTATE_PROFESIONALE = this.__addValue(new EnumValue(5, "Rezultate profesionale", ""));
  }
}


// COVID
export const StatusCovid = Object.freeze(new EnumStatusCovid());
export const AcordColegNevaccinat = Object.freeze(
  new EnumAcordColegNevaccinat()
);
export const AcordColocatarAltStatus = Object.freeze(
  new EnumAcordColocatarAltStatus()
);

// SESIUNE
export const TipSesiune = Object.freeze(new EnumTipSesiune());
export const TipListareRepartizari = Object.freeze(
  new EnumTipListareRepartizari()
);
export const Motiv = Object.freeze(new EnumMotiv())

//
export const StatusObligatiePlata = Object.freeze(
  new EnumStatusObligatiePlata()
);
