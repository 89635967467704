import {
  FETCH_CERERI_REQUESTED,
  FETCH_CERERE_BY_SESIUNE_ID_SUCCESS,
  FETCH_CERERI_ERROR,
  STERGERE_CERERE_SUCCESS,
  CREARE_CERERE_SUCCESS,
  MODIFICARE_CERERE_SUCCESS,
  FETCH_CERERI_SUCCESS,
  FETCH_CONTRACANDIDATI_BY_SESIUNE_ID,
  FETCH_CERERE_BY_ID,
} from "../actions/types";

import uniq from "lodash/uniq";

const DEFAULT_STATE = {
  byId: {},
  ids: [],
  bySesiuneId: {},

  contracandidatiByCerereId: {},

  isFetching: false,
  error: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CERERI_REQUESTED:
      return { ...state, isFetching: true };

    case FETCH_CERERE_BY_SESIUNE_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        ids: uniq([...state.ids, action.payload.id]),
        bySesiuneId: {
          ...state.bySesiuneId,
          [action.payload.sesiuneId]: action.payload,
        },
      };

    case FETCH_CERERI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: action.payload.entities.cereri,
        ids: action.payload.result,
      };

    case FETCH_CERERE_BY_ID:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: { ...state.byId, [action.payload.id]: action.payload },
      };

    case FETCH_CONTRACANDIDATI_BY_SESIUNE_ID:
      return {
        ...state,
        isFetching: false,
        error: null,
        contracandidatiByCerereId: {
          ...state.contracandidatiByCerereId,
          [action.payload.cerereId]: action.payload.contracandidati,
        },
      };

    case CREARE_CERERE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        ids: [...state.ids, action.payload.id],
        bySesiuneId: {
          ...state.bySesiuneId,
          [action.payload.sesiuneCazare.id]: action.payload,
        },
      };

    case MODIFICARE_CERERE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
        bySesiuneId: {
          ...state.bySesiuneId,
          [action.payload.sesiuneCazare.id]: action.payload,
        },
      };

    case STERGERE_CERERE_SUCCESS: {
      const sesiuniIds = Object.keys(state.bySesiuneId);
      const sesiuneId = sesiuniIds.find(
        (sesiuneId) =>
          state.bySesiuneId[sesiuneId] &&
          state.bySesiuneId[sesiuneId].id !== action.payload.cerereId
      ); // sesiunea cererii de sters
      if (sesiuneId) {
        return {
          ...state,
          isFetching: false,
          error: null,
          byId: { ...state.byId, [action.payload.cerereId]: undefined },
          ids: [...state.ids.filter((e) => e.id !== action.payload.cerereId)],
          bySesiuneId: { ...state.bySesiuneId, [sesiuneId]: undefined },
        };
      } else {
        return { ...state, isFetching: false, error: null };
      }
    }

    case FETCH_CERERI_ERROR:
      return { ...state, error: action.payload, isFetching: false };

    default:
      return { ...state };
  }
};

export const getCereri = (state) => state.ids.map((id) => state.byId[id]);
