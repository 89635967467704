import strings from "../utils/strings";
import {
  APP_SET_TITLE,
  APP_SET_APP_BAR_BACK_LOCATION,
  APP_SET_IS_LOADING,
  APP_SWITCH_COLOR_MODE,
  APP_SET_GOOGLE_ANALYTICS_CONSENT,
} from "./../actions/types";
import colorMode from "../utils/colorMode";

export const DEFAULT_STATE = {
  title: strings.APP_NAME,
  isLoading: false,
  appBarBackLocation: null,
  colorMode: colorMode.LIGHT,
  googleAnalyticsConsent: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case APP_SET_TITLE:
      return { ...state, title: action.payload };
    case APP_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case APP_SET_APP_BAR_BACK_LOCATION:
      return { ...state, appBarBackLocation: action.payload };

    case APP_SWITCH_COLOR_MODE:
      return {
        ...state,
        colorMode:
          state.colorMode === colorMode.LIGHT
            ? colorMode.DARK
            : colorMode.LIGHT,
      };

    case APP_SET_GOOGLE_ANALYTICS_CONSENT:
      return { ...state, googleAnalyticsConsent: action.payload };

    default:
      return { ...state };
  }
};
