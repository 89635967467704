import { makeStyles, Typography, useTheme } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

const useStyles = makeStyles(theme => ({
  typography: {
    position: "fixed",
    bottom: "6rem",
    right: "2rem",
    display: "flex",
    justifyContent: "center"
  }
}));

const Chat = () => {
  const classes = useStyles();

  const theme = useTheme();
  const chatTheme = {
    background: "#f5f8fb",
    // fontFamily: "Helvetica Neue",
    headerBgColor: theme.palette.primary.main,
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: theme.palette.primary.main,
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a"
  };

  const currentHour = moment().hour();

  const greeting = currentHour < 18 ? "Bună ziua" : "Bună seara";
  const goodbye = currentHour < 18 ? "O zi bună!" : "O seară bună!";

  const steps = [
    {
      id: "first",
      message: `${greeting}, ce vă pot ajuta?`,
      trigger: "options"
    },
    {
      id: "second-first",
      message: "Vă mai pot ajuta cu ceva?",
      trigger: "options"
    },
    // meniu optiuni
    {
      id: "options",
      options: [
        {
          value: 1,
          label:
            "Nu mă mai pot loga în contul meu, deși l-am folosit într-o sesiune trecută. Nu primesc email pentru resetare de parolă.",
          trigger: "r-2"
        },
        {
          value: 2,
          label: "Nu primesc emailul pentru resetarea parolei.",
          trigger: "r-1"
        },     
        {
          value: 3,
          label:
            "Există deja un cont legat de această identitate.",
          trigger: "r-3"
        },
        // default
        {
          value: 99,
          label: "Am altă problemă.",
          trigger: "contact"
        },
        { value: 100, label: "Totul este în regulă", trigger: "end" }
      ]
    },
    // raspunsuri
    {
      id: "r-1",
      message:
        "Emailurile de resetare a parolei se trimit doar pentru conturile de utilizator a căror adresa de email este confirmată.",
      trigger: "second-first"
    },
    {
      id: "r-2",
      message:
        "Din pricina unor dificultăți tehnice întâmpinate de infrastructura ASE în trecut, este posibil ca, la momentul de față, contul respectiv să nu mai existe. " + 
        "Încearcă să te înregistrezi din nou și să-ți validezi identitatea, datele legate de interacțiunea ta cu sistemul vor fi restaurate.",
      trigger: "second-first"
    },
    {
      id: "r-3",
      message:
        "Un student nu poate avea, în același timp, mai multe conturi de utilizator. Încearcă să îți amintești dacă ai folosit o altă adresă de email pentru a te înregistra în trecut în cadrul platformei.",
      trigger: "second-first"
    },
    {
      id: "contact",
      message: "Contactați-ne la suport-cazare@ase.ro",
      trigger: "second-first"
    },
    {
      id: "end",
      message: `${goodbye}`,
      end: true
    }
  ];

  return (
    <>
      <div className={classes.typography}>
        <Typography variant="h6" color="Primary">
          Ajutor
        </Typography>
      </div>
      <ThemeProvider theme={chatTheme}>
        <ChatBot
          placeholder=""
          floating
          // floatingStyle={{ right: "20rem", top: "4px", zIndex: 1200 }}
          steps={steps}
        />
      </ThemeProvider>
    </>
  );
};

export default Chat;
