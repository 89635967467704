import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    justifyContent: "center",
    display: "flex"
  },
  dialogActions: {
    justifyContent: "center",
    display: "flex"
  },
  dialogButtonYes: {
    // paddingRight: theme.spacing(4),
    // paddingLeft: theme.spacing(4),
    margin: theme.spacing(1),
    color: "white"
  },
  dialogButtonNo: {
    margin: theme.spacing(1)
  }
}));

export default function AlertDialog(props) {
  const classes = useStyles();
  const {
    isOpen,
    setIsOpen,
    dialogTitle,
    dialogContentText,
    yesAction,
    isLoading
  } = props;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box padding=".3rem">
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>

          {dialogContentText && (
            <DialogContent className={classes.dialogContent}>
              <DialogContentText id="alert-dialog-description">
                {dialogContentText}
              </DialogContentText>
            </DialogContent>
          )}

          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.dialogButtonNo}
              variant="outlined"
              color="primary"
              onClick={() => {
                setIsOpen(false);
              }}
              disabled={isLoading}
            >
              Anulează
            </Button>

            <Button
              className={classes.dialogButtonYes}
              variant="contained"
              color="primary"
              autoFocus
              onClick={yesAction}
              disabled={isLoading}
            >
              Da
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
