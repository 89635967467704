import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  LinearProgress,
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { LoginMenu } from "../api-authorization/LoginMenu";
import * as actions from "../../actions";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { switchColorMode } from "../../actions";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "block",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: "flex",
  },
  iconButton: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

const AppBarMUI = ({ setIsOpen, isOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const title = useSelector((state) => state.app.title);
  const appBarBackLocation = useSelector(
    (state) => state.app.appBarBackLocation
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="static">
          <Toolbar /*variant='dense'*/>
            {appBarBackLocation ? (
              <IconButton
                onClick={() =>
                  typeof appBarBackLocation === "string"
                    ? history.replace(appBarBackLocation)
                    : typeof appBarBackLocation === "function"
                    ? appBarBackLocation()
                    : null
                }
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="back arrow"
              >
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setIsOpen(!isOpen)}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography className={classes.title} variant="h6" noWrap>
              {title}
            </Typography>

            <div className={classes.grow} />

            <div className={classes.sectionDesktop}>
              <IconButton
                color="inherit"
                onClick={() => dispatch(switchColorMode())}
              >
                <Brightness4Icon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <LoginMenu handleMenuClose={handleMenuClose} />
        </Menu>
      </div>
    </>
  );
};

export default AppBarMUI;
