import {
  FETCH_SESIUNI_REQUESTED,
  FETCH_SESIUNI_ERROR,
  FETCH_SESIUNI_SUCCESS,
  FETCH_SESIUNE_BY_ID_SUCCESS,
} from "../actions/types";

const DEFAULT_STATE = {
  byId: {},
  ids: [],
  isFetching: false,
  error: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SESIUNI_REQUESTED:
      return { ...state, isFetching: true };

    case FETCH_SESIUNI_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        byId: action.payload.entities.sesiuni,
        ids: action.payload.result,
      };

    case FETCH_SESIUNE_BY_ID_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        ids: state.ids.find((id) => id === action.payload.id)
          ? [...state.ids]
          : [...state.ids, action.payload.id],
      };

    case FETCH_SESIUNI_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
};

export const getSesiuni = (state) => state.ids.map((id) => state.byId[id]);
