import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { formatNormalDate } from "../../utils/formatDate";
import MaterialTable from "material-table";
import tableIcons from "../../utils/tableIcons";
import TabelContracandidatiPanelDetalii from "./TabelContracandidatiPanelDetalii";

const useStyles = makeStyles((theme) => ({}));

export default ({ contracandidati }) => {
  const classes = useStyles();

  const contracandidatiExists = contracandidati && contracandidati.length;
  const data =
    (contracandidatiExists &&
      contracandidati.map((e) => ({
        ...e.deponent,
        inregistratLa: formatNormalDate(e.inregistratLa),
        optiuniCazare: e.optiuniCazare,
      }))) ||
    [];

  // console.log("data :", data);
  if (contracandidatiExists) {
    return (
      <Box margin="2rem 0 2rem">
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "Nume", field: "numeComplet" },
            { title: "Facultate", field: "denumireFacultate" },
            { title: "Specializare", field: "denumireProgramStudiu" },
            { title: "An", field: "denumireAnStudiu" },
            // { title: "Data înregistrării", field: "inregistratLa" },
            {
              title: "Medie cazare",
              field: "medieCazare",
              defaultSort: "desc",
            },
          ]}
          data={data}
          title="Cereri"
          detailPanel={[
            {
              tooltip: "Detalii",
              render: (rowData) => {
                return (
                  <Box width="100%" height="315">
                    <TabelContracandidatiPanelDetalii
                      optiuniCazare={rowData.optiuniCazare}
                      medieCazare={rowData.medieCazare}
                    />
                  </Box>
                );
              },
            },
          ]}
          onRowClick={(e, rowData, togglePanel) => {
            togglePanel();
          }}
          options={{
            pageSizeOptions: [20, 50, 100],
            pageSize: 20,
            debounceInterval: 200,
          }}
        />
      </Box>
    );
  } else {
    return <></>;
  }
};
