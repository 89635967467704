import { post, get, put, del } from "../utils/restUtilities";
import { ACCOMMODATION_ERROR } from "./types";
import strings from "../utils/strings";

export const postAction = (
  data,
  url,
  successType,
  errorType
) => async dispatch => {
  try {
    const res = await post(url, data);
    switch (res.status) {
      case 200:
      case 201:
        dispatch({
          type: successType,
          payload: res.content
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch({
      type: errorType ? errorType : ACCOMMODATION_ERROR,
      payload: strings.REQUEST_ERROR
    });
  }
};

export const getAction = (url, successType, errorType) => async dispatch => {
  try {
    const res = await get(url);
    switch (res.status) {
      case 200:
        dispatch({
          type: successType,
          payload: res.content
        });
        break;
      case 404:
        dispatch({
          type: errorType ? errorType : ACCOMMODATION_ERROR,
          payload: strings.REQUEST_ERROR_404
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch({
      type: errorType ? errorType : ACCOMMODATION_ERROR,
      payload: strings.REQUEST_ERROR
    });
  }
};

export const putAction = (
  data,
  url,
  successType,
  errorType
) => async dispatch => {
  try {
    const res = await put(url, data);
    switch (res.status) {
      case 200:
        dispatch({
          type: successType,
          payload: res.content
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch({
      type: errorType ? errorType : ACCOMMODATION_ERROR,
      payload: strings.REQUEST_ERROR
    });
  }
};

export const deleteAction = (url, successType, errorType) => async dispatch => {
  try {
    const res = await del(url);
    switch (res.status) {
      case 200:
      case 204:
        dispatch({
          type: successType,
          payload: res.content
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch({
      type: errorType ? errorType : ACCOMMODATION_ERROR,
      payload: strings.REQUEST_ERROR
    });
  }
};

export const setAction = (value, type) => dispatch => {
  dispatch({
    type: type,
    payload: value
  });
};
