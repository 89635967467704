import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchAniStudiu,
  fetchFacultati,
  setBackLocation,
  setTitle
} from "../../actions";
import appRoutes from "../../utils/appRoutes";
import { snackbarError, snackbarSuccess } from "../../utils/snackbarVariants";
import strings from "../../utils/strings";
import { CnpSchema } from "../../utils/validations";
import authService, {
  AuthenticationResultStatus
} from "../api-authorization/AuthorizeService";
import ExtendedFab from "../utils/ExtendedFab";
import TextFieldCustom from "../utils/TextFieldCustom";
import { get, patch } from "./../../utils/restUtilities";
// import { fetchAniStudiu } from "./../../actions/profil";
import IdentitateListItem from "./IdentitateListItem";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "1rem"
  },
  circularProgress: {
    marginTop: "2rem",
    textAlign: "center"
  },
  divListaIdentitati: {
    margin: "2rem 0 2rem"
  },
  typographyMargin: {
    margin: "1rem 0 1rem"
  },
  divButtons: {
    marginTop: "2rem",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }
}));

const CnpForm = ({ setStep }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const isActualizare = location?.pathname.split("/")?.[2] === "actualizare";

  const [validareCnpIsFetching, setValidareCnpIsFetching] = useState(false);
  const [facultatiIsFetching, setFacultatiIsFetching] = useState(false);
  const [identitati, setIdentitati] = useState(null);

  const identitate = useSelector(state => state.profile.identitate);

  // fields
  const [cnp, setCnp] = useState("");

  // error fields
  const [cnpError, setCnpError] = useState("");

  const fetchFacultatiAniStudiu = async () => {
    setFacultatiIsFetching(true);
    await Promise.all([dispatch(fetchFacultati()), dispatch(fetchAniStudiu())]);
    setFacultatiIsFetching(false);
  };

  // CDM
  useEffect(() => {
    if (isActualizare) {
      if (identitate) {
        setCnp(identitate.cnp ?? "");
      } else {
        history.replace(appRoutes.PROFILE);
      }
    }
    fetchFacultatiAniStudiu();
    dispatch(setBackLocation(appRoutes.PROFILE));
    dispatch(
      setTitle(
        isActualizare
          ? strings.ACTUALIZARE_IDENTITATE
          : strings.COMPLETE_YOUR_PROFILE
      )
    );

    return () => {
      dispatch(setBackLocation(null));
    };
  }, []);

  useEffect(() => {
    if (cnp.length > 13) {
      setCnpError("CNP-ul trebuie să conțină maxim 13 cifre");
    } else {
      setCnpError("");
    }
    if (identitati) {
      setIdentitati(null);
    }
  }, [cnp]);

  const validareCnp = async res => {
    console.log("Validare CNP");
    setValidareCnpIsFetching(true);
    try {
      const response = await get(`/api/info/identitate?cnp=${res.cnp}`);
      switch (response.status) {
        case 200:
          // console.log("200");
          setIdentitati(response.content); // de decomentat
          break;
        case 409:
          // console.log("409");

          // const response2 = await patch(`/api/info/identitate?cnp=${res.cnp}`);
          // if (response2?.status === 200) {
          //   enqueueSnackbar(strings.IDENTITATE_VALIDATA, snackbarSuccess);
          //   history.push(appRoutes.PROFILE);
          //   const returnUrl = appRoutes.PROFILE;
          //   const result = await authService.signIn(returnUrl);
          //   switch (result.status) {
          //     case AuthenticationResultStatus.Success:
          //       window.location.replace(returnUrl);
          //       break;
          //     default:
          //       history.push(appRoutes.AUTO_LOG_OUT);
          //       break;
          //   }
          // } else {
          setIdentitati(null);
          setCnpError(`${strings.CONT_EXISTENT} (${response?.content?.email})`);
          enqueueSnackbar(
            `${strings.CONT_EXISTENT} (${response?.content?.email})`,
            snackbarError
          );
          // }
          break;
        default:
          console.log("DEFAULT");
          setIdentitati(null);
          enqueueSnackbar(strings.CNP_INVALID, snackbarError);
          break;
      }
    } catch (err) {
      console.log("err :>> ", err);
      setIdentitati([]);
      enqueueSnackbar(strings.REQUEST_ERROR, snackbarError);
    }
    setValidareCnpIsFetching(false);
  };

  const catchErrors = err => {
    console.log("err :", err);
    enqueueSnackbar(strings.VALIDATION_ERROR, snackbarError);
    const errors = err.inner;
    let pathMarker = "";
    errors.forEach(error => {
      if (error.path !== pathMarker) {
        switch (error.path) {
          case "cnp":
            setCnpError(error.message);
            break;
          default:
            break;
        }
      }
      pathMarker = error.path;
    });
  };

  const handleClickValidareCnp = async () => {
    const dataToValidate = {
      cnp: cnp.trim()
    };
    CnpSchema.validate(dataToValidate, { abortEarly: false })
      .then(validareCnp)
      .catch(catchErrors);
  };

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Typography variant="body1" align="center">
          {strings.COMPLETE_CNP_DESCRIPTION}
        </Typography>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleClickValidareCnp();
          }}
        >
          <TextFieldCustom
            error={cnpError}
            setError={setCnpError}
            fullWidth
            label={strings.CNP_HELPER_TEXT}
            value={cnp}
            setValue={setCnp}
            variant="outlined"
            disabled={
              facultatiIsFetching ||
              validareCnpIsFetching ||
              Boolean(identitati && identitati.length) || isActualizare
            }
          // disabled={isActualizare}
          />
        </form>
      </Paper>

      {identitati ? (
        <div className={classes.divListaIdentitati}>
          {identitati.length ? (
            <>
              <Typography className={classes.typographyMargin} align="center">
                {strings.ALEGE_IDENTITATE}
              </Typography>
              {identitati.map((identitate, index) => (
                <IdentitateListItem
                  key={index}
                  index={index}
                  identitate={identitate}
                  setStep={setStep}
                />
              ))}
            </>
          ) : !validareCnpIsFetching ? (
            <Typography align="center">{strings.CNP_NU_EXISTA}</Typography>
          ) : null}
        </div>
      ) : null}

      {Boolean(!identitati || identitati?.length === 0) && (
        <ExtendedFab
          action={handleClickValidareCnp}
          disabled={
            validareCnpIsFetching ||
            facultatiIsFetching ||
            cnp?.length < 1 ||
            cnp?.length > 13
          }
          text={strings.VALIDEAZA}
          icon={CheckIcon}
        />
        // <div className={classes.divButtons}>
        //   <div>
        //     <Button
        //       style={{ marginTop: "1rem" }}
        //       color="primary"
        //       variant="contained"
        //       onClick={handleClickValidareCnp}
        //       disabled={
        //         validareCnpIsFetching ||
        //         facultatiIsFetching ||
        //         cnp?.length < 1 ||
        //         cnp?.length > 13
        //       }
        //     >
        //       {strings.VALIDEAZA}
        //     </Button>
        //   </div>
        // </div>
      )}

      {Boolean(validareCnpIsFetching || facultatiIsFetching) && (
        <div className={classes.circularProgress}>
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
};

export default CnpForm;
