import {
  ACTUALIZARE_IDENTITATE_SUCCESS,
  FETCH_ANI_STUDIU,
  FETCH_FACULTATI,
  FETCH_IDENTITATE_REQUESTED,
  FETCH_IDENTITATE_SUCCESS,
  FETCH_PROFIL_ERROR,
  PATCH_COVID,
  PROFIL_SET_USER,
  SET_IDENTITATE_DE_VALIDAT,
  VALIDARE_CNP_SUCCESS,
  VALIDARE_IDENTITATE_SUCCESS
} from "../actions/types";

const DEFAULT_STATE = {
  identitate: null, // pentru profil
  error: null,
  isFetching: false,

  // formular
  facultati: [],
  aniStudiu: [],
  identitateDeValidat: null,

  // identitateDeValidat: {
  //   idInscriere: 398894,
  //   nume: "IORDACHE",
  //   initiale: "C",
  //   prenume: "CĂTĂLIN",
  //   cnp: "1970227000000",
  //   serieCI: "ZC",
  //   numarCI: "312334",
  //   adresaDomiciliu: "ALEEA GHIOCEILOR",
  //   localitateDomiciliu: "BACĂU",
  //   judetDomiciliu: "Bacău  ",
  //   cetatenie: "Română",
  //   nationalitate: "Română",
  //   medieCazare: null,
  //   facultateId: 15,
  //   programStudiuId: 85,
  //   anStudiuId: 1,
  //   emitentCI: "Bacau",
  //   formaFinantareSemestrul1: "",
  //   formaFinantareSemestrul2: "",
  //   idPersoana: 1,
  //   dataCI: "",
  //   telefon: "0700000000",
  // },

  user: null //user from the authService
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_IDENTITATE_REQUESTED:
      return { ...state, isFetching: true };

    case FETCH_IDENTITATE_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        identitate: action.payload
      };

    case PROFIL_SET_USER:
      return { ...state, error: null, user: action.payload };

    case VALIDARE_CNP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        identitateDeValidat: action.payload
      };

    case SET_IDENTITATE_DE_VALIDAT:
      return {
        ...state,
        identitateDeValidat: action.payload
      };

    case VALIDARE_IDENTITATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null
      };

    case ACTUALIZARE_IDENTITATE_SUCCESS:
      return {
        ...state,
        identitate: action.payoad,
        isFetching: false,
        error: null
      };

    case FETCH_FACULTATI:
      return {
        ...state,
        facultati: action.payload,
        error: null
      };

    case FETCH_ANI_STUDIU:
      return {
        ...state,
        aniStudiu: action.payload,
        error: null
      };

    case FETCH_PROFIL_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };

    case PATCH_COVID:
      return {
        ...state,
        isFetching: false,
        error: null,
        identitate: {
          ...state.identitate,
          statusCovid: action.payload?.statusCovid,
          acordColegNevaccinat: action.payload?.acordColegNevaccinat,
          acordColocatarAltStatus: action.payload?.acordColocatarAltStatus,
          raportCovidLa: action.payload?.raportCovidLa
        }
      };

    default:
      return { ...state };
  }
};
