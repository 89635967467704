import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import statusuriPlati, { getDenumireStatusPlata } from "./statusuriPlati";
import { getDenumireTipPlata } from "./tipuriPlati";
import strings from "../../../utils/strings";
import LabelWithValue from "../../utils/LabelWithValue";
import { formatMomentDateWithoutHours } from "../../../utils/formatDate";
import PaymentIcon from "@material-ui/icons/Payment";
import crypto from "crypto";
import { useSnackbar } from "notistack";
import {
  snackbarError,
  snackbarWarning
} from "../../../utils/snackbarVariants";
import { patch } from "../../../utils/restUtilities";
import moment from "moment";
import authService from "../../api-authorization/AuthorizeService";

const useStyles = makeStyles(theme => ({
  rootDialog: {
    padding: "2rem",
    minWidth: "500px"
  },
  divButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2rem",
    marginBottom: "1rem"
  }
}));

function pad2(n) {
  return n < 10 ? "0" + n : n;
}

const ObligatiePlataDialog = ({
  isOpen,
  setIsOpen,
  id,
  cuantum,
  luna,
  scadentLa,
  denumire,
  descriere,
  status,
  tip,
  deponent,
  cazareId,
  concurrencyStamp
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => setIsOpen(false);
  const [patchIsFetching, setPatchIsFetching] = useState(false);

  const paymentIsDisabled = concurrencyStamp
    ? Boolean(
        moment() <
          moment(concurrencyStamp)
            .add(moment(concurrencyStamp).utcOffset(), "minute")
            .add(15, "minutes")
      )
    : false;

  const triggerPayment = async () => {
    var date = new Date();
    const timestamp =
      date.getFullYear().toString() +
      pad2(date.getMonth() + 1) +
      pad2(date.getDate()) +
      pad2(date.getHours()) +
      pad2(date.getMinutes()) +
      pad2(date.getSeconds());
    const user = await authService.getUser();
    const email = user.name ?? undefined;
    const mid = process.env.REACT_APP_PAYMENT_MID;
    const key = process.env.REACT_APP_PAYMENT_KEY;
    if (mid && key) {
      const data = {
        amount: cuantum.toString(),
        curr: "RON",
        invoice_id: id.toString(),
        order_desc: denumire,
        merch_id: mid,
        // timestamp: Date.now().toString(),
        timestamp: timestamp,
        nonce: crypto.randomBytes(32).toString("hex")
      };

      const datakeys = Object.keys(data);
      let hmac = "";
      for (let i = 0; i < datakeys.length; i++) {
        if (data[datakeys[i]].length == 0) {
          hmac += "-";
        } else {
          hmac += data[datakeys[i]].length + data[datakeys[i]];
        }
      }

      const binKey = new Buffer(key, "hex");

      const hmacx = crypto
        .createHmac("md5", binKey)
        .update(hmac, "utf8")
        .digest("hex");
      data["fp_hash"] = hmacx;

      //campuri aditionale - info client
      data["fname"] = deponent.numeComplet;
      // data["lname"] = "Ion";
      //+altele

      document.getElementById("amount").value = data["amount"];
      document.getElementById("curr").value = data["curr"];
      document.getElementById("invoice_id").value = data["invoice_id"];
      document.getElementById("order_desc").value = data["order_desc"];
      document.getElementById("merch_id").value = data["merch_id"];
      document.getElementById("timestamp").value = data["timestamp"];
      document.getElementById("nonce").value = data["nonce"];
      document.getElementById("fp_hash").value = data["fp_hash"];
      document.getElementById("fname").value = data["fname"];
      document.getElementById("email").value = email;
      // document.getElementById("lname").value = data["lname"];

      document.getElementById("ExtraData[silenturl]").value =
        process.env.REACT_APP_PAYMENT_SILENT_URL;
      document.getElementById("ExtraData[successurl]").value =
        process.env.REACT_APP_PAYMENT_SUCCESS_URL;
      document.getElementById("ExtraData[failedurl]").value =
        process.env.REACT_APP_PAYMENT_FAILED_URL;

      if (process.env.NODE_ENV === "development") {
        // sha1
        let shasum = crypto
          .createHash("sha1")
          .update(key + id.toString(), "utf8")
          .digest("hex");

        document.getElementById("ExtraData[test]").value = shasum;
      }

      document.getElementById("form").submit();
    } else {
      enqueueSnackbar(strings.REQUEST_ERROR, snackbarError);
    }
  };

  const onClickPlateste = async () => {
    try {
      setPatchIsFetching(true);
      const res = await patch(
        `/api/cazari-studenti/${cazareId}/obligatii-plata/${id}`,
        {}
      );
      if (res?.status === 200) {
        await triggerPayment();
      } else if (res?.status === 409) {
        enqueueSnackbar(strings.CONFLICT_PAYMENT, snackbarWarning);
        setIsOpen(false);
        setPatchIsFetching(false);
      } else {
        throw new Error();
      }
    } catch (err) {
      enqueueSnackbar(strings.REQUEST_ERROR, snackbarError);
      setPatchIsFetching(false);

      console.log("err :>> ", err);
    }
  };
  return (
    <>
      <form
        style={{ display: "none" }}
        action="https://secure.euplatesc.ro/tdsprocess/tranzactd.php"
        method="POST"
        id="form"
      >
        <input type="hidden" id="amount" name="amount" value="" />
        <input type="hidden" id="curr" name="curr" value="" />
        <input type="hidden" id="invoice_id" name="invoice_id" value="" />
        <input type="hidden" id="order_desc" name="order_desc" value="" />
        <input type="hidden" id="merch_id" name="merch_id" value="" />
        <input type="hidden" id="timestamp" name="timestamp" value="" />
        <input type="hidden" id="nonce" name="nonce" value="" />
        <input type="hidden" id="fp_hash" name="fp_hash" value="" />
        <input type="hidden" id="fname" name="fname" value="" />
        <input type="hidden" id="email" name="email" value="" />

        {/* <input type="hidden" id="lname" name="lname" value="" /> */}

        <input
          type="hidden"
          id="ExtraData[silenturl]"
          name="ExtraData[silenturl]"
          value=""
        />
        <input
          type="hidden"
          id="ExtraData[successurl]"
          name="ExtraData[successurl]"
          value=""
        />
        <input
          type="hidden"
          id="ExtraData[failedurl]"
          name="ExtraData[failedurl]"
          value=""
        />
        <input
          type="hidden"
          id="ExtraData[ep_method]"
          name="ExtraData[ep_method]"
          value="get"
        />
        {process.env.NODE_ENV === "development" && (
          <input
            type="hidden"
            id="ExtraData[test]"
            name="ExtraData[test]"
            value=""
          />
        )}
      </form>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        classes={{ root: classes.rootDialog }}
      >
        <DialogTitle>Detaliile obligației de plată</DialogTitle>

        <DialogContent>
          <LabelWithValue
            label={strings.STATUS}
            value={getDenumireStatusPlata(status)}
            margin
          />
          <LabelWithValue
            label={strings.TIP}
            value={getDenumireTipPlata(tip)}
            margin
          />
          <LabelWithValue
            label={strings.CUANTUM}
            value={`${cuantum} RON`}
            margin
          />
          <LabelWithValue label={strings.DENUMIRE} value={denumire} margin />
          <LabelWithValue label={strings.DESCRIERE} value={descriere} margin />
          <LabelWithValue
            label={strings.LUNA}
            value={luna ? luna.toString() : null}
            margin
          />
          <LabelWithValue
            label={strings.SCADENT_LA}
            value={scadentLa ? formatMomentDateWithoutHours(scadentLa) : null}
            margin
          />
          <div className={classes.divButtons}>
            {status === statusuriPlati.NEACHITAT && (
              <Tooltip
                title={paymentIsDisabled ? strings.CONFLICT_PAYMENT : ""}
              >
                <span>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<PaymentIcon />}
                    onClick={onClickPlateste}
                    disabled={patchIsFetching || paymentIsDisabled}
                  >
                    {strings.PLATESTE}
                  </Button>
                </span>
              </Tooltip>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ObligatiePlataDialog;
