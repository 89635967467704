import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold",
  },
  margin: {
    margin: "8px 0 8px",
  },
});

export default ({ label, value, bold, margin, variant }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={margin ? classes.margin : undefined}>
        <Typography
          className={bold ? classes.bold : null}
          display={variant === "vertical" ? "block" : "inline"}
          variant="body2"
          color="textSecondary"
        >
          {`${label}`}
          <span>{variant === "vertical" ? null : ": "}</span>
        </Typography>
        <Typography
          className={bold ? classes.bold : null}
          display={variant === "vertical" ? "block" : "inline"}
          variant="body2"
        >
          {value ? value : "-"}
        </Typography>
      </Box>
    </>
  );
};
