export const snackbarError = {
  variant: "error",
  autoHideDuration: 3000,
};

export const snackbarWarning = {
  variant: "warning",
  autoHideDuration: 3000,
};

export const snackbarSuccess = {
  variant: "success",
  autoHideDuration: 3000,
};

export const snackbarInfo = {
  variant: "info",
  autoHideDuration: 3000,
};
