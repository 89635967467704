const applyAllFilters = (dataArray, search, filters) => {
  let newItems = [...dataArray];
  let afterSearchItems = [];
  let afterFiltersItems = [];

  //1. Apply search
  if (search.value) {
    for (let i = 0; i < newItems.length; i++) {
      if (
        newItems[i][search.field]
          .toLowerCase()
          .indexOf(search.value.toLowerCase()) > -1
      ) {
        afterSearchItems.push(newItems[i]);
      }
    }
  } else {
    afterSearchItems = newItems;
  }

  //2. Apply filters
  let allow = false;
  let nrFilters = 0;
  let nrSelectedFilters = 0;
  let field = "";

  if (filters && filters.value.length) {
    for (let x = 0; x < filters.value.length; x++) {
      if (filters.value[x]) {
        allow = true;
        nrSelectedFilters++;
        // console.log("SELECTED FILTER")
      }
    }

    if (!allow) {
      afterFiltersItems = afterSearchItems;
    } else {
      for (let j = 0; j < afterSearchItems.length; j++) {
        nrFilters = 0;
        for (let i = 0; i < filters.value.length; i++) {
          //for every filter
          if (filters.value[i]) {
            // console.log("filters[i]: ", filters[i])
            field = filters.availableFilters[i].field;
            if (afterSearchItems[j][field].toString() === filters.value[i]) {
              // console.log("nrFilters++")
              nrFilters++;
            }
          }
        }
        // console.log("nrFilters: ", nrFilters, "nrSelectedFilters: ", nrSelectedFilters)
        if (nrFilters === nrSelectedFilters) {
          afterFiltersItems.push(afterSearchItems[j]);
        }
      }
    }
  } else {
    afterFiltersItems = afterSearchItems;
  }

  return afterFiltersItems;
};

export default applyAllFilters;
