import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  Button,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import File from "./File";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { acceptedFileFormats, maxSize } from "../../utils/filesUtils";

const FilesWrapper = ({
  type,
  files,
  cerereCazareId,
  handleDeleteFile,
  onChangeFile,
  animated,
  showMaxSize,
  isRemote,
}) => {
  const classes = useStyles();
  //image dialog
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [selectedImageForDialog, setSelectedImageForDialog] = useState(null);

  const onClickOpenDialog = (src) => {
    setSelectedImageForDialog(src);
    setIsImageDialogOpen(true);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        // alignItems="center"
        flexWrap="wrap"
      >
        {files &&
          files.map((e, i) => {
            return (
              e && (
                <>
                  <Box key={i} className={classes.container}>
                    {type === "existing" ? (
                      <>
                        {acceptedFileFormats.IMAGE.includes(
                          e.isNew ? e.file.type : e.format
                        ) || Object.keys(acceptedFileFormats)[0] === e?.type ? (
                          <File
                            variant="sm"
                            animated={animated}
                            type="image"
                            file={{
                              src: e.isNew
                                ? [e.src]
                                : [
                                    `${window.location.origin}/api/cereri-cazare/${cerereCazareId}/media-contents?contentId=${e.id}`,
                                  ],
                            }}
                            onClick={() =>
                              onClickOpenDialog(
                                e.isNew
                                  ? [e.src]
                                  : [
                                      `${window.location.origin}/api/cereri-cazare/${cerereCazareId}/media-contents?contentId=${e.id}`,
                                    ]
                                //  [
                                //     `${
                                //       window.location.origin
                                //     }/api/questions/${cerereCazareId}/${
                                //       isRemote
                                //         ? "remote-media"
                                //         : "local-media"
                                //     }?contentId=${isRemote ? e.uri : e.id}`,
                                //   ]
                              )
                            }
                          />
                        ) : (
                          <File
                            variant="sm"
                            animated={animated}
                            type="other"
                            file={{
                              src: e.isNew
                                ? e.src
                                : [
                                    `${window.location.origin}/api/cereri-cazare/${cerereCazareId}/media-contents?contentId=${e.id}`,
                                  ],
                              // `${
                              //     window.location.origin
                              //   }/api/questions/${cerereCazareId}/${
                              //     isRemote ? "remote-media" : "local-media"
                              //   }?contentId=${isRemote ? e.uri : e.id}`,
                              name: e.isNew ? e.file.name : e.name,
                            }}
                          />
                        )}

                        {handleDeleteFile ? (
                          <Box
                            className={`${classes.footerBase} ${classes.delete}`}
                          >
                            <IconButton
                              onClick={() =>
                                handleDeleteFile(
                                  e,
                                  e.isNew ? "new" : "existing"
                                )
                              }
                              size="small"
                            >
                              <CancelIcon color="error" />
                            </IconButton>
                          </Box>
                        ) : (
                          showMaxSize && (
                            <Box
                              className={`${classes.footerBase} ${classes.delete}`}
                            >
                              <Typography
                                variant="caption"
                                color="error"
                              >{`Max ${maxSize.name}`}</Typography>
                            </Box>
                          )
                        )}
                      </>
                    ) : (
                      type === "placeholder" && (
                        <File
                          variant="sm"
                          animated={animated}
                          type="placeholder"
                          file={e}
                          index={i}
                          onClick={onChangeFile}
                        />
                      )
                    )}
                    {e.description ? (
                      <Box
                        className={`${classes.footerBase} ${classes.description}`}
                      >
                        <Typography variant="caption">
                          {e.description}
                        </Typography>
                      </Box>
                    ) : (
                      showMaxSize && (
                        <Box
                          className={`${classes.footerBase} ${classes.delete}`}
                        >
                          <Typography
                            variant="caption"
                            color="error"
                          >{`Max ${maxSize.name}`}</Typography>
                        </Box>
                      )
                    )}
                  </Box>
                </>
              )
            );
          })}
      </Box>

      <Dialog
        fullWidth={false}
        maxWidth="xl"
        open={isImageDialogOpen}
        onClose={() => setIsImageDialogOpen(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <Box display="flex" justifyContent="center">
          <File
            variant="full"
            type="image"
            file={{ src: selectedImageForDialog }}
          />
        </Box>
        <DialogActions>
          <Button onClick={() => setIsImageDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilesWrapper;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    margin: "1rem",
    maxWidth: "160px",
  },
  othersWrapper: {
    width: "106px",
    height: "106px",
    border: `px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    wordBreak: "break-word",
    // textOverflow: "ellipsis",
    backgroundColor: theme.palette.background.default,

    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    padding: "2px",
  },
  footerBase: {
    // marginTop: "0.5rem",
    minHeight: "40px",
  },
  delete: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    paddingTop: "10px",
  },
}));

FilesWrapper.propTypes = {
  type: PropTypes.oneOf(["existing", "placeholder"]),
  files: PropTypes.array,
  cerereCazareId: PropTypes.number,
  handleDeleteFile: PropTypes.func,
  animated: PropTypes.bool,
  showMaxSize: PropTypes.bool,
};
