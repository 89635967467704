import { schema } from "normalizr";

export const cazariByCerereId = [
  new schema.Entity(
    "cazariByCerereId",
    {},
    { idAttribute: (value) => value.cerereCazare.id }
  ),
];

export const cazari = [new schema.Entity("cazari")];
