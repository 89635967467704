import { Box, Button, Divider, Paper, Typography } from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FaceIcon from "@material-ui/icons/Face";
import MenuIcon from "@material-ui/icons/Menu";
import MessageIcon from "@material-ui/icons/Message";
import { makeStyles } from "@material-ui/styles";
import get from "lodash/get";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setBackLocation, setTitle, stergereCerere } from "../../actions";
import { fetchCazareByCerereId } from "../../store/cazari/actions";
import appRoutes from "../../utils/appRoutes";
import { Motiv } from "../../utils/enums";
import { formatMomentDate } from "../../utils/formatDate";
import { snackbarError, snackbarSuccess } from "../../utils/snackbarVariants";
import toTitleCase from "../../utils/toTitleCase";
import AlertDialog from "../utils/AlertDialog";
import FilesWrapper from "../utils/FilesWrapper";
import HeaderWithDivider from "../utils/HeaderWithDivider";
import LabelWithValue from "../utils/LabelWithValue";
import strings from "./../../utils/strings";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "0 0 2rem",
    padding: "1rem",
    minWidth: 300
  },
  indent: {
    marginLeft: "1rem"
  },
  checkColor: {
    color: "#008a25",
    fontWeight: "bold"
  },
  button: {
    width: "120px"
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "120px"
    // marginLeft: ".5rem",
  },
  divButtons: {
    marginTop: "1rem",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center"
    },
    justifyContent: "flex-end"
  }
}));

const CardCerere = ({
  cerere,
  setIsEditing,
  readOnly,
  deleteIsEnabled,
  editIsEnabled,
  ...props
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { preferinteColeg, optiuniCazare } = cerere;
  const history = useHistory();

  const cereriIsFetching = useSelector(state => state.cereri.isFetching);

  const [dialogDeleteIsOpen, setDialogDeleteIsOpen] = useState(false);

  useEffect(() => {
    dispatch(setTitle("Vizualizează cererea"));
    dispatch(setBackLocation(appRoutes.SESIUNI));

    return () => {
      dispatch(setBackLocation(null));
    };
  }, []);

  const handleDelete = async () => {
    dispatch(stergereCerere(cerere.id))
      .then(() => {
        setDialogDeleteIsOpen(false);
        enqueueSnackbar("Cerere ștearsă", snackbarSuccess);
        history.replace(appRoutes.SESIUNI);
      })
      .catch(err => {
        enqueueSnackbar(err, snackbarError);
      });
  };

  return (
    <>
      <div>
        {!readOnly && (
          <Box marginBottom="2rem" display="flex" justifyContent="center">
            <Box component="span" marginRight="1rem">
              <Typography
                display="inline"
                variant="h6"
                className={classes.checkColor}
              >
                {"✓"}
              </Typography>
            </Box>
            <Typography display="inline" variant="h6">
              Cerere completată
            </Typography>
          </Box>
        )}
        <Paper className={classes.paper} elevation={4}>
          <Typography
            // className={classes.indent}
            variant="h6"
            color="textSecondary"
          >
            {strings.CERERE}
          </Typography>
          <HeaderWithDivider
            icon={MenuIcon}
            title={strings.DETALII}
            size="small"
            marginBottom
          />

          <LabelWithValue
            label="Nume complet"
            value={toTitleCase(get(cerere, "deponent.numeComplet", "-"))}
            margin
          />
          <LabelWithValue
            label="Media de cazare"
            value={get(cerere, "medieCazare", "-")}
            margin
          />

          <LabelWithValue
            label="Data înregistrării cererii"
            value={formatMomentDate(get(cerere, "inregistratLa", ""))}
            margin
          />

          {cerere.actualizatLa && (
            <LabelWithValue
              label="Ultima actualizare"
              value={formatMomentDate(get(cerere, "actualizatLa", ""))}
              margin
            />
          )}

          <HeaderWithDivider
            icon={ApartmentIcon}
            title="Opțiuni de cazare"
            secondaryTitle={optiuniCazare?.length || ""}
            size="small"
            marginBottom
          />

          {optiuniCazare &&
            optiuniCazare.map((e, i) => (
              <Box key={i}>
                <Typography variant="body1">
                  {i + 1}.{" "}
                  {e.spatiiCazare.length === 1
                    ? e.spatiiCazare[0].denumire
                    : e.denumire}
                </Typography>
                {e.spatiiCazare.length > 1
                  ? e.spatiiCazare.map((spatiu, index) => (
                    <Typography
                      display="block"
                      key={index}
                      variant="caption"
                      color="textSecondary"
                    >{`${i + 1}.${index + 1}. ${spatiu.denumire
                      }`}</Typography>
                  ))
                  : null}
              </Box>
            ))}

          <HeaderWithDivider
            size="small"
            icon={FaceIcon}
            title={strings.PREFERINTE_DE_COLEGI}
            secondaryTitle={preferinteColeg?.length || ""}
            marginBottom
          />

          {preferinteColeg && preferinteColeg.length !== 0 ? (
            preferinteColeg.map((e, i) => (
              <Typography key={i} variant="body2">
                {`${i + 1}. ${toTitleCase(e.numeComplet)} (${e.codFacultate ??
                  ""} ${e.denumireAnStudiu ?? ""}${e.denumireCiclu?.charAt(0) ??
                  ""} ${e.denumireProgramStudiu ? `- ${e.denumireProgramStudiu}` : ""
                  })`}
              </Typography>
            ))
          ) : (
            <Typography variant="body1">-</Typography>
          )}

          <HeaderWithDivider
            icon={MessageIcon}
            title="Observații"
            size="small"
            marginBottom
          />
          <Typography>{cerere?.observatii || "-"}</Typography>

          {cerere.motivId > 0 &&
            <>
              <HeaderWithDivider
                icon={MessageIcon}
                title="Motivul cazării"
                size="small"
                marginBottom
              />
              <Typography>{Motiv.getById(cerere.motivId).name || "-"}</Typography>
            </>}

          {/* ########### UPLOADS ############## */}
          <HeaderWithDivider
            title={strings.FISIERE}
            icon={CloudUploadIcon}
            size="small"
            marginBottom
          />
          {cerere?.uploads && cerere?.uploads?.length ? (
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <FilesWrapper
                type="existing"
                files={cerere?.uploads?.map(u => u.mediaContent)}
                // files={[
                //   // ...mediaContents,
                //   ...cerere?.uploads?.map((u) => ({ ...u })),
                // ]}
                cerereCazareId={cerere?.id}
              // handleDeleteFile={handleDeleteFile}
              // questionId={questionView ? questionView.id : null}
              />
            </Box>
          ) : (
            <Typography variant="body1">-</Typography>
          )}

          {!readOnly && (
            <>
              <div className={classes.divButtons}>
                <Button
                  className={classes.button}
                  // variant="contained"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  startIcon={<EditIcon />}
                  disabled={!editIsEnabled}
                >
                  Modifică
                </Button>

                <Button
                  className={classes.errorButton}
                  // variant="contained"
                  color="secondary"
                  onClick={() => setDialogDeleteIsOpen(true)}
                  startIcon={<DeleteIcon />}
                  disabled={!deleteIsEnabled}
                >
                  Șterge
                </Button>
              </div>
            </>
          )}
        </Paper>

        <AlertDialog
          isOpen={dialogDeleteIsOpen}
          setIsOpen={setDialogDeleteIsOpen}
          dialogTitle="Dorești să îți ștergi cererea pentru această sesiune?"
          dialogContentText="Confirmă acțiunea"
          yesAction={handleDelete}
          isLoading={cereriIsFetching}
        />
      </div>
    </>
  );
};

export default CardCerere;
