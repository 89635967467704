import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

export default ({ optiuniCazare, medieCazare }) => {
  const classes = useStyles();
  const optiuniCazareExists = optiuniCazare && optiuniCazare.length;

  return (
    <>
      <Box padding="0.3rem">
        {medieCazare && (
          <Box display="block">
            <Typography
              variant="body2"
              display="inline"
              color="textSecondary"
            >{`Medie cazare: `}</Typography>
            <Typography
              variant="body2"
              display="inline"
            >{`${medieCazare}`}</Typography>
          </Box>
        )}

        {optiuniCazareExists && (
          <Box display="block">
            <Typography variant="body2" color="textSecondary">
              Opțiuni cazare:
            </Typography>
            {optiuniCazare.map((e, i) => (
              <Box key={i} marginLeft="1rem">
                <Typography variant="body2">
                  {i + 1}.{" "}
                  {e.spatiiCazare.length === 1
                    ? e.spatiiCazare[0].denumire
                    : e.denumire}
                </Typography>
                {e.spatiiCazare.length > 1
                  ? e.spatiiCazare.map((spatiu, index) => (
                      <Typography
                        display="block"
                        key={index}
                        variant="caption"
                        color="textSecondary"
                      >{`${i + 1}.${index + 1}. ${
                        spatiu.denumire
                      }`}</Typography>
                    ))
                  : null}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};
