import moment from "moment";
import "moment/locale/ro";

export const formatNormalDate = (date) =>
  new Date(date).toLocaleString("ro-RO", {
    hour: "2-digit",
    minute: "2-digit",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

export const formatMomentDate = (date) => {
  if (date == null) {
    return "-";
  }
  const parsedDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSS');
  const offset = parsedDate.utcOffset();
  const adjustedDate = parsedDate.add(offset, "minute");
  return adjustedDate.locale("ro").format("LLL");
}

export const formatMomentDateWithoutHours = (date) => {
  if (date == null) {
    return "-";
  }
  const parsedDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSS');
  const offset = parsedDate.utcOffset();
  const adjustedDate = parsedDate.add(offset, "minute");
  return adjustedDate.locale("ro").format("LL");
}