import authService from "../components/api-authorization/AuthorizeService";

const request = async (method, url, data = null) => {
  let isBadRequest = false;
  let body = data;
  let headers = new Headers();
  const token = await authService.getAccessToken();
  headers.set("Authorization", `Bearer ${token}`);
  // headers.set('Accept','application/json');

  if (data) {
    if (typeof data === "object") {
      headers.set("Content-Type", "application/json");
      body = JSON.stringify(data);
    } else {
      headers.set("Content-Type", "application/x-www-form-urlencoded");
    }
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: body,
  })
    .then(async (res) => {
      if (res.status === 401) {
        // neautorizat
        // trigger sign out
      }
      isBadRequest = res.status === 400;
      let responseContentType = res.headers.get("content-type");
      if (
        responseContentType &&
        responseContentType.indexOf("application/json") !== -1
      ) {
        return { content: await res.json(), status: res.status };
      } else {
        return { content: await res.text(), status: res.status };
      }
    })
    .then((res) => {
      let response = {
        isError: isBadRequest,
        errorContent: isBadRequest ? res.content : null,
        content: isBadRequest ? null : res.content,
        status: res.status,
      };
      return response;
    });
};

export const get = (url) => {
  return request("GET", url);
};

export const del = (url) => {
  return request("DELETE", url);
};

export const put = (url, data) => {
  return request("PUT", url, data);
};

export const post = (url, data) => {
  return request("POST", url, data);
};

export const patch = (url, data) => {
  return request("PATCH", url, data);
};
