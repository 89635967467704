import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../actions";
import strings from "../utils/strings";
import InfoCard from "../components/utils/InfoCard";
import { Grid, Box } from "@material-ui/core";

import BusinessIcon from "@material-ui/icons/Business";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { APP_SET_TITLE } from "../actions/types";

const Contact = props => {
  useEffect(() => {
    props.setAction(strings.CONTACT, APP_SET_TITLE);
  }, []);

  return (
    <>
      <Grid container justify="space-around">
        <Grid item lg={4} xs={12}>
          <Box margin="1rem 1rem 1rem" display="flex" justifyContent="center">
            <InfoCard
              icon={<BusinessIcon fontSize="large" />}
              title={"Adresa"}
              rows={["Str. Frumoasă nr. 31", "Sala 13, parter"]}
            />
          </Box>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box margin="1rem 1rem 1rem" display="flex" justifyContent="center">
            <InfoCard
              icon={<ScheduleIcon fontSize="large" />}
              title={"Program"}
              rows={["Luni - Vineri", "10:00-14:00 și 15:00-19:00"]}
            />
          </Box>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box margin="1rem 1rem 1rem" display="flex" justifyContent="center">
            <InfoCard
              icon={<MailOutlineIcon fontSize="large" />}
              title={"Contact"}
              rows={["Tel: 0728881152", "Email: contact@cazare.ase.ro"]}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

const enhance = compose(connect(mapStateToProps, actions));

export default enhance(Contact);
