import strings from "../../utils/strings";
import appRoutes from "../../utils/appRoutes";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import ListIcon from "@material-ui/icons/List";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import FaceIcon from "@material-ui/icons/Face";
import InfoIcon from "@material-ui/icons/Info";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import HomeIcon from "@material-ui/icons/Home";
import HistoryIcon from "@material-ui/icons/History";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

export const loggedInItems = [
  { path: appRoutes.HOME, icon: HomeIcon, text: strings.HOME },
  {
    path: appRoutes.SESIUNI,
    icon: ListIcon,
    text: strings.ACTIVE_SESSIONS,
  },
  {
    path: appRoutes.CAZARI,
    icon: LocationCityIcon,
    text: strings.CAZARI,
  },
  {
    path: appRoutes.ISTORIC_CERERI,
    icon: HistoryIcon,
    text: strings.REQUESTS_HISTORY,
  },
  {
    path: appRoutes.PROFILE,
    icon: FaceIcon,
    text: strings.YOUR_PROFILE,
  },
  {},
  {
    path: appRoutes.INFO,
    icon: InfoIcon,
    text: strings.INFO,
  },
  {
    path: appRoutes.CONTACT,
    icon: ContactMailIcon,
    text: strings.CONTACT,
  },
];

export const notLoggedInItems = [
  { path: ApplicationPaths.Login, icon: ExitToAppIcon, text: strings.LOGIN },
  {
    path: ApplicationPaths.Register,
    icon: PersonAddIcon,
    text: strings.REGISTER,
  },
  { path: appRoutes.HOME, icon: HomeIcon, text: strings.HOME },
  {
    path: appRoutes.INFO,
    icon: InfoIcon,
    text: strings.INFO,
  },
  {
    path: appRoutes.CONTACT,
    icon: ContactMailIcon,
    text: strings.CONTACT,
  },
];
