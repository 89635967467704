import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  ro: {
    HOME: "Pagina principală",
    ACCOMMODATION: "Cazare",
    PROFILE: "Profil",
    APP_NAME: "Asecommodation",
    MANAGE_ACCOUNT: "Contul tău",
    MANAGE_ACCOUNT_DESCRIPTION: "Administrează datele tale de acces",
    MANAGE_ACCOUNT_BUTTON: "Accesează",
    COMPLETE_YOUR_PROFILE: "Validează identitatea de student",
    COMPLETE_YOUR_PROFILE_DESCRIPTION:
      "Este necesar să îți validezi identitatea de student pentru a putea depune cereri de cazare",
    COMPLETE_YOUR_PROFILE_ACTION: "Completează",
    FIRST_NAME: "Nume",
    SURNAME: "Prenume",
    CNP: "CNP",
    CNP_HELPER_TEXT: "Cod numeric personal",
    IDENTITATE_VALIDATA: "Identitate validată",
    IDENTITATE_ACTUALIZATA: "Identitate actualizată",

    FORMA_FINANTARE_SEMESTRUL_1: "Formă finanțare semestrul 1",
    FORMA_FINANTARE_SEMESTRUL_2: "Formă finanțare semestrul 2",
    ACTUALIZAT_LA: "Actualizat la",

    LOGIN: "Autentificare",
    REGISTER: "Creare cont",
    INFO: "Info",
    CONTACT: "Contact",
    REQUEST_ERROR: "Eroare la procesarea cererii",
    REQUEST_ERROR_404: "Entitate neregăsită",
    SUBMIT: "Trimite",
    EDIT: "Modifică",
    SESSION: "Sesiune",
    REASON_SESSION: "Motivul cazării",
    REASON_REQUIRED: "Motivul cazării este obligatoriu",
    COMPLETE_ACCOMMODATION_REQUEST:
      "Completează informațiile necasare creării unei cereri de cazare",
    COMPLETE_ACCOMMODATION_REQUEST_SPACES:
      "Alege 3 cămine, în ordinea priorității",
    COMPLETE_ACCOMMODATION_REQUEST_COLLEAGUES:
      "Alege până la 4 opțiuni de coleg (opțional)",
    COMPLETE_ACCOMMODATION_REQUEST_COLLEAGUES_2:
      "Numai colegii validați în această secțiune vor fi luați în considerare în procesul de cazare",
    COLLEAGUE: "Coleg",
    COLLEAGUE_EXTENDED: "Cod coleg",
    CONFIRM_CREATE_ACCOMMODATION_REQUEST:
      "Ești sigur că dorești să finalizezi cererea?",
    COMPLETE_ACCOMMODATION_REQUEST_SPACES_REQUIRED:
      "Verifică selectarea spațiilor de cazare",
    COMPLETE_ACCOMMODATION_REQUEST_SPACES_DISTINCT:
      "Ai selectat opțiuni duplicate. Selectează opțiuni distincte pentru a continua.",
    ACCOMMODATION_SPACES: "Cămine disponibile",
    ACTIVE_SESSIONS: "Sesiuni de cazare",
    CREATE_SESSION: "Creează o sesiune de cazare",
    CREATE_SESSION_DESCRIPTION:
      "Creează o sesiune de cazare pentru a permite studenților să aplice la repartizarea în cămine",
    NAME: "Denumire",
    NAME_HELPER_TEXT_SESSION: "Denumirea publică a sesiunii de cazare",
    DESCRIPTION: "Descriere",
    DESCRIPTION_HELPER_TEXT_SESSION:
      "Descrierea sesiunii de cazare -  detalii suplimentare pentru informarea studenților (opțional)",
    SESSION_NOT_FOUND: "Sesiune negăsită",
    START_DATE: "Dată început",
    START_DATE_HELPER_TEXT_SESSION: "Data de la care se pot depune cereri",
    STOP_DATE: "Dată sfârșit",
    STOP_DATE_HELPER_TEXT_SESSION: "Data până la care se pot depune cereri",
    ACCOMMODATION_REQUEST: "Cerere de cazare",
    EMPTY_SESSIONS: "Nu există sesiuni active",
    VALIDATION_ERROR: "Verifică valorile introduse",
    FACULTY: "Facultate",
    PROGRAM_STUDIU: "Program studiu",
    YEAR: "An",
    ACCOMMODATION_GRADE: "Medie de cazare",
    STUDY_YEAR: "An studiu",
    IDENTITATE_ACTUALIZAT_LA: "Identitate actualizată la",
    SERIE_BULETIN: "Serie carte identitate",
    NUMAR_BULETIN: "Număr carte identitate",
    CNP_VALID: "CNP valid",
    COPY: "Copiază",
    COPY_YOUR_CODE: "Copiază codul",
    YOUR_PROFILE: "Profilul tău",
    YOUR_PROFILE_DESCRIPTION:
      "Vizualizează profilului tău din Academia de Studii Economice",
    YOUR_CODE: "Codul tău de coleg - pentru preferințele de coleg",
    LOGIN_REQUIRED:
      "Pentru confirmarea statutului de student, va fi necesară reautentificarea în aplicație",
    COD_COLEG_LOCATIE: "Codul de coleg se găsește în pagina de Profil",
    REQUESTS_HISTORY: "Istoric cereri",
    EMPTY_REQUESTS: "Nu există cereri",
    PRIORITY_1: "Prioritate 1",
    PRIORITY_2: "Prioritate 2",
    PRIORITY_3: "Prioritate 3",
    EMITENT_CI: "Emitentul cărții de identitate",
    DATA_CI: "Data emiterii cărții de identitate",
    DATA_CI_HELPER_TEXT: "În formatul ZZ-LL-AAAA",
    PHONE: "Numărul de telefon",
    ISTORIC_CERERI_EMPTY: "Nu există cereri depuse",
    OBSERVATII: "Observații",
    COLEGI_DE_CAMERA: "Colegi de cameră",
    OPTIONAL: "Opțional",
    STERGE_COLEG: "Șterge coleg",
    VALIDEAZA_COLEG: "Validează coleg",

    CAZARI: "Cazări",
    CAZARE: "Cazare",
    LOC: "Loc",
    SPATIU: "Spațiu",
    CAMERA: "Camera",
    ETAJ: "Etaj",
    VALABILITATE: "Valabilitate",

    EMPTY_CAZARI: "Nu există cazări",
    VALABILITATE_CAZARE: "Valabilitate cazare",
    DETALII_CAZARE: "Detalii cazare",
    EVENIMENTE: "Evenimente",
    CREATA_LA: "Creată la",
    PLATI: "Plăți",
    DETALII: "Detalii",
    CERERE: "Cerere",
    PREFERINTE_DE_COLEGI: "Preferințe de colegi",
    COMPLETE_CNP_DESCRIPTION: "Introdu codul numeric personal",
    VALIDEAZA: "Validează",
    CNP_FOLOSIT: "Identitatea este deja validată pentru acest CNP",
    CNP_INVALID: "CNP invalid",
    CNP_NU_EXISTA: "Nu există o identitate de student pentru CNP-ul introdus",
    ALEGE_IDENTITATE:
      "Alege identitatea de student pe care dorești să o asociezi acestui cont",
    MODIFICA_DACA_E_NECESAR: "Modifică datele dacă este necesar",
    CARTEA_DE_IDENTITATE: "Cartea de identitate",
    DATE_PERSONALE: "Date personale",
    PROFIL_STUDII: "Profil studii",
    GDPR_TRIMITE:
      'Prin apăsarea butonului "Trimite" ești de acord cu procesarea datelor tale cu caracter personal',
    FISIERE: "Fișiere",

    VIZUALIZEAZA_CONTRACANDIDATI: " Vizualizează cererile din anul tău",
    STATUS: "Status",
    IN_ASTEPTARE: "În așteptare",
    CONFIRMATA: "Confirmată",
    ANULATA: "Anulată",
    GENEREAZA_CONTRACT: "Generează contractul",
    UPLOAD_CONTRACT_SEMNAT: "Încarcă contractul semnat",
    ACTIUNI_NECESARE: "Acțiuni necesare",
    DESCARCA_CONTRACTUL: "Descarcă contractul",
    EROARE_CONTRACT: "Eroare la generarea contractului",
    STATUS: "Status",

    CUANTUM: "Cuantum",
    LUNA: "Luna",
    SCADENT_LA: "Scadent la",
    DENUMIRE: "Denumire",
    DESCRIERE: "Descriere",
    TIP: "Tip",
    PLATESTE: "Plătește",

    INTOARCE_TE_LA_PAGINA_PRINCIPALA: "Întoarce-te la pagina principală",
    CONFLICT_PAYMENT:
      "Există o plată în procesare. Încearcă din nou în 15 minute.",

    CONT_EXISTENT: "Există deja un cont legat de această identitate",
    ACTUALIZARE_IDENTITATE: "Actualizează identitatea de student",
    ACTUALIZEAZA_IDENTITATEA: "Actualizează identitatea",
    IDENTITATE_DEZACTVATA: "Identitatea nu a putut fi actualizată automat",

    UPLOADS_DESCRIPTION:
      "(Opțional) Se vor încărca doar documente suplimentare speciale (ex: certificat medical). Documentele încărcate trebuie să fie prezentate fizic la ocuparea cazării.",

    COVID_STATUS:
      "Situația dvs. din punct de vedere al vaccinării împotriva Covid-19 la data de 11.10.2021",
    INFO_COVID: "Informații referitoare la Covid-19",
    COVID_CLARIFICARE1: "*Conform Hotărârii Consiliului de Administrație al ASE București din 11.10.2021, opțiunea de a fi cazat în aceeași cameră cu persoana menționată în cererea de cazare nu este valabilă în cazul repartizării în căminele încadrate în gradul 2 de confort unde grupurile sanitare sunt amplasate la comun pe etaj, dacă nu aveți același status din punct de vedere al vaccinării."
  }
  // en: {
  //   HOME: "Home",
  //   ACCOMMODATION: "Accommodation",
  //   PROFILE: "Profile",
  //   APP_NAME: "Asecommodation",
  //   MANAGE_ACCOUNT: "Manage your account",
  //   MANAGE_ACCOUNT_DESCRIPTION: "This is your identity in the application",
  //   MANAGE_ACCOUNT_BUTTON: "Manage",
  //   COMPLETE_YOUR_PROFILE: "Complete your profile",
  //   COMPLETE_YOUR_PROFILE_DESCRIPTION:
  //     "You must complete your profile in order to make a request for accommodation",
  //   COMPLETE_YOUR_PROFILE_ACTION: "Complete",
  //   LOGIN: "Login",
  //   REGISTER: "Register",
  //   INFO: "Info",
  //   CONTACT: "Contact",
  //   REQUEST_ERROR: "Request processing error",
  //   REQUEST_ERROR_404: "Entity not found",
  //   CNP: "CNP",
  //   CNP_EXTENDED: "Cod numeric personal",
  //   SUBMIT: "Submit",
  //   SESSION: "Session",
  //   COMPLETE_ACCOMMODATION_REQUEST:
  //     "Please complete the necessary informations in order to create an accommodation request",
  //   COMPLETE_ACCOMMODATION_REQUEST_SPACES: "Complete accommodations choices",
  //   ACCOMMODATION_SPACES: "Available accommodation spaces",
  //   COMPLETE_ACCOMMODATION_REQUEST_COLLEAGUES: "Complete colleagues (optional)",
  //   COLLEAGUE: "Colleague",
  //   COLLEAGUE_EXTENDED: "Colleague code",
  //   CONFIRM_CREATE_ACCOMMODATION_REQUEST:
  //     "Are you sure you want to submit the request?",
  //   COMPLETE_ACCOMMODATION_REQUEST_SPACES_REQUIRED: "Accommodation is required",
  //   ACTIVE_SESSIONS: "Active accommodation sessions",
  //   CREATE_SESSION: "Create an accommodation session",
  //   CREATE_SESSION_DESCRIPTION:
  //     "Create an accommodation session in order to....",
  //   NAME: "Name",
  //   NAME_HELPER_TEXT_SESSION: "Display name of the accommodation session",
  //   DESCRIPTION: "Description",
  //   DESCRIPTION_HELPER_TEXT_SESSION:
  //     "Description of accommodation session (optional)",
  //   SESSION_NOT_FOUND: "Session not found",
  //   START_DATE: "Start date",
  //   START_DATE_HELPER_TEXT_SESSION: "",
  //   STOP_DATE: "Stop date",
  //   STOP_DATE_HELPER_TEXT_SESSION: "",
  //   ACCOMMODATION_REQUEST: "Accommodation request"
  // }
});

strings.setLanguage("ro");

export default strings;
