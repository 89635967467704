import {
  Container,
  // withStyles,
  // responsiveFontSizes,
  CssBaseline,
  makeStyles,
  MuiThemeProvider
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Route } from 'react-router'
//MUI
import { Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import AuthorizeRoleRoute from "./components/api-authorization/AuthorizeRoleRoute";
//Routes
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import authService from "./components/api-authorization/AuthorizeService";
import VizualizareCazare from "./components/cazari/VizualizareCazare";
import Cazari from "./components/cazari/_Cazari";
import Layout from "./components/navigation/Layout";
import _Profil from "./components/profil/_Profil";
import _ValidareIdentitate from "./components/profil/_ValidareIdentitate";
import _Sesiuni from "./components/sesiuni/_Sesiuni";
import PaymentError from "./components/utils/PaymentError";
import PaymentSuccess from "./components/utils/PaymentSuccess";
import appRoutes from "./utils/appRoutes";
import colorMode from "./utils/colorMode";
import roles from "./utils/roles";
import AutoLogOut from "./views/AutoLogOut";
import Contact from "./views/Contact";
import Home from "./views/Home";
import Info from "./views/Info";
import IstoricCerereView from "./views/IstoricCerereView";
import IstoricCereri from "./views/IstoricCereri";
import Session from "./views/Session";

// const styles = (theme) => {};

const useStyles = makeStyles(theme => ({
  containerRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));

const App = () => {
  const classes = useStyles();

  const selectedColorMode = useSelector(state => state.app.colorMode);

  const [ready, setReady] = useState(false);

  const populateAuthenticationState = async () => {
    await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    setReady(true);
  };

  useEffect(() => {
    populateAuthenticationState();
  }, []);

  const palette = {
    secondary: {
      main: "#b5763f"
    }
    // background: {
    //   default: "#303030",
    //   paper: "#424242",
    // },
  };

  let theme = createTheme({
    palette:
      selectedColorMode === colorMode.LIGHT
        ? { ...palette, type: "light" }
        : {
            ...palette,
            type: "dark"
          },
    overrides: {
      MuiButton: {
        root: {
          textTransform: "none"
        }
      },
      MuiChip: {
        outlinedPrimary: {
          color: selectedColorMode === colorMode.DARK ? "white" : "#3f51b5"
        }
      }
    }
  });

  // theme = responsiveFontSizes(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {ready && (
        <SnackbarProvider maxSnack={3}>
          <Layout>
            <Container maxWidth="lg" classes={{ root: classes.containerRoot }}>
              <Switch>
                <Route
                  path={ApplicationPaths.ApiAuthorizationPrefix}
                  component={ApiAuthorizationRoutes}
                />

                <Route exact path={appRoutes.HOME} component={Home} />

                <Route exact path={appRoutes.INFO} component={Info} />

                <Route exact path={appRoutes.CONTACT} component={Contact} />

                <Route
                  exact
                  path={appRoutes.AUTO_LOG_OUT}
                  component={AutoLogOut}
                />

                {/* profil */}
                <AuthorizeRoute
                  exact
                  path={appRoutes.PROFILE}
                  component={_Profil}
                />

                <AuthorizeRoute
                  exact
                  path={appRoutes.COMPLETE_PROFILE}
                  component={_ValidareIdentitate}
                />
                <AuthorizeRoleRoute
                  exact
                  path={appRoutes.UPDATE_PROFILE}
                  component={_ValidareIdentitate}
                  role={roles.STUDENT}
                />

                {/* sesiuni */}
                <AuthorizeRoute
                  exact
                  path={appRoutes.SESIUNI}
                  component={_Sesiuni}
                />

                <AuthorizeRoleRoute
                  exact
                  path={appRoutes.SESIUNE_ACCESATA}
                  component={Session}
                  role={roles.STUDENT}
                />

                {/* istoric */}
                <AuthorizeRoute
                  exact
                  path={appRoutes.ISTORIC_CERERI}
                  component={IstoricCereri}
                  // role={roles.STUDENT}
                />

                <AuthorizeRoute
                  exact
                  path={appRoutes.ISTORIC_CERERE_ACCESATA}
                  component={IstoricCerereView}
                  // role={roles.STUDENT}
                />

                {/* cazari */}
                <AuthorizeRoute
                  exact
                  path={appRoutes.CAZARI}
                  component={Cazari}
                  // role={roles.STUDENT}
                />
                <AuthorizeRoute
                  exact
                  path={appRoutes.CAZARE_ACCESATA}
                  component={VizualizareCazare}
                  // role={roles.STUDENT}
                />

                <Route
                  exact
                  path={appRoutes.PLATA_SUCCES}
                  component={PaymentSuccess}
                />

                <Route
                  exact
                  path={appRoutes.PLATA_EROARE}
                  component={PaymentError}
                />
              </Switch>
            </Container>
          </Layout>
        </SnackbarProvider>
      )}
    </MuiThemeProvider>
  );
};

// const enhance = compose(withStyles(styles, { withTheme: true }));

export default App;
