import { combineReducers } from "redux";

import appReducer from "./app";
import accommodationReducer from "./accommodation";
import profileReducer from "./profil";
import sesiuni, * as fromSesiuni from "./sesiuni";
import cereri, * as fromCereri from "./cereri";
import complexe from "./complexe";
import cazari from "../store/cazari/reducer";

export default combineReducers({
  app: appReducer,
  accommodation: accommodationReducer,
  profile: profileReducer,
  sesiuni,
  cereri,
  complexe,
  cazari,
});

export const getSesiuni = (state) => fromSesiuni.getSesiuni(state.sesiuni);

export const getCereri = (state) => fromCereri.getCereri(state.cereri);
