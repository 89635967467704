import React, { useState } from "react";
import { Paper, Typography, Button, Icon } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import LabelWithValue from "../utils/LabelWithValue";
import strings from "../../utils/strings";
import { formatMomentDateWithoutHours } from "../../utils/formatDate";
import HeaderWithDivider from "../utils/HeaderWithDivider";
import TimelineIcon from "@material-ui/icons/Timeline";
import PaymentIcon from "@material-ui/icons/Payment";
import MenuIcon from "@material-ui/icons/Menu";
import statusuriCazare from "./statusuriCazare";

import { get } from "../../utils/restUtilities";
import { useSnackbar } from "notistack";
import { snackbarError } from "../../utils/snackbarVariants";
import ObligatiePlataListItem from "./plati/ObligatiePlataListItem";
import { StatusObligatiePlata } from "../../utils/enums";
const useStyles = makeStyles(theme => ({
  paper: {
    padding: "1rem"
  },
  divButton: {
    margin: "1rem 0 1rem"
  },
  button: { marginRight: "1rem" }
}));

const CardCazare = ({
  id,
  dataStart,
  dataStop,
  locCamera,
  adaugatLa,
  status,
  obligatiiPlata,
  cerereCazare
}) => {
  const classes = useStyles();

  const datorie =
    obligatiiPlata
      ?.filter(
        obligatie => obligatie.status !== StatusObligatiePlata.ACHITAT.id
      )
      ?.reduce((acc, e) => acc + e.cuantum, 0) ?? 0;

  const nrDatorii =
    obligatiiPlata?.filter(
      obligatie => obligatie.status !== StatusObligatiePlata.ACHITAT.id
    )?.length ?? 0;

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Typography variant="h6" color="textSecondary">
          {strings.CAZARE}
        </Typography>

        <HeaderWithDivider
          title={strings.DETALII}
          icon={MenuIcon}
          size="small"
        />
        <LabelWithValue
          margin
          label={strings.STATUS}
          value={statusuriCazare[status]}
        />
        <LabelWithValue
          margin
          label={strings.VALABILITATE}
          value={`${formatMomentDateWithoutHours(
            dataStart
          )} - ${formatMomentDateWithoutHours(dataStop)}`}
        />
        <LabelWithValue
          margin
          value={`${locCamera.denumireSpatiu}`}
          label={strings.SPATIU}
        />
        <LabelWithValue
          margin
          value={`${locCamera.etaj}`}
          label={strings.ETAJ}
        />
        <LabelWithValue
          margin
          value={`${locCamera.numarCamera}`}
          label={strings.CAMERA}
        />

        <HeaderWithDivider
          title={strings.EVENIMENTE}
          icon={TimelineIcon}
          size="small"
        />
        <LabelWithValue
          margin
          value={`${formatMomentDateWithoutHours(adaugatLa)}`}
          label={strings.CREATA_LA}
        />
        <HeaderWithDivider
          title={strings.PLATI}
          icon={PaymentIcon}
          size="small"
        />
        {obligatiiPlata?.length ? (
          obligatiiPlata.map((obligatie, indexObligatie) => (
            <ObligatiePlataListItem
              key={indexObligatie}
              deponent={cerereCazare.deponent}
              cazareId={id}
              {...obligatie}
            />
          ))
        ) : (
          <Typography>-</Typography>
        )}
      </Paper>
    </>
  );
};

export default CardCazare;

{
  /* {Boolean(status === 0) && (
          <>
            <HeaderWithDivider
              title={strings.ACTIUNI_NECESARE}
              icon={WarningIcon}
              size="small"
            />

            <div className={classes.divButton}>
              {dateContract ? (
                <PDFDownloadLink
                  document={<ContractGenerator {...dateContract} />}
                  fileName={"contract.pdf"}
                >
                  {
                    ({ blob, url, loading, error }) => {
                      if (loading) {
                        return (
                          <Button
                            className={classes.button}
                            color="secondary"
                            disabled
                          >
                            Contractul se generează...
                          </Button>
                        );
                      } else {
                        // setIsFetching(false);
                        // return "Descarcă contractul";
                        return (
                          <Button
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            startIcon={<GetAppIcon />}
                          >
                            {strings.DESCARCA_CONTRACTUL}
                          </Button>
                        );
                      }
                    }
                    // loading ? "Documentul se încarcă..." : "Descarcă repartizările"
                  }
                </PDFDownloadLink>
              ) : (
                <Button
                  className={classes.button}
                  color="secondary"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => generareContract()}
                  disabled={dateContractIsLoading}
                >
                  {strings.GENEREAZA_CONTRACT}
                </Button>
              )}

            </div>


          </>
        )} */
}

// const { enqueueSnackbar } = useSnackbar();
// const [dateContract, setDateContract] = useState(null);
// const [dateContractIsLoading, setDateContractIsLoading] = useState(false);

// const generareContract = async () => {
//   setDateContractIsLoading(true);
//   try {
//     const res = await get("/api/info/identitate?variant=extended");
//     setDateContractIsLoading(false);
//     if (res?.status === 200) {
//       setDateContract({
//         ...res.content,
//         dataStart: formatMomentDateWithoutHours(dataStart),
//         dataStop: formatMomentDateWithoutHours(dataStop),
//         serieContract: "AA",
//         dataContract: "01.10.2020",
//         numarContract: "123456",
//         spatiu: locCamera?.denumireSpatiu,
//         etaj: locCamera?.etaj?.toString(),
//         camera: locCamera?.numarCamera,
//         adresaSpatiu: locCamera?.adresaSpatiu ?? "-",
//       });
//     } else {
//       enqueueSnackbar(strings.EROARE_CONTRACT, snackbarError);
//       setDateContractIsLoading(false);
//     }
//   } catch (err) {
//     enqueueSnackbar(strings.EROARE_CONTRACT, snackbarError);
//     setDateContractIsLoading(false);
//   }
// };
