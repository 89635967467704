import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Typography,
  Paper,
  Box,
  Fade,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import strings from "../utils/strings";

import RequestForm from "../components/accommodation/RequestForm";

import CardCerere from "../components/accommodation/CardCerere";

import SesiuneCard from "../components/accommodation/SesiuneCard";
import appRoutes from "../utils/appRoutes";
import {
  fetchIdentitate,
  fetchSesiuneById,
  fetchCerereBySesiuneId,
  setTitle,
  setBackLocation,
} from "../actions";
import { TipSesiune } from "../utils/enums";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "1rem",
    padding: "1rem",
  },
  medie: {
    margin: "1rem 0 1rem",
  },
}));

const studentulEsteEligibil = (sesiune, identitate) => {
  if (!sesiune?.cerinte?.length || !identitate) {
    return false;
  }

  const areStudiileInCerinte = sesiune.cerinte.find((cerinta) =>
    cerinta.anStudiu?.id === identitate.anStudiuId &&
    cerinta.tipCicluStudii?.id === identitate.cicluId)
  if (!areStudiileInCerinte) {
    return false
  }

  if (
    sesiune.estePentruAnulUniversitarUrmator &&
    identitate.anStudiuId === 3 &&
    identitate.cicluId === 1 &&
    identitate.codFacultate !== 'DREPT'
  ) {
    return false
  }

  return true
}

const Session = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const sesiuneId = Number(props.match.params.id);
  const sesiune = useSelector((state) => state.sesiuni.byId[sesiuneId]);
  const cerereExistenta = useSelector(
    (state) => state.cereri.bySesiuneId[sesiuneId]
  );
  const sesiuniIsFetching = useSelector((state) => state.sesiuni.isFetching);
  const cereriIsFetching = useSelector((state) => state.cereri.isFetching);
  const identitateIsFetching = useSelector((state) => state.profile.isFetching);
  const identitate = useSelector((state) => state.profile.identitate);

  const medieCazare = (identitate && identitate.medieCazare) || null;
  const formaFinantareSemestrul1 = (identitate && identitate.formaFinantareSemestrul1) || null;
  const formaFinantareSemestrul2 = (identitate && identitate.formaFinantareSemestrul2) || null;
  const actualizatLa = (identitate && identitate.actualizatLa) || null;

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const promiseArray = [];
      // if (!cerereExistenta) {
      promiseArray.push(dispatch(fetchCerereBySesiuneId(sesiuneId)));
      // }
      // if (!sesiune) {
      promiseArray.push(dispatch(fetchSesiuneById(sesiuneId)));
      // }
      if (!medieCazare || !identitate) {
        promiseArray.push(dispatch(fetchIdentitate()));
      }
      await Promise.all(promiseArray);
    };

    dispatch(setTitle(strings.SESSION));
    dispatch(setBackLocation(appRoutes.SESIUNI));
    fetchData();
    return () => {
      dispatch(setBackLocation(null));
    };
  }, []);

  const actiuni =
    sesiune && sesiune.actiuni && sesiune.actiuni.length ? sesiune.actiuni : [];

  const creationIsEnabled = Boolean(actiuni.find((e) => e.actiuneId === 1));
  const editIsEnabled = Boolean(actiuni.find((e) => e.actiuneId === 2));
  const deleteIsEnabled = Boolean(actiuni.find((e) => e.actiuneId === 3));

  // const esteEligibil = Boolean(
  //   sesiune?.cerinte?.find(
  //     (e) =>
  //       e.anStudiu?.id === identitate?.anStudiuId &&
  //       e.tipCicluStudii?.id === identitate?.cicluId
  //   )
  // );

  const esteEligibil = studentulEsteEligibil(sesiune, identitate)

  if (
    (sesiuniIsFetching && !sesiune) ||
    (cereriIsFetching && !cerereExistenta)
  ) {
    return (
      <>
        {sesiune && <SesiuneCard session={sesiune} />}
        <Box textAlign="center">
          <CircularProgress disableShrink />
        </Box>
      </>
    );
  } else if (sesiune) {
    // s-a facut fetch-ul pentru sesiune si cerere
    return (
      <>
        <SesiuneCard {...sesiune} showDetails />

        {cerereExistenta ? ( // exista cererea
          isEditing ? ( // session.editIsEnabled..
            <RequestForm
              cerere={cerereExistenta}
              setIsEditing={setIsEditing}
              numarMinimOptiuniCazare={sesiune.numarMinimOptiuniCazare}
              medieCazare={medieCazare}
              formaFinantareSemestrul1={formaFinantareSemestrul1}
              formaFinantareSemestrul2={formaFinantareSemestrul2}
              actualizatLa={actualizatLa}
            />
          ) : (
            <CardCerere
              cerere={cerereExistenta}
              setIsEditing={setIsEditing}
              deleteIsEnabled={deleteIsEnabled} // session.editIsEnabled..
              editIsEnabled={editIsEnabled}
            />
          )
        ) : (
          // nu exista cererea
          <>
            {/* {medieCazare ? (
              <Typography
                className={classes.medie}
                variant="body1"
                align="center"
              >
                Media de cazare: <b>{medieCazare}</b>
              </Typography>
            ) : identitateIsFetching ? (
              <Box textAlign="center">
                <CircularProgress disableShrink />
              </Box>
            ) : null} */}

            {Boolean(creationIsEnabled && esteEligibil) ? (
              <RequestForm // session.createIsEnabled...
                numarMinimOptiuniCazare={sesiune.numarMinimOptiuniCazare}
                medieCazare={medieCazare}
                formaFinantareSemestrul1={formaFinantareSemestrul1}
                formaFinantareSemestrul2={formaFinantareSemestrul2}
                actualizatLa={actualizatLa}
              />
            ) : !creationIsEnabled ? (
              <Box textAlign="center" marginTop="1rem">
                <Typography>
                  Adăugarea de cereri de cazare este dezactivată
                </Typography>
              </Box>
            ) : (
              <Box textAlign="center" marginTop="1rem">
                <Typography>
                  Nu poți depune cerere pentru această sesiune
                </Typography>
              </Box>
            )}
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <Fade in={true}>
          <Paper className={classes.paper}>
            <Typography align="center" variant="h6">
              {strings.SESSION_NOT_FOUND}
            </Typography>
          </Paper>
        </Fade>
      </>
    );
  }
};

export default Session;
