import {
  Avatar,
  Box,
  Chip,
  Divider,
  makeStyles,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import uniq from "lodash/uniq";
import React from "react";
import { TipSesiune } from "../../utils/enums";
import { formatMomentDate } from "../../utils/formatDate";
import LabelWithValue from "../utils/LabelWithValue";
import ListItemCustom from "../utils/ListItemCustom";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "0 0 2rem",
    padding: "1rem"
  },
  chip: {
    fontSize: ".75rem",
    margin: "8px 4px 8px",
    padding: "4px"
  },
  divider: {
    margin: "8px"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
}));

const esteCerintaAnul3Licenta = (cerinta) => {
  if (cerinta?.anStudiu?.id === 3 && cerinta?.tipCicluStudii?.id === 1) {
    return true;
  }
  return false;
}

const SesiuneCard = ({
  showDetails,
  id,
  denumire,
  dataStartValabilitate,
  dataStopValabilitate,
  descriere,
  actiuni,
  cerinte,
  tip,
  estePentruAnulUniversitarUrmator
}) => {
  const classes = useStyles();

  const arrayActiuni =
    actiuni && actiuni.length ? uniq(actiuni.map(e => e.denumire)) : [];

  const arrayCerinte =
    cerinte && cerinte.length
      ? cerinte
        .sort(
          (a, b) =>
            a.tipCicluStudii.id - b.tipCicluStudii.id ||
            a.anStudiu.id - b.anStudiu.id
        )
        .map(
          cerinta =>
            `${cerinta.anStudiu?.id > 0 ? "An " : ""} ${cerinta.anStudiu.denumire} ${cerinta.tipCicluStudii.denumire}${esteCerintaAnul3Licenta(cerinta) && estePentruAnulUniversitarUrmator ? ' DREPT' : ''}`
        )
      : [];
  if (denumire) {
    return (
      <>
        <Paper className={classes.paper} elevation={4}>
          <ListItemCustom
            id={id}
            avatar={
              <Avatar className={classes.avatar}>
                {tip === TipSesiune.CAZARE.id ? "C" : "R"
                  // sesiune.denumire.charAt(0)
                }
              </Avatar>
            }
            primaryText={`[${TipSesiune.getName(tip)}] ${denumire}`}
            secondaryText={
              <>
                <Typography component="span" variant="body2">
                  {`Start valabilitate: ${formatMomentDate(
                    dataStartValabilitate
                  )}`}
                </Typography>
                <Typography
                  display="block"
                  component="span"
                  variant="body2"
                >{`Stop valabilitate: ${formatMomentDate(
                  dataStopValabilitate
                )}`}</Typography>
              </>
            }
          />

          <Divider className={classes.divider} />

          {descriere && (
            <LabelWithValue label="Descriere" value={descriere} margin />
            // <Box marginLeft="1rem">
            //   <Typography variant="body2">{descriere}</Typography>
            // </Box>
          )}
          {showDetails ? (
            <div>
              {arrayCerinte && arrayCerinte.length ? (
                <Tooltip title="Cui i se adresează această sesiune">
                  <Box>
                    {arrayCerinte.map(e => (
                      <Chip
                        size="small"
                        className={classes.chip}
                        label={e}
                        variant="outlined"
                        color="primary"
                        icon={<SchoolIcon />}
                      />
                    ))}
                  </Box>
                </Tooltip>
              ) : null}
              {arrayActiuni && arrayActiuni.length ? (
                <Tooltip title="Acțiuni permise în perioada de valabilitate">
                  <Box>
                    {arrayActiuni.map(e => (
                      <Chip
                        size="small"
                        className={classes.chip}
                        label={e}
                        variant="outlined"
                        color="primary"
                        icon={<TouchAppIcon />}
                      />
                    ))}
                  </Box>
                </Tooltip>
              ) : null}
              {/* {stringCerinte && (
                <LabelWithValue label="Cerințe" value={stringCerinte} margin />
              )}
              {stringActiuni && (
                <LabelWithValue
                  label="Acțiuni permise"
                  value={stringActiuni}
                  margin
                />
              )} */}
            </div>
          ) : null}
        </Paper>
      </>
    );
  } else {
    return <></>;
  }
};

export default SesiuneCard;
