import React from "react";
import { Img } from "react-image";
import {
  CircularProgress,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import PropTypes from "prop-types";
// import { motion, AnimatePresence } from "framer-motion";
import ZipIcon from "@material-ui/icons/Archive";
import DocIcon from "@material-ui/icons/InsertDriveFile";
import ImageIcon from "@material-ui/icons/Image";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import { acceptedFileFormats } from "../../utils/filesUtils";

const sizes = {
  xs: {
    box: "56px",
    img: "50px",
  },
  sm: {
    box: "106px",
    img: "100px",
  },
  full: {},
};

const variants = {
  enter: {
    opacity: 0,
    scale: 0,
  },
  center: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0,
  },

  duration: 0.4,
};

const File = ({ variant, onClick, type, file, index }) => {
  const classes = useStyles({ variant, type });

  // console.log("variant :>> ", variant);
  // console.log("type :>> ", type);
  // console.log("file :>> ", file);

  return (
    <>
      {type === "image" ? (
        <>
          {variant === "full" ? (
            <Img
              src={file.src}
              loader={<CircularProgress />}
              unloader={<BrokenImageIcon color="error" fontSize="large" />}
              style={{ maxWidth: "100%", height: "auto" }}
              alt="img"
            />
          ) : (
            <Box className={`${classes.base} ${classes.image}`}>
              <Img
                src={file.src}
                loader={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                }
                unloader={<BrokenImageIcon color="error" fontSize="large" />}
                style={{
                  cursor: "pointer",
                  width:
                    variant === "xs"
                      ? sizes.xs.img
                      : variant === "sm" && sizes.sm.img,
                  height:
                    variant === "xs"
                      ? sizes.xs.img
                      : variant === "sm" && sizes.sm.img,
                }}
                alt="img"
                onClick={onClick}
              />
            </Box>
          )}
        </>
      ) : type === "other" ? (
        <Box className={`${classes.base} ${classes.other}`}>
          <a href={file.src} download={file.name}>
            {file.name}
          </a>
        </Box>
      ) : (
        <Box className={`${classes.base} ${classes.placeholder}`}>
          {onClick ? ( //if actionable
            <>
              <input
                id={file.id || index}
                accept={acceptedFileFormats[file.type ? file.type : file.format]
                  .join(",")
                  .toString()}
                className={classes.inputImage}
                // multiple
                type="file"
                onChange={(ev) => onClick(file.id, ev)}
              />
              <label htmlFor={file.id || index} className={classes.inputLabel}>
                <Box display="flex" alignItems="center" color="secondary.main">
                  {file.format === "ZIP" || file.type === "ZIP" ? (
                    <ZipIcon className={classes.icon} />
                  ) : file.format === "DOC" || file.type === "DOC" ? (
                    <DocIcon className={classes.icon} />
                  ) : file.format === "IMAGE" || file.type === "IMAGE" ? (
                    <ImageIcon className={classes.icon} />
                  ) : file.format === "TEXT" || file.type === "TEXT" ? (
                    <ViewHeadlineIcon className={classes.icon} />
                  ) : null}
                  <Typography>
                    {file.format
                      ? file.format.toLowerCase()
                      : file.type && file.type.toLowerCase()}
                  </Typography>
                </Box>
              </label>
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              color="secondary.main"
            >
              <Box display="flex">
                {file.format === "ZIP" || file.type === "ZIP" ? (
                  <ZipIcon className={classes.icon} />
                ) : file.format === "DOC" || file.type === "DOC" ? (
                  <DocIcon className={classes.icon} />
                ) : file.format === "IMAGE" || file.type === "IMAGE" ? (
                  <ImageIcon className={classes.icon} />
                ) : file.format === "TEXT" ? (
                  <ViewHeadlineIcon className={classes.icon} />
                ) : null}
                <Typography>
                  {file.format
                    ? file.format.toLowerCase()
                    : file.type && file.type.toLowerCase()}
                </Typography>
              </Box>
              {file.additionalText && (
                <Box color="error.main">{file.additionalText}</Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default File;

const useStyles = makeStyles((theme) => ({
  base: ({ variant, type }) => ({
    width: variant === "xs" ? sizes.xs.box : variant === "sm" && sizes.sm.box,
    height: variant === "xs" ? sizes.xs.box : variant === "sm" && sizes.sm.box,
    border: `3px solid ${
      type === "placeholder"
        ? theme.palette.secondary.main
        : theme.palette.primary.main
    }`,
    borderRadius: "5px",
    backgroundColor: theme.palette.background.default,

    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  }),
  image: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  other: {
    wordBreak: "break-word",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    padding: "2px",
  },
  placeholder: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  //styles for type === "placeholder"
  inputImage: {
    display: "none",
  },
  inputLabel: {
    marginBottom: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  icon: {
    marginRight: "0.25rem",
  },
}));

File.propTypes = {
  variant: PropTypes.oneOf(["xs", "sm", "full"]),
  // sources: PropTypes.array,
  onClick: PropTypes.func,
  // title: PropTypes.string,
  type: PropTypes.oneOf(["image", "other", "placeholder"]),
  file: PropTypes.object, //{src: ..., name: ..., format: ..., actionable}; name is only for 'other'; format is only for 'placeholder'
  animated: PropTypes.bool,
};
