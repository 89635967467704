import {
  FETCH_CAZARI_REQUESTED,
  FETCH_CAZARI_SUCCESS,
  FETCH_CAZARI_ERROR,
  FETCH_CAZARE_BY_CERERE_ID_SUCCESS,
  FETCH_CAZARE_BY_ID_SUCCESS,
} from "./types";

const DEFAULT_STATE = {
  byId: {},
  byCerereId: {},
  ids: [],
  isFetching: false,
  error: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CAZARI_REQUESTED:
      return { ...state, isFetching: true };

    case FETCH_CAZARI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: action.payload.byId.entities.cazari ?? {},
        byCerereId: action.payload.byCerereId.entities.cazariByCerereId ?? {},
        ids: action.payload.byId.result,
      };

    case FETCH_CAZARE_BY_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: { ...state.byId, [action.payload.id]: action.payload },
      };

    case FETCH_CAZARE_BY_CERERE_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        byCerereId: {
          ...state.byCerereId,
          [action.payload.cerereId]: action.payload,
        },
      };

    case FETCH_CAZARI_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
