import * as Yup from "yup";

export const IdentitySchema = Yup.object().shape({
  adresaDomiciliu: Yup.string().nullable(),
  anStudiuId: Yup.number().required("Anul de studiu este obligatoriu"),
  cetatenie: Yup.string().nullable(),
  cnp: Yup.string()
    .required("CNP-ul este obligatoriu")
    .length(13, "CNP-ul trebuie să conțină 13 caractere")
    .matches(/^[0-9]*$/, "CNP-ul trebuie să conțină doar cifre"),
  dataCI: Yup.string()
    .required("Data cărții de identitate este obligatorie")
    .length(10, "Formatul trebuie să aibă 10 caractere"),
  emitentCI: Yup.string()
    .required("Emitentul cărții de identitate este obligatoriu")
    .max(255),
  facultateId: Yup.number().required("Facultatea este obligatorie"),
  formaFinantareSemestrul1: Yup.string().nullable(),
  formaFinantareSemestrul2: Yup.string().nullable(),
  idInscriere: Yup.number().nullable(),
  idPersoana: Yup.number().nullable(),
  initiale: Yup.string().nullable(),
  judetDomiciliu: Yup.string().nullable(),
  localitateDomiciliu: Yup.string().nullable(),
  medieCazare: Yup.number().nullable(),
  medieDepartajare1: Yup.number().nullable(),
  medieDepartajare2: Yup.number().nullable(),
  medieDepartajare3: Yup.number().nullable(),
  nationalitate: Yup.string().nullable(),
  numarCI: Yup.string()
    .required("Numărul cărții de identitate  este obligatoriu")
    .length(6, "Numărul cărții de identitate trebuie să conțină 6 cifre")
    .matches(
      /^[0-9]*$/,
      "Numărul cărții de identitate trebuie să conțină doar cifre"
    ),
  nume: Yup.string()
    .required("Numele este obligatoriu")
    .min(2, "Numele trebuie să conțină minimum 2 caractere")
    .max(250, "Numele trebuie să conțină maximum 250 caractere"),
  prenume: Yup.string()
    .required("Prenumele este obligatoriu")
    .min(2, "Prenumele trebuie să conțină minimum 2 caractere")
    .max(250, "Prenumele trebuie să conțină maximum 250 caractere"),
  programStudiuId: Yup.number().required(
    "Programul de studiu este obligatoriu"
  ),
  serieCI: Yup.string()
    .required("Seria cărții de identitate  este obligatorie")
    .length(2, "Serie cărții de identitate trebuie să conțină 2 caractere"),
  sex: Yup.string().nullable(),
  telefon: Yup.string()
    .required("Numărul de telefon este obligatoriu")
    .length(10, "Numărul de telefon trebuie să conțină 10 cifre")
    .matches(/^[0-9]*$/, "Numărul trebuie să conțină doar cifre")
});

export const CnpSchema = Yup.object().shape({
  cnp: Yup.string()
    .required("CNP-ul este obligatoriu")
    .length(13, "CNP-ul trebuie să conțină 13 caractere")
    .matches(/^[0-9]*$/, "CNP-ul trebuie să conțină doar cifre")
});

export const RequestSchema = Yup.object().shape({
  observatii: Yup.string().max(
    100,
    "Observațiile trebuie să conțină maximum 100 de caractere"
  )
});
