import authService from "../components/api-authorization/AuthorizeService";

export const acceptedFileFormats = {
  IMAGE: [
    "image/*",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ],
  DOC: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ],
  ZIP: ["application/x-zip-compressed"],
  TEXT: ["text/plain"],
  ALL: [
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/x-zip-compressed",
    "text/plain",
    "application/pdf"
  ],
};

export const maxSize = {
  name: "10MB",
  size: 10485760,
};

export const uploadFile = async (url, file) => {
  const token = await authService.getAccessToken();
  return new Promise((resolve, reject) => {
    if (file) {
      if (file.size <= maxSize.size) {
        let formData = new FormData();
        formData.append("image", file);
        let headers = new Headers();
        headers.set("Authorization", `Bearer ${token}`);
        try {
          resolve(
            fetch(url, {
              method: "POST",
              headers: headers,
              body: formData,
            }).then((res) => res.json())
          );
        } catch (err) {
          reject("Could not upload file");
        }
      } else {
        reject(`Size of file must be under ${maxSize.name}`);
      }
    } else {
      resolve("");
    }
  });
};
