export default {
  NEACHITAT: 1,
  ACHITAT: 2,
  ANULAT: 3,
};

export const getDenumireStatusPlata = (status) => {
  switch (status) {
    case 1:
      return "Neachitat";
    case 2:
      return "Achitat";
    case 3:
      return "Anulat";
    default:
      return "-";
  }
};
