import { Divider, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: "8px",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    margin: "16px 0 0",
  },
  containerMarginBottom: {
    margin: "16px 0 16px",
  },
  grow: {
    flexGrow: 1,
  },
  divider: {
    margin: "4px 0 4px",
  },
}));

const HeaderWithDivider = ({
  icon,
  title,
  secondaryTitle,
  description,
  marginBottom,
  size,
}) => {
  const classes = useStyles();

  const Icon = icon;
  return (
    <>
      <div
        className={
          marginBottom ? classes.containerMarginBottom : classes.container
        }
      >
        <div className={classes.title}>
          {icon && (
            <Icon
              fontSize={size === "small" ? size : undefined}
              className={classes.icon}
            />
          )}
          <Typography
            display="inline"
            variant={size === "small" ? "body1" : "h6"}
          >
            {title}
          </Typography>
          <div className={classes.grow}></div>
          {secondaryTitle && (
            <Typography variant="body1" color="textSecondary">
              {secondaryTitle}
            </Typography>
          )}
        </div>
        <Divider className={classes.divider} />
        {description && (
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
    </>
  );
};

export default HeaderWithDivider;
