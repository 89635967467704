import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchIdentitate, setTitle } from "../../actions/";
import appRoutes from "../../utils/appRoutes";
import checkRole from "../../utils/checkRole";
import roles from "../../utils/roles";
import strings from "../../utils/strings";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import SectionCard from "../utils/SectionCard";
import CardIdentitate from "./CardIdentitate";
import Covid from "./Covid";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(state => state.profile.user);
  const identitate = useSelector(state => state.profile.identitate);
  const isFetching = useSelector(state => state.profile.isFetching);
  const isStudent = checkRole(user, roles.STUDENT);

  useEffect(() => {
    if (!identitate) {
      dispatch(fetchIdentitate());
    }
    dispatch(setTitle(strings.PROFILE));
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <SectionCard
            title={strings.MANAGE_ACCOUNT}
            description={strings.MANAGE_ACCOUNT_DESCRIPTION}
            actionName={strings.MANAGE_ACCOUNT_BUTTON}
            action={() => history.push(ApplicationPaths.Profile)}
          />

          {isStudent ? (
            identitate ? (
              <>
                <CardIdentitate {...identitate} />
                {/* <Covid /> */}
              </>
            ) : isFetching ? (
              <Box margin="2rem" textAlign="center">
                <CircularProgress disableShrink />
              </Box>
            ) : null
          ) : (
            <SectionCard
              title={strings.COMPLETE_YOUR_PROFILE}
              description={strings.COMPLETE_YOUR_PROFILE_DESCRIPTION}
              actionName={strings.COMPLETE_YOUR_PROFILE_ACTION}
              action={() => history.push(appRoutes.COMPLETE_PROFILE)}
            />
          )}
        </Box>
      </div>
    </>
  );
};
