export const ACCOMMODATION_ERROR = "ACCOMMODATION_ERROR";

// profil
export const FETCH_FACULTATI = "FETCH_FACULTATI";
export const FETCH_ANI_STUDIU = "FETCH_ANI_STUDIU";
export const FETCH_IDENTITATE_REQUESTED = "FETCH_IDENTITATE_REQUESTED";
export const FETCH_IDENTITATE_SUCCESS = "FETCH_IDENTITATE_SUCCESS";
export const FETCH_PROFIL_ERROR = "FETCH_PROFIL_ERROR";
export const VALIDARE_IDENTITATE_SUCCESS = "VALIDARE_IDENTITATE_SUCCESS";
export const ACTUALIZARE_IDENTITATE_SUCCESS = "ACTUALIZARE_IDENTITATE_SUCCESS";
export const VALIDARE_CNP_SUCCESS = "VALIDARE_CNP_SUCCESS";
export const SET_IDENTITATE_DE_VALIDAT = "SET_IDENTITATE_DE_VALIDAT";
export const PROFIL_SET_USER = "PROFIL_SET_USER";
export const PATCH_COVID = "PATCH_COVID";

// sesiuni
export const FETCH_SESIUNI_REQUESTED = "FETCH_SESIUNI_REQUESTED";
export const FETCH_SESIUNI_SUCCESS = "FETCH_SESIUNI_SUCCESS";
export const FETCH_SESIUNE_BY_ID_SUCCESS = "FETCH_SESIUNE_BY_ID_SUCCESS";
export const FETCH_SESIUNI_ERROR = "FETCH_SESIUNI_ERROR";

// cereri
export const FETCH_CERERI_REQUESTED = "FETCH_CERERI_REQUESTED";
export const FETCH_CERERE_BY_SESIUNE_ID_SUCCESS = "FETCH_CERERE_BY_SESIUNE_ID";
export const FETCH_CERERE_BY_ID = "FETCH_CERERE_BY_ID";
export const FETCH_CERERI_SUCCESS = "FETCH_CERERI_SUCCESS";
export const FETCH_CONTRACANDIDATI_BY_SESIUNE_ID =
  "FETCH_CONTRACANDIDATI_BY_SESIUNE_ID";
export const CREARE_CERERE_SUCCESS = "CREARE_CERERE_SUCCESS";
export const STERGERE_CERERE_SUCCESS = "STERGERE_CERERE_BY_SESIUNE_ID_SUCCESS";
export const MODIFICARE_CERERE_SUCCESS = "MODIFICARE_CERERE_SUCCESS";
export const FETCH_CERERI_ERROR = "FETCH_CERERI_ERROR";

// app
export const APP_SET_TITLE = "APP_SET_TITLE";
export const APP_SET_LANGUAGE = "APP_SET_LANGUAGE";
export const APP_SET_IS_LOADING = "APP_SET_IS_LOADING";
export const APP_SET_APP_BAR_BACK_LOCATION = "APP_SET_APP_BAR_BACK_LOCATION";
export const APP_SWITCH_COLOR_MODE = "APP_SWITCH_COLOR_MODE";
export const APP_SET_GOOGLE_ANALYTICS_CONSENT =
  "APP_SET_GOOGLE_ANALYTICS_CONSENT";

// complexe
export const FETCH_COMPLEXE_REQUESTED = "FETCH_COMPLEXE_REQUESTED";
export const FETCH_COMPLEXE_BY_SESIUNE_ID_SUCCESS =
  "FETCH_COMPLEXE_BY_SESIUNE_ID";
export const FETCH_COMPLEXE_ERROR = "FETCH_COMPLEXE_ERROR";
