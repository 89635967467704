import React, { useState, useEffect } from "react";
import { makeStyles, Box, AppBar } from "@material-ui/core";
import * as actions from "../../actions";
import authService from "../api-authorization/AuthorizeService";
import AppBarMUI from "./AppBarMUI";
import TemporaryDrawer from "./TemporaryDrawer";
import { setAction } from "../../actions/actionsTemplate";
import { PROFIL_SET_USER } from "../../actions/types";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import CookiesBanner from "../utils/CookiesBanner";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    // justifyContent: 'flex-end',
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    minHeight: "60px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: "1rem",
    marginBottom: "4rem",
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isAuthed, setIsAuthed] = useState(false);
  const googleAnalyticsConsent = useSelector(
    (state) => state.app.googleAnalyticsConsent
  );

  const { children } = props;

  const getIsAuthenticated = async () => {
    setIsAuthed(await authService.isAuthenticated());
    const user = await authService.getUser();
    props.setAction(user, PROFIL_SET_USER);
  };

  useEffect(() => {
    getIsAuthenticated();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {/* {Boolean(
        googleAnalyticsConsent === null ||
          typeof googleAnalyticsConsent === "undefined"
      ) && <CookiesBanner />} */}

      <Box display="flex">
        <AppBar position="fixed" className={classes.appBar}>
          <AppBarMUI
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isAuthed={isAuthed}
          />
        </AppBar>
      </Box>
      <TemporaryDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      <main className={classes.content}>
        <div className={classes.toolbar} />

        {children}
      </main>
    </div>
  );
};

const enhance = compose(connect(null, actions));

export default enhance(Layout);
