import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";

const ListItemCustom = props => {
  const { primaryText, secondaryText, avatar, action, id } = props;
  return (
    <>
      <ListItem
        button={action ? true : false}
        onClick={action ? () => action(id) : null}
      >
        {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </ListItem>
    </>
  );
};

ListItemCustom.propTypes = {
  primaryText: PropTypes.string.isRequired,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  avatar: PropTypes.element,
  action: PropTypes.func
};

const enhance = compose(withRouter);

export default enhance(ListItemCustom);
