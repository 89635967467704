import { get, patch, post, put } from "../utils/restUtilities";
import strings from "../utils/strings";
import {
  ACTUALIZARE_IDENTITATE_SUCCESS,
  FETCH_ANI_STUDIU,
  FETCH_FACULTATI,
  FETCH_IDENTITATE_REQUESTED,
  FETCH_IDENTITATE_SUCCESS,
  FETCH_PROFIL_ERROR,
  PATCH_COVID,
  SET_IDENTITATE_DE_VALIDAT,
  VALIDARE_CNP_SUCCESS,
  VALIDARE_IDENTITATE_SUCCESS
} from "./types";

const errorObject = {
  type: FETCH_PROFIL_ERROR,
  payload: strings.REQUEST_ERROR
};

// pentru profil
export const fetchIdentitate = () => async dispatch => {
  try {
    dispatch({ type: FETCH_IDENTITATE_REQUESTED });
    const res = await get("/api/info/identitate");
    switch (res.status) {
      case 200:
        dispatch({ type: FETCH_IDENTITATE_SUCCESS, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
  }
};

// se obtine identitatea de validat
export const validareCnp = cnp => async dispatch => {
  try {
    dispatch({ type: FETCH_IDENTITATE_REQUESTED });
    // de facut request multiplu
    const res = await get(`/api/info/identitate?cnp=${cnp}`);
    switch (res.status) {
      case 200:
        dispatch({ type: VALIDARE_CNP_SUCCESS, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

export const setIdentiateDeValidat = identitate => async dispatch => {
  dispatch({ type: SET_IDENTITATE_DE_VALIDAT, payload: identitate });
};

// se valideaza identitatea
export const validareIdentitate = data => async dispatch => {
  try {
    dispatch({ type: FETCH_IDENTITATE_REQUESTED });
    const res = await post("/api/info/identitate", data);
    // TODO PUT daca are deja rol
    // de tinut cont si de ruta
    // de folosit doar cnp-ul existent
    switch (res.status) {
      case 201:
        dispatch({
          type: VALIDARE_IDENTITATE_SUCCESS,
          payload: res.content
        });
        break;
      case 409:
        dispatch({
          type: FETCH_PROFIL_ERROR,
          payload: strings.CNP_FOLOSIT
        });
        return Promise.reject(strings.CNP_FOLOSIT);
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

// se actualizeaza identitatea
export const actualizareIdentitate = data => async dispatch => {
  try {
    dispatch({ type: FETCH_IDENTITATE_REQUESTED });
    const res = await put("/api/info/identitate/actualizare", data);

    switch (res.status) {
      case 200:
        dispatch({
          type: ACTUALIZARE_IDENTITATE_SUCCESS,
          payload: res.content
        });
        break;
      case 409:
        dispatch({
          type: FETCH_PROFIL_ERROR,
          payload: strings.CNP_FOLOSIT
        });
        return Promise.reject(strings.CNP_FOLOSIT);
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

// pentru formularul de identiate
export const fetchFacultati = () => async dispatch => {
  try {
    const res = await get("/api/info/facultati");
    switch (res.status) {
      case 200:
        dispatch({ type: FETCH_FACULTATI, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
  }
};

// pentru formularul de identiate
export const fetchAniStudiu = () => async dispatch => {
  try {
    const res = await get("/api/info/ani-studiu");
    switch (res.status) {
      case 200:
        dispatch({ type: FETCH_ANI_STUDIU, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
  }
};

//patch COVID
export const patchCovid = data => async dispatch => {
  try {
    const res = await patch("/api/info/identitate/status-covid", data);
    switch (res.status) {
      case 200:
        dispatch({ type: PATCH_COVID, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    console.log("err");
    dispatch(errorObject);
  }
};
