import React, { useEffect } from "react";
import CardCazare from "./CardCazare";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import appRoutes from "../../utils/appRoutes";
import { setBackLocation, setTitle } from "../../actions";
import strings from "../../utils/strings";
import { fetchCazareById } from "../../store/cazari/actions";

import { makeStyles } from "@material-ui/core";
import { snackbarError } from "../../utils/snackbarVariants";

const useStyles = makeStyles((theme) => ({
  divProgress: {
    textAlign: "center",
  },
}));

const VizualizareCazare = () => {
  const classes = useStyles();

  const { cazareId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const cazare = useSelector((state) => state.cazari.byId[cazareId]);
  const isFetching = useSelector((state) => state.cazari.isFetching);
  const error = useSelector((state) => state.cazari.error);

  useEffect(() => {
    // if (!cazare) {
    dispatch(fetchCazareById(cazareId));
    // history.push(appRoutes.CAZARI);
    // }
    dispatch(setBackLocation(appRoutes.CAZARI));
    dispatch(setTitle(strings.DETALII_CAZARE));
    return () => dispatch(setBackLocation(null));
  }, []);

  useEffect(() => {
    if (error) {
      history.push(appRoutes.CAZARI);
    }
  }, [error]);

  return (
    <>
      {cazare ? (
        <CardCazare {...cazare} />
      ) : isFetching ? (
        <div className={classes.divProgress}>
          <CircularProgress disableShrink />
        </div>
      ) : null}
    </>
  );
};

export default VizualizareCazare;
