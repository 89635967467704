import strings from "../utils/strings";
import {
  FETCH_COMPLEXE_REQUESTED,
  FETCH_COMPLEXE_BY_SESIUNE_ID_SUCCESS,
  FETCH_COMPLEXE_ERROR,
} from "./types";
import { get } from "../utils/restUtilities";

const errorObject = {
  type: FETCH_COMPLEXE_ERROR,
  payload: strings.REQUEST_ERROR,
};

export const fetchComplexeBySesiuneId = (sesiuneId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_COMPLEXE_REQUESTED });

    const res = await get(`/api/complexe-cazare?sesiuneId=${sesiuneId}`);

    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_COMPLEXE_BY_SESIUNE_ID_SUCCESS,
          payload: { sesiuneId, complexe: res.content },
        });

        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};
