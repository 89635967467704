import React from "react";
import PropTypes from "prop-types";

import { TextField, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconTextField: {
    margin: "30px 16px 0px 0px",
  },
}));

const TextFieldCustom = ({
  label,
  helperText,
  value,
  setValue,
  error,
  setError,
  fullWidth,
  variant,
  type,
  multiline,
  rows,
  rowsMax,
  // defaultValue,
  disabled,
  icon,
}) => {
  const classes = useStyles();

  const Icon = icon;

  return (
    <>
      <Box display="flex" width="100%">
        {Icon && <Icon className={classes.iconTextField} />}
        <TextField
          type={type ? type : "text"}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setError("");
          }}
          margin="normal"
          fullWidth={fullWidth ? fullWidth : undefined}
          multiline={multiline ? multiline : undefined}
          rows={rows ? rows : undefined}
          rowsMax={rowsMax ? rowsMax : undefined}
          label={label}
          variant={variant}
          helperText={error?.length > 0 ? error : helperText ? helperText : ""}
          error={Boolean(error)}
          disabled={disabled}
        />
      </Box>
    </>
  );
};

TextFieldCustom.propTypes = {
  type: PropTypes.oneOf(["number", "password"]),
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(["outlined", "standard", "filled"]).isRequired,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  // defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  icon: PropTypes.object,
};

export default TextFieldCustom;
