import {
  Box,
  CircularProgress,
  FormHelperText,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PersonIcon from "@material-ui/icons/Person";
import PublishIcon from "@material-ui/icons/Publish";
import SchoolIcon from "@material-ui/icons/School";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  actualizareIdentitate,
  fetchAniStudiu,
  fetchFacultati,
  setBackLocation,
  setIdentiateDeValidat,
  setTitle,
  validareIdentitate
} from "../../actions";
import appRoutes from "../../utils/appRoutes";
import { snackbarError, snackbarSuccess } from "../../utils/snackbarVariants";
import strings from "../../utils/strings";
import { IdentitySchema } from "../../utils/validations";
import authService, {
  AuthenticationResultStatus
} from "../api-authorization/AuthorizeService";
import ExtendedFab from "../utils/ExtendedFab";
import HeaderWithDivider from "../utils/HeaderWithDivider";
import SelectCustom from "../utils/SelectCustom";
import TextFieldCustom from "../utils/TextFieldCustom";

const useStyles = makeStyles(theme => ({
  paperFormular: {
    padding: "1rem",
    marginTop: "2rem"
  },
  paperAnunt: {
    minHeight: "3rem",
    padding: "1rem"
  },
  mesajGdpr: {
    margin: "1rem 0 1rem",
    textAlign: "center"
  }
}));

const IdentitateForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isActualizare = location?.pathname.split("/")?.[2] === "actualizare";

  const facultati = useSelector(state => state.profile.facultati);
  const aniStudiu = useSelector(state => state.profile.aniStudiu);

  const isFetching = useSelector(state => state.profile.isFetching);
  const identitateDeValidat = useSelector(
    state => state.profile.identitateDeValidat
  );
  const error = useSelector(state => state.profile.error);

  // console.log("identity :", identity);
  let updatedProps = useRef(error);
  updatedProps.current = error;
  // fields
  const [nume, setNume] = useState("");
  const [prenume, setPrenume] = useState("");
  const [cnp, setCnp] = useState("");
  const [serieBuletin, setSerieBuletin] = useState("");
  const [numarBuletin, setNumarBuletin] = useState("");
  const [facultateId, setFacultateId] = useState(null);
  const [programStudiuId, setProgramStudiuId] = useState(null);
  const [anStudiuId, setAnStudiuId] = useState(null);
  const [emitentCI, setEmitentCI] = useState("");
  const [dataCI, setDataCI] = useState("");
  const [telefon, setTelefon] = useState("");

  const [specializariOptions, setSpecializariOptions] = useState(null);
  const [aniStudiuOptions, setAniStudiuOptions] = useState(null);

  // error fields
  const [numeError, setNumeError] = useState("");
  const [prenumeError, setPrenumeError] = useState("");
  const [cnpError, setCnpError] = useState("");
  const [serieBuletinError, setSerieBuletinError] = useState("");
  const [numarBuletinError, setNumarBuletinError] = useState("");
  const [facultateError, setFacultateError] = useState("");
  const [specializareError, setSpecializareError] = useState("");
  const [anStudiuError, setAnStudiuError] = useState("");
  const [emitentCIError, setEmitentCIError] = useState("");
  const [dataCIError, setDataCIError] = useState("");
  const [telefonError, setTelefonError] = useState("");

  useEffect(() => {
    if (identitateDeValidat) {
      // console.log("identity :", identity);
      setNume(identitateDeValidat.nume);
      setPrenume(identitateDeValidat.prenume);
      setCnp(identitateDeValidat.cnp);
      setSerieBuletin(identitateDeValidat.serieCI);
      setNumarBuletin(identitateDeValidat.numarCI);
      setFacultateId(identitateDeValidat.facultateId);
      setEmitentCI(identitateDeValidat.emitentCI);
      setDataCI(identitateDeValidat.dataCI);
    }
  }, [identitateDeValidat]);

  // mount
  useEffect(() => {
    dispatch(fetchFacultati());
    dispatch(fetchAniStudiu());
    dispatch(setBackLocation(appRoutes.PROFILE));
    dispatch(setTitle(strings.COMPLETE_YOUR_PROFILE));

    return () => {
      dispatch(setBackLocation(null));
      dispatch(setIdentiateDeValidat(null));
    };
  }, []);

  useEffect(() => {
    if (facultati && facultateId) {
      let facultateSelectata = facultati.find(e => e.id == facultateId);
      if (facultateSelectata) {
        setSpecializariOptions(facultateSelectata.programeStudiu);
        if (
          facultateSelectata.programeStudiu &&
          facultateSelectata.programeStudiu.length > 0
        ) {
          setProgramStudiuId(identitateDeValidat.programStudiuId);
        }
      }
    }
  }, [facultateId, facultati]);

  useEffect(() => {
    if (aniStudiu && programStudiuId) {
      let facultateSelectata = facultati.find(e => e.id == facultateId);
      if (facultateSelectata && facultateSelectata.programeStudiu) {
        let cicluStudiiId = facultateSelectata.programeStudiu.find(
          e => e.id == programStudiuId
        ).ciclu.id;
        // let aniStudiuOptions = aniStudiu.filter(e => e.id == cicluStudiiId);
        if (cicluStudiiId === 1 || cicluStudiiId === 3) {
          setAniStudiuOptions(aniStudiu);
        }
        if (cicluStudiiId === 2) {
          setAniStudiuOptions(aniStudiu.filter(e => e.id != 3));
        }
      }
    }
    if (aniStudiu && aniStudiu.length > 0) {
      setAnStudiuId(identitateDeValidat.anStudiuId);
    }
  }, [programStudiuId, aniStudiu]);

  // // schimbarea facultatii
  // useEffect(() => {
  //   if (facultati && facultateId) {
  //     let facultateSelectata = facultati.find(e => e.id == facultateId);
  //     if (facultateSelectata) {
  //       let programeStudiu = facultateSelectata.programeStudiu;
  //       setSpecializariOptions(programeStudiu); // incarcare lista de programe de studiu
  //       if (programeStudiu && programeStudiu.length > 0) {
  //         setProgramStudiuId(programeStudiu[0].id);
  //       } // selectarea primului program de studii
  //     }
  //   } else {
  //     setSpecializariOptions(null);
  //     setProgramStudiuId(null);
  //     setAniStudiuOptions(null);
  //     setAnStudiuId(null);
  //   }
  // }, [facultateId]);

  // // schimbarea programului de studiu
  // useEffect(() => {
  //   if (aniStudiu && programStudiuId) {
  //     let facultateSelectata = props.facultati.find(e => e.id == facultateId);
  //     if (facultateSelectata && facultateSelectata.programeStudiu) {
  //       let cicluStudiiId = facultateSelectata.programeStudiu.find(
  //         e => e.id == programStudiuId
  //       ).ciclu.id;
  //       let aniStudiuOptions = aniStudiu.filter(
  //         e => e.cicluStudii.id == cicluStudiiId
  //       );
  //       setAniStudiuOptions(aniStudiuOptions);
  //       if (aniStudiuOptions && aniStudiuOptions.length > 0) {
  //         setAnStudiuId(aniStudiuOptions[0].id);
  //       }
  //     }
  //   } else {
  //     setAniStudiuOptions(null);
  //     setAnStudiuId(null);
  //   }
  // }, [programStudiuId]);

  // clear errors on selects
  useEffect(() => {
    setFacultateError("");
  }, [facultateId]);

  useEffect(() => {
    setSpecializareError("");
  }, [programStudiuId]);

  useEffect(() => {
    setAnStudiuError("");
  }, [anStudiuId]);

  const submitPostIdentitate = async res => {
    dispatch(validareIdentitate(res))
      .then(async () => {
        enqueueSnackbar(strings.IDENTITATE_VALIDATA, snackbarSuccess);
        history.push(appRoutes.PROFILE);
        const returnUrl = appRoutes.PROFILE;
        const result = await authService.signIn(returnUrl);
        switch (result.status) {
          case AuthenticationResultStatus.Success:
            window.location.replace(returnUrl);
            break;
          default:
            history.push(appRoutes.AUTO_LOG_OUT);
            break;
        }
      })
      .catch(err => enqueueSnackbar(err, snackbarError));
  };

  const submitPutIdentitate = async res => {
    dispatch(actualizareIdentitate(res)).then(async () => {
      enqueueSnackbar(strings.IDENTITATE_ACTUALIZATA, snackbarSuccess);
      history.push(appRoutes.PROFILE);
    });
  };

  const catchErrors = err => {
    console.log("err :", err);
    enqueueSnackbar(strings.VALIDATION_ERROR, snackbarError);
    const errors = err.inner;
    let pathMarker = "";
    errors.forEach(error => {
      if (error.path !== pathMarker) {
        switch (error.path) {
          case "nume":
            setNumeError(error.message);
            break;
          case "prenume":
            setPrenumeError(error.message);
            break;
          case "cnp":
            setCnpError(error.message);
            break;
          case "numarCI":
            setNumarBuletinError(error.message);
            break;
          case "serieCI":
            setSerieBuletinError(error.message);
            break;
          case "facultateId":
            setFacultateError(error.message);
            break;
          case "programStudiuId":
            setSpecializareError(error.message);
            break;
          case "anStudiuId":
            setAnStudiuError(error.message);
            break;
          case "dataCI":
            setDataCIError(error.message);
            break;
          case "emitentCI":
            setEmitentCIError(error.message);
            break;
          case "telefon":
            setTelefonError(error.message);
            break;
          default:
            break;
        }
      }
      pathMarker = error.path;
    });
  };

  const handleSubmit = async () => {
    const dataToValidate = {
      adresaDomiciliu: identitateDeValidat.adresaDomiciliu,
      anStudiuId: Number(anStudiuId),
      cetatenie: identitateDeValidat.cetatenie,
      cnp: cnp.trim(),
      dataCI: dataCI.trim(),
      emitentCI: emitentCI.trim(),
      facultateId: Number(facultateId),
      formaFinantareSemestrul1: identitateDeValidat.formaFinantareSemestrul1,
      formaFinantareSemestrul2: identitateDeValidat.formaFinantareSemestrul2,
      idInscriere: identitateDeValidat.idInscriere,
      idPersoana: identitateDeValidat.idPersoana,
      initiale: identitateDeValidat.initiale,
      judetDomiciliu: identitateDeValidat.judetDomiciliu,
      localitateDomiciliu: identitateDeValidat.localitateDomiciliu,
      medieCazare: identitateDeValidat.medieCazare,
      medieDepartajare1: identitateDeValidat.medieDepartajare1,
      medieDepartajare2: identitateDeValidat.medieDepartajare2,
      medieDepartajare3: identitateDeValidat.medieDepartajare3,
      nationalitate: identitateDeValidat.nationalitate,
      numarCI: numarBuletin.trim(),
      nume: nume.trim(),
      prenume: prenume.trim(),
      programStudiuId: Number(programStudiuId),
      serieCI: serieBuletin.trim().toUpperCase(),
      sex: identitateDeValidat.sex === "F" ? "F" : "B",
      telefon: telefon.trim()
    };
    if (isActualizare) {
      IdentitySchema.validate(dataToValidate, { abortEarly: false })
        .then(submitPutIdentitate)
        .catch(catchErrors);
    } else {
      IdentitySchema.validate(dataToValidate, { abortEarly: false })
        .then(submitPostIdentitate)
        .catch(catchErrors);
    }
  };

  return (
    <>
      <Paper className={classes.paperAnunt} elevation={4}>
        <Typography variant="body1" align="center">
          {strings.COMPLETE_YOUR_PROFILE_DESCRIPTION}
        </Typography>
      </Paper>

      <Paper className={classes.paperFormular} elevation={4}>
        <HeaderWithDivider title={strings.DATE_PERSONALE} icon={PersonIcon} />
        <TextFieldCustom
          error={numeError}
          setError={setNumeError}
          fullWidth
          label={strings.FIRST_NAME}
          value={nume}
          setValue={setNume}
          variant="outlined"
          disabled
        />
        <TextFieldCustom
          error={prenumeError}
          setError={setPrenumeError}
          fullWidth
          label={strings.SURNAME}
          value={prenume}
          setValue={setPrenume}
          variant="outlined"
          disabled
        />

        <TextFieldCustom
          error={cnpError}
          setError={setCnpError}
          fullWidth
          label={strings.CNP_HELPER_TEXT}
          value={cnp}
          setValue={setCnp}
          variant="outlined"
          disabled
        />

        <HeaderWithDivider
          title={strings.CARTEA_DE_IDENTITATE}
          icon={AccountBalanceWalletIcon}
          description={strings.MODIFICA_DACA_E_NECESAR}
        />
        <TextFieldCustom
          error={serieBuletinError}
          setError={setSerieBuletinError}
          fullWidth
          label={strings.SERIE_BULETIN}
          value={serieBuletin}
          setValue={setSerieBuletin}
          variant="outlined"
        />
        <TextFieldCustom
          error={numarBuletinError}
          setError={setNumarBuletinError}
          fullWidth
          label={strings.NUMAR_BULETIN}
          value={numarBuletin}
          setValue={setNumarBuletin}
          variant="outlined"
        />
        {/* TO ADD */}
        <TextFieldCustom
          error={dataCIError}
          setError={setDataCIError}
          fullWidth
          label={strings.DATA_CI}
          value={dataCI}
          setValue={setDataCI}
          variant="outlined"
          helperText={strings.DATA_CI_HELPER_TEXT}
        />
        <TextFieldCustom
          error={emitentCIError}
          setError={setEmitentCIError}
          fullWidth
          label={strings.EMITENT_CI}
          value={emitentCI}
          setValue={setEmitentCI}
          variant="outlined"
        />

        <HeaderWithDivider title={strings.PROFIL_STUDII} icon={SchoolIcon} />

        {facultati && facultati.length !== 0 ? (
          <>
            <SelectCustom
              label={strings.FACULTY}
              options={facultati.map(e => ({
                value: e.id,
                text: e.denumire
              }))}
              value={facultateId}
              setValue={setFacultateId}
              fullWidth
              disabled
            />
            {facultateError && (
              <Box marginLeft="1rem">
                <FormHelperText error>{facultateError}</FormHelperText>
              </Box>
            )}
          </>
        ) : (
          <CircularProgress disableShrink />
        )}
        {specializariOptions && specializariOptions.length !== 0 ? (
          <SelectCustom
            label={strings.PROGRAM_STUDIU}
            options={specializariOptions.map(e => ({
              value: e.id,
              text: `${e.denumire} - ${e.ciclu.denumire}`
            }))}
            value={programStudiuId}
            setValue={setProgramStudiuId}
            fullWidth
            disabled={
              !(anStudiuId === 0 && identitateDeValidat.tipCicluStudiiId === 3)
            } // pentru doctoranzi
          />
        ) : null}
        {aniStudiuOptions && aniStudiuOptions.length !== 0 ? (
          <SelectCustom
            label={strings.YEAR}
            options={aniStudiuOptions.map(e => ({
              value: e.id,
              text: e.denumire
            }))}
            value={anStudiuId}
            setValue={setAnStudiuId}
            fullWidth
            disabled
          />
        ) : null}
        {specializareError && (
          <Box marginLeft="1rem">
            <FormHelperText error>{specializareError}</FormHelperText>
          </Box>
        )}
        {anStudiuError && (
          <Box marginLeft="1rem">
            <FormHelperText error>{anStudiuError}</FormHelperText>
          </Box>
        )}

        <HeaderWithDivider title={strings.CONTACT} icon={ContactPhoneIcon} />
        <TextFieldCustom
          error={telefonError}
          setError={setTelefonError}
          fullWidth
          label={strings.PHONE}
          value={telefon}
          setValue={setTelefon}
          variant="outlined"
        />

        <Typography
          className={classes.mesajGdpr}
          variant="body2"
          color="textSecondary"
        >
          {strings.GDPR_TRIMITE}
        </Typography>
      </Paper>

      <ExtendedFab
        action={handleSubmit}
        disabled={isFetching}
        text={strings.SUBMIT}
        icon={PublishIcon}
      />
    </>
  );
};

export default IdentitateForm;
