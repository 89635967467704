import React, { useEffect, useState } from "react";
import { makeStyles, CircularProgress, Box, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import appRoutes from "../utils/appRoutes";
import CardCerere from "../components/accommodation/CardCerere";
import SesiuneCard from "../components/accommodation/SesiuneCard";
import {
  fetchContracandidatiBySesiuneId,
  setBackLocation,
  fetchCerereById,
} from "../actions";
import TabelContracandidati from "../components/istoric/TabelContracandidati";
import {
  fetchCazareByCerereId,
  fetchCazareById,
} from "../store/cazari/actions";
import CardCazare from "../components/cazari/CardCazare";
import strings from "../utils/strings";
import PeopleIcon from "@material-ui/icons/People";

const useStyles = makeStyles((theme) => ({
  divCircularProgress: {
    textAlign: "center",
    marginTop: "2rem",
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams(); // cerereId

  const cerere = useSelector((state) => state.cereri.byId[id]);
  const cerereIsFetching = useSelector((state) => state.cereri.isFetching);
  const cazare = useSelector((state) => state.cazari.byCerereId[cerere?.id]);

  const contracandidati = useSelector(
    (state) => state.cereri?.contracandidatiByCerereId?.[id]
  );

  const contracandidatiIsFetching = useSelector(
    (state) => state.cereri.isFetching
  );

  const [contracandidatiIsShowing, setContracandidatiIsShowing] = useState(
    false
  );

  const fetchContracandidati = (sesiuneId) => {
    if (sesiuneId) {
      dispatch(fetchContracandidatiBySesiuneId(sesiuneId, id));
    }
  };

  useEffect(() => {
    if (!cerere) {
      // history.replace(appRoutes.ISTORIC_CERERI);
      dispatch(fetchCerereById(id));
    } else {
      // if (!cazare) {
      dispatch(fetchCazareByCerereId(cerere.id));
      // }
      dispatch(setBackLocation(appRoutes.ISTORIC_CERERI));
    }
    return () => {
      dispatch(setBackLocation(null));
    };
  }, [cerere]);

  useEffect(() => {
    if (contracandidatiIsShowing) {
      const idSesiune = cerere?.sesiuneCazare?.id;
      if (idSesiune) {
        fetchContracandidati(idSesiune);
      }
    }
  }, [contracandidatiIsShowing]);

  const contracandidatiExists = contracandidati?.length;

  if (cerere) {
    return (
      <>
        <SesiuneCard {...cerere.sesiuneCazare} />
        <CardCerere cerere={cerere} readOnly={true} />
        {cazare && <CardCazare {...cazare} />}

        {contracandidatiIsShowing ? (
          contracandidatiExists ? (
            <TabelContracandidati contracandidati={contracandidati} />
          ) : contracandidatiIsFetching ? (
            <Box textAlign="center" marginTop="2rem">
              <CircularProgress disableShrink />
            </Box>
          ) : null
        ) : (
          <Box display="flex" justifyContent="center" marginTop="2rem">
            <Button
              startIcon={<PeopleIcon />}
              variant="contained"
              color="primary"
              onClick={() => setContracandidatiIsShowing(true)}
            >
              {strings.VIZUALIZEAZA_CONTRACANDIDATI}
            </Button>
          </Box>
        )}
      </>
    );
  } else {
    return (
      <>
        {cerereIsFetching ? (
          <div className={classes.divCircularProgress}>
            <CircularProgress disableShrink />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
};
