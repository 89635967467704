import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import FaceIcon from "@material-ui/icons/Face";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../actions";
import { get } from "../../utils/restUtilities";
import TextFieldCustom from "../utils/TextFieldCustom";
import strings from "./../../utils/strings";

const useStyles = makeStyles(theme => ({
  divSus: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%"
  },
  divIsFrate: {
    alignItems: "start"
  },
  divCheckboxes: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  formControlLabel: {
    marginBottom: "0"
  },
  submitButton: {
    backgroundColor: theme.palette.background.default
  }
}));

const SelectColeg = ({ coleg, setColeg, disabled, isLast }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [cod, setCod] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    if (cod && cod.length > 1 && cod.length < 500) {
      setIsLoading(true);
      const res = await get(`/api/cereri-cazare/preferinte-coleg/${cod}`);
      switch (res.status) {
        case 200:
          setColeg({ ...res.content, isFrate: false, isNeaparat: false });
          setCod("");
          break;
        case 404:
          setError("Codul de coleg nu este valid");
          break;
        case 409:
          setError("Colegul trebuie să aibă același sex");
          break;
        default:
          break;
      }
      setIsLoading(false);
    } else {
      setError("Introdu codul colegului dorit");
    }
  };

  const handleClear = () => {
    setColeg(null);
    setCod("");
  };

  // console.log("coleg :>> ", coleg);

  if (disabled) {
    return <></>;
  } else {
    return (
      <>
        <Box display="flex" flexDirection="row" padding="0 0rem 0">
          {coleg ? (
            <>
              <div className={classes.divSus}>
                <TextField
                  margin="normal"
                  label="Coleg validat"
                  value={coleg.numeComplet}
                  disabled
                  fullWidth
                  variant="outlined"
                />

                {/* <div className={classes.divIsFrate}> */}
                {/* <div> */}
                <div className={classes.divCheckboxes}>
                  <FormControlLabel
                    classes={{ root: classes.formControlLabel }}
                    control={
                      <Checkbox
                        checked={coleg.isFrate}
                        onChange={e =>
                          setColeg({ ...coleg, isFrate: e.target.checked })
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">{`Este ${
                        coleg.sex === "B" ? `frate` : `soră`
                      }`}</Typography>
                    }
                  />
                  {/* <FormControlLabel
                    classes={{ root: classes.formControlLabel }}
                    control={
                      <Checkbox
                        checked={coleg.isNeaparat}
                        onChange={e =>
                          setColeg({ ...coleg, isNeaparat: e.target.checked })
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {`Sunt dispus${
                          coleg.sex === "F" ? `ă` : ``
                        } să renunț la gradul de confort pentru
                        coleg${coleg.sex === "F" ? `ă` : ``}`}
                      </Typography>
                    }
                  /> */}
                </div>

                {/* </div> */}

                {/* </div> */}
              </div>
            </>
          ) : (
            <TextFieldCustom
              error={error}
              fullWidth
              helperText={
                cod.length > 0
                  ? "Codul de coleg nu este încă validat. Apăsați butonul din lateral pentru validare."
                  : ""
              }
              label="Cod coleg"
              setError={setError}
              setValue={setCod}
              value={cod}
              variant="outlined"
              disabled={disabled === true ? disabled : undefined}
              // icon={FaceIcon}
            />
          )}

          <Box padding="1rem">
            {isLoading ? (
              <CircularProgress disableShrink />
            ) : coleg ? (
              <IconButton onClick={handleClear} disabled={isLoading}>
                <ClearIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
                className={classes.submitButton}
              >
                <DoneIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        {Boolean(coleg && !isLast) && (
          <div>
            <Divider />
          </div>
        )}
      </>
    );
  }
};

function mapStateToProps(state) {
  return {};
}

const enhance = compose(
  withRouter,
  withSnackbar,
  connect(mapStateToProps, actions)
);

export default enhance(SelectColeg);
