import React, { useState } from "react";

import {
  Avatar,
  Icon,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import ObligatiePlataDialog from "./ObligatiePlataDialog";
import { getDenumireStatusPlata } from "./statusuriPlati";
import { StatusObligatiePlata } from "../../../utils/enums";
import { formatMomentDateWithoutHours } from "../../../utils/formatDate";

const useStyles = makeStyles(theme => ({
  avatarObligatie: ({ esteAchitata }) => ({
    backgroundColor: esteAchitata ? theme.palette.primary.main : undefined
  })
}));

const ObligatiePlataListItem = ({
  id,
  cuantum,
  luna,
  scadentLa,
  denumire,
  status,
  tip,
  descriere,
  deponent,
  cazareId,
  concurrencyStamp
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const esteAchitata = status === StatusObligatiePlata.ACHITAT.id;
  const esteAnulata = status === StatusObligatiePlata.ANULAT.id;
  const classes = useStyles({ esteAchitata });

  return (
    <>
      {dialogIsOpen && (
        <ObligatiePlataDialog
          isOpen={dialogIsOpen}
          setIsOpen={setDialogIsOpen}
          id={id}
          cuantum={cuantum}
          luna={luna}
          scadentLa={scadentLa}
          denumire={denumire}
          status={status}
          descriere={descriere}
          tip={tip}
          deponent={deponent}
          cazareId={cazareId}
          concurrencyStamp={concurrencyStamp}
        />
      )}
      <ListItem button onClick={() => setDialogIsOpen(true)}>
        <ListItemAvatar>
          <Avatar className={classes.avatarObligatie}>
            {esteAchitata ? (
              <Icon>check_icon</Icon>
            ) : esteAnulata ? (
              <Icon>close</Icon>
            ) : (
              <Icon>money_off</Icon>
            )}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${denumire} - ${cuantum} RON`}
          secondary={
            <>
              <Typography>{`Scadent la: ${formatMomentDateWithoutHours(
                scadentLa
              )}`}</Typography>
              <Typography>{`Status: ${getDenumireStatusPlata(
                status
              )}`}</Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};

export default ObligatiePlataListItem;
