import { normalize } from "normalizr";
import { get } from "../utils/restUtilities";
import strings from "../utils/strings";
import * as schema from "./normalizrSchema";
import {
  FETCH_SESIUNE_BY_ID_SUCCESS,
  FETCH_SESIUNI_ERROR,
  FETCH_SESIUNI_REQUESTED,
  FETCH_SESIUNI_SUCCESS,
} from "./types";

const errorObject = {
  type: FETCH_SESIUNI_ERROR,
  payload: strings.REQUEST_ERROR,
};

export const fetchSesiuni = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SESIUNI_REQUESTED });
    const res = await get("/api/sesiuni-cazare");
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_SESIUNI_SUCCESS,
          payload: normalize(res.content.reverse(), schema.sesiuni),
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
  }
};

export const fetchSesiuneById = (sesiuneId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SESIUNI_REQUESTED });
    const res = await get(`/api/sesiuni-cazare/${sesiuneId}`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_SESIUNE_BY_ID_SUCCESS,
          payload: res.content,
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
  }
};
