import React, { useState } from "react";

import { Box } from "@material-ui/core";
import CnpForm from "./CnpForm";
import IdentitateForm from "./IdentitateForm";

export default () => {
  const [step, setStep] = useState(0);

  return (
    <>
      <Box>
        {step === 0 && <CnpForm setStep={setStep} />}
        {step === 1 && <IdentitateForm />}
      </Box>
    </>
  );
};
