import {
  Avatar,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import appRoutes from "../../utils/appRoutes";
import { formatMomentDate } from "../../utils/formatDate";
import handleCopy from "../../utils/handleCopy";
import strings from "../../utils/strings";
import toTitleCase from "../../utils/toTitleCase";
import CodIdentificare from "../utils/CodIdentificare";
import LabelWithValue from "../utils/LabelWithValue";

const useStyles = makeStyles(theme => ({
  iconButton: {
    // margin: "1rem 0 1rem",
    marginLeft: "1rem",
    block: "inline"
  },
  paper: {
    padding: "2rem",
    textAlign: "initial",
    flexGrow: 1
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatarBase: {
    marginBottom: "1rem",
    backgroundColor: theme.palette.secondary.main
  },
  facultateAnCiclu: {
    display: "flex",
    alignItems: "center"
  },
  divider: {
    margin: "1rem 0 1rem"
  },
  divButton: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center"
  },
  divWarning: {
    display: "flex",
    alignItems: "center"
  },
  iconWarning: {
    marginRight: ".5rem",
    color: theme.palette.warning.main
  }
}));

// containerul pentru vizualizarea datelor unui student confirmat (in pagina de profil)

const CardIdentitate = ({
  numeComplet,
  denumireAnStudiu,
  codFacultate,
  denumireProgramStudiu,
  denumireFacultate,
  medieCazare,
  denumireCiclu,
  codColeg,
  actualizatLa,
  eDezactivat,
  formaFinantareSemestrul1,
  formaFinantareSemestrul2
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <div className={classes.header}>
          <Avatar
            className={
              classes.avatarBase
              // `${classes.avatarBase}
              // ${
              //   sex === "F"
              //     ? classes.avatarF
              //     : sex === "B"
              //     ? classes.avatarB
              //     : null
              // }`
            }
          >
            {numeComplet && numeComplet[0].toUpperCase()}
          </Avatar>
          <Typography variant="body1">{toTitleCase(numeComplet)}</Typography>

          <div className={classes.facultateAnCiclu}>
            <CodIdentificare
              codIdentificare={`${codFacultate} - ${denumireAnStudiu} ${denumireCiclu &&
                denumireCiclu[0].toUpperCase()}`}
              // color={
              //   (facultateId && culoriFacultati[facultateId]) || "secondary"
              // }
            />
            {/* <Typography
              variant="body2"
              color="textSecondary"
            >{` - ${denumireAnStudiu}${
              denumireCiclu && denumireCiclu[0].toUpperCase()
            }`}</Typography> */}
          </div>
        </div>

        <Divider variant="middle" className={classes.divider} />
        <div>
          <LabelWithValue
            label={strings.ACCOMMODATION_GRADE}
            value={medieCazare}
            margin
          />
          <LabelWithValue
            label={strings.FACULTY}
            value={`${toTitleCase(denumireFacultate)} (${codFacultate})`}
            margin
          />
          <LabelWithValue
            label={strings.PROGRAM_STUDIU}
            value={denumireProgramStudiu}
            margin
          />
          <LabelWithValue
            label={strings.STUDY_YEAR}
            value={denumireAnStudiu}
            margin
          />
           <LabelWithValue
            label={strings.FORMA_FINANTARE_SEMESTRUL_1}
            value={formaFinantareSemestrul1}
            margin
          />
           <LabelWithValue
            label={strings.FORMA_FINANTARE_SEMESTRUL_2}
            value={formaFinantareSemestrul2}
            margin
          />
          <Divider variant="middle" className={classes.divider} />

          {codColeg && (
            <>
              <Box component="span" display="flex" alignItems="center">
                <Tooltip title="Necesar la completarea cererilor celorlalți utilizatori care doresc să vă adauge ca și coleg">
                  <Box display="inline">
                    <LabelWithValue
                      label={strings.YOUR_CODE}
                      value={codColeg}
                      margin
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={strings.COPY_YOUR_CODE}>
                  <IconButton
                    size="small"
                    className={classes.iconButton}
                    onClick={() => handleCopy(codColeg, enqueueSnackbar)}
                    color="primary"
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}

          <Divider variant="middle" className={classes.divider} />
          <LabelWithValue
            label={strings.IDENTITATE_ACTUALIZAT_LA}
            value={formatMomentDate(actualizatLa)}
            margin
          />

          {eDezactivat && (
            <>
              <div className={classes.divWarning}>
                <Icon className={classes.iconWarning}>warning</Icon>
                <Typography variant="body2" display="inline">
                  {strings.IDENTITATE_DEZACTVATA}
                </Typography>
              </div>
              <div className={classes.divButton}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Icon>update</Icon>}
                  onClick={() => history.push(appRoutes.UPDATE_PROFILE)}
                >
                  {strings.ACTUALIZEAZA_IDENTITATEA}
                </Button>
              </div>
            </>
          )}
        </div>
      </Paper>
    </>
  );
};

export default CardIdentitate;
