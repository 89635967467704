import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../actions";
import strings from "../utils/strings";
import { Box, Link, Typography, Divider, Paper } from "@material-ui/core";
import { APP_SET_TITLE, APP_SET_APP_BAR_BACK_LOCATION } from "../actions/types";
import appRoutes from "../utils/appRoutes";

const Info = props => {
  useEffect(() => {
    props.setAction(strings.INFO, APP_SET_TITLE);
    props.setAction(appRoutes.HOME, APP_SET_APP_BAR_BACK_LOCATION);
    return () => {
      props.setAction(null, APP_SET_APP_BAR_BACK_LOCATION);
    };
  }, []);

  return (
    <>
      <Box textAlign="center">
        <Paper elevation={4}>
          <Box padding="1rem">
            <Box margin="1rem 0 1rem">
              <Typography variant="h5">Resurse utile</Typography>
            </Box>
            {/* <Box margin="2rem 0 0" textAlign="center">
              <Link
                href="https://consilierstudenti.ase.ro/calendarul-studentului-2022-2023/"
                target="_blank"
              >
                Calendarul Studentului 2022-2023
              </Link>
            </Box> */}
            <Box margin="1rem 0 1rem" textAlign="center">
              <Link
                href="https://cazare.ase.ro/wp-content/uploads/2024/04/Calend-de-Cazare-2024-2025.pdf"
                target="_blank"
              >
                Calendarul de Cazare 2024-2025
              </Link>
            </Box>
            <Box margin="1rem 0 1rem" textAlign="center">
              <Link
                href="https://cazare.ase.ro/wp-content/uploads/2024/04/Metod-Cazare-2024_2025.pdf"
                target="_blank"
              >
                Metodologia privind cazarea studenților în caminele ASE
                București în anul universitar 2024-2025
              </Link>
            </Box>
          </Box>
        </Paper>
        <Box margin="2rem 0 0">
          <Paper elevation={4}>
            <Box padding="1rem">
              <Box margin="1rem 0 1rem">
                <Typography variant="h5">
                  Ghid pentru depunerea cererilor de cazare
                </Typography>
              </Box>
              <Box margin="1rem 0 0 " textAlign="left">
                <Box margin="1rem 1rem 1rem">
                  <Typography variant="h6">
                    <b>1. Crearea și accesarea contului</b>
                  </Typography>
                </Box>
                <Typography>
                  <li>
                    Accesați secțiunea{" "}
                    <b>
                      <i>Înregistrare</i>
                    </b>{" "}
                    din pagina principală sau din meniul sus-dreapta.
                  </li>
                </Typography>
                <Typography>
                  <li>
                    Completați formularul de înregistrare cu adresa de mail și
                    parola.
                  </li>
                </Typography>
                <Typography>
                  <li>Confirmați contul prin link-ul primit în mail.</li>
                </Typography>
                <Typography>
                  <li>
                    Accesați secțiunea{" "}
                    <b>
                      <i>Autentificare</i>
                    </b>{" "}
                    din pagina principală sau din meniul sus-dreapta și
                    introduceți datele de acces.
                  </li>
                </Typography>
                <Box margin="1rem 1rem 1rem">
                  <Typography variant="h6">
                    <b>2. Validarea identității de student</b>
                  </Typography>
                </Box>
                <Typography>
                  <li>
                    Accesați secțiunea{" "}
                    <b>
                      <i>Profilul tău</i>
                    </b>{" "}
                    din pagina principală sau din meniul sus-stânga.
                  </li>
                </Typography>
                <Typography>
                  <li>
                    Apăsați butonul{" "}
                    <b>
                      <i>Completează</i>
                    </b>{" "}
                    din secțiunea <i>Validează identitatea de student.</i>
                  </li>
                </Typography>
                <Typography>
                  <li>
                    Completați codul numeric personal și datele necesare din
                    următorul formular.
                  </li>
                </Typography>
                <Typography>
                  <li>
                    Vizualizați identitatea de student în pagina de profil.
                  </li>
                </Typography>
                <Box margin="1rem 1rem 1rem">
                  <Typography variant="h6">
                    <b>3. Depunerea cererilor</b>
                  </Typography>
                </Box>
                <Typography>
                  <li>
                    Accesați secțiunea{" "}
                    <b>
                      <i>Sesiuni de cazare</i>
                    </b>{" "}
                    din pagina principală sau din meniul sus-stânga.
                  </li>
                </Typography>
                <Typography>
                  <li>Selectați sesiunea de cazare.</li>
                </Typography>
                <Typography>
                  <li>Alegeți opțiunile de cămin.</li>
                </Typography>
                <Typography>
                  <li>
                    Introduceți unul sau mai multe coduri de coleg pentru a vă
                    exprima preferința în privința colegilor.
                  </li>
                </Typography>
                <Typography>
                  <li>
                    Vizualizați, modificați sau ștergeți cererea din pagina
                    aferentă sesiunii de cazare.
                  </li>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

function mapStateToProps (state) {
  return {};
}

const enhance = compose(connect(mapStateToProps, actions));

export default enhance(Info);
