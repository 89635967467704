import {
  APP_SET_TITLE,
  APP_SET_APP_BAR_BACK_LOCATION,
  APP_SWITCH_COLOR_MODE,
  APP_SET_GOOGLE_ANALYTICS_CONSENT,
} from "./types";

export const setTitle = (data) => (dispatch) =>
  dispatch({ type: APP_SET_TITLE, payload: data });

export const setBackLocation = (data) => (dispatch) =>
  dispatch({ type: APP_SET_APP_BAR_BACK_LOCATION, payload: data });

export const switchColorMode = () => (dispatch) =>
  dispatch({ type: APP_SWITCH_COLOR_MODE });

export const setGoogleAnalyticsConsent = (consent) => (dispatch) =>
  dispatch({ type: APP_SET_GOOGLE_ANALYTICS_CONSENT, payload: consent });
