import {
  FETCH_COMPLEXE_REQUESTED,
  FETCH_COMPLEXE_BY_SESIUNE_ID_SUCCESS,
  FETCH_COMPLEXE_ERROR,
} from "../actions/types";

const DEFAULT_STATE = {
  bySesiuneId: {},
  error: null,
  isFetching: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPLEXE_REQUESTED:
      return { ...state, isFetching: true };

    case FETCH_COMPLEXE_BY_SESIUNE_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        bySesiuneId: {
          ...state.bySesiuneId,
          [action.payload.sesiuneId]: action.payload.complexe,
        },
      };

    case FETCH_COMPLEXE_ERROR:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return { ...state };
  }
};
