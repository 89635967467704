import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import strings from "../utils/strings";
import { Box, Avatar, CircularProgress, Button } from "@material-ui/core";

import ListItemCustom from "../components/utils/ListItemCustom";
import { setTitle } from "../actions/app";
import { fetchCereri } from "../actions";
import Container from "../components/utils/Container";
import { formatMomentDate } from "../utils/formatDate";
import { useHistory } from "react-router-dom";
import HistoryIcon from "@material-ui/icons/History";
import { getCereri } from "../reducers";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cereri = useSelector((state) => getCereri(state));
  const cereriIsFetching = useSelector((state) => state.cereri.isFetching);

  useEffect(() => {
    dispatch(setTitle(strings.REQUESTS_HISTORY));
    dispatch(fetchCereri());
  }, []);

  const handleClickCereri = (id) => {
    history.push(`${history.location.pathname}/${id}`);
  };

  return (
    <>
      {(cereri && cereri.length) || !cereriIsFetching ? (
        <Container
          emptyStateTitle={strings.ISTORIC_CERERI_EMPTY}
          searchField="id"
          items={cereri}
          mapFunction={(cerere, i) => (
            <ListItemCustom
              key={i}
              id={cerere.id}
              action={handleClickCereri}
              avatar={
                <Avatar>
                  <HistoryIcon />
                </Avatar>
              }
              primaryText={
                cerere.sesiuneCazare ? cerere.sesiuneCazare.denumire : "Sesiune"
              }
              secondaryText={formatMomentDate(cerere.inregistratLa)}
            />
          )}
        />
      ) : cereriIsFetching ? (
        <Box textAlign="center">
          <CircularProgress disableShrink />
        </Box>
      ) : null}
    </>
  );
};
