import { Button, CardActions, Paper, Typography } from "@material-ui/core";
import React from "react";

import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setGoogleAnalyticsConsent } from "../../actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1rem",
    position: "fixed",
    bottom: "0",
    width: "100%",
    zIndex: "1000",
    "-webkit-box-shadow": "0px -15px 30px 0px rgba(0,0,0,0.75)",
    // "-moz-box-shadow": "0px -20px 50px 0px rgba(0,0,0,0.75",
    "box-shadow": "0px -15px 30px 0px rgba(0,0,0,0.75)",
  },
  divButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginLeft: "2rem",
  },
}));

const CookiesBanner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Typography variant="body1">This website uses cookies</Typography>
        <Typography variant="body2" color="textSecondary">
          We use cookies to analyse our traffic.
        </Typography>
        <div className={classes.divButtons}>
          <Button
            color="primary"
            onClick={() => dispatch(setGoogleAnalyticsConsent(false))}
          >
            Use necessary cookies only
          </Button>
          <Button
            className={classes.button}
            autoFocus
            color="primary"
            variant="contained"
            onClick={() => dispatch(setGoogleAnalyticsConsent(true))}
          >
            Allow all
          </Button>
        </div>
      </Paper>
    </>
  );
};

export default CookiesBanner;
