import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadState } from "./utils/localStorage";

import { DEFAULT_STATE as appDefaultState } from "./reducers/app";

export default () => {
  const persistedState = loadState();
  const middlewares = [reduxThunk];

  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  return createStore(
    reducers,
    { app: { ...appDefaultState, ...persistedState?.app } },
    composedEnhancers
  );
};
