export default {
  REGIE: 1,
  DAUNE: 2,
  ALTELE: 3,
};

export const getDenumireTipPlata = (tip) => {
  switch (tip) {
    case 1:
      return "Regie";
    case 2:
      return "Daune";
    case 3:
      return "Altele";
    default:
      return "-";
  }
};
