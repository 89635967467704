import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { setIdentiateDeValidat } from "./../../actions/";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const IdentitateListItem = ({ identitate, index, setStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const facultati = useSelector((state) => state.profile.facultati);
  const aniStudiu = useSelector((state) => state.profile.aniStudiu);

  const facultate =
    facultati?.find((facultate) => facultate.id === identitate.facultateId) ||
    null;
  const codFacultate = facultate?.codFacultate || "";
  const programStudiu = facultate?.programeStudiu?.find(
    (program) => program.id === identitate.programStudiuId
  );
  const denumireProgramStudiu = programStudiu?.denumire || "";
  const initialaDenumireCiclu = programStudiu?.ciclu?.denumire?.charAt(0) || "";

  const anStudiu = aniStudiu?.find((e) => e.id === identitate.anStudiuId);
  const denumireAnStudiu = anStudiu?.denumire || "";

  const medieCazare = identitate.medieCazare
    ? (Math.round(identitate.medieCazare * 100) / 100).toString() // sau round
    : "-";

  const handleClickIdentiate = () => {
    dispatch(setIdentiateDeValidat(identitate));
    setStep(1);
  };

  return (
    <>
      <ListItem button onClick={handleClickIdentiate} disabled={!programStudiu}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${codFacultate} ${denumireAnStudiu} ${initialaDenumireCiclu} - ${denumireProgramStudiu}`}
          secondary={`Medie de cazare: ${medieCazare}`}
        />
      </ListItem>
    </>
  );
};

export default IdentitateListItem;
