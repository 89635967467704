import {
  FETCH_CERERI_ERROR,
  FETCH_CERERI_REQUESTED,
  FETCH_CERERE_BY_SESIUNE_ID_SUCCESS,
  STERGERE_CERERE_SUCCESS,
  CREARE_CERERE_SUCCESS,
  MODIFICARE_CERERE_SUCCESS,
  FETCH_CERERI_SUCCESS,
  FETCH_CONTRACANDIDATI_BY_SESIUNE_ID,
  FETCH_CERERE_BY_ID,
} from "./types";
import strings from "../utils/strings";
import { get, del, post, put } from "../utils/restUtilities";
import * as schema from "./normalizrSchema";
import { normalize } from "normalizr";

const errorObject = {
  type: FETCH_CERERI_ERROR,
  payload: strings.REQUEST_ERROR,
};

export const fetchCerereBySesiuneId = (sesiuneId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CERERI_REQUESTED });
    const res = await get(`/api/cereri-cazare?sesiuneId=${sesiuneId}`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CERERE_BY_SESIUNE_ID_SUCCESS,
          payload: { ...res.content, sesiuneId },
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

export const fetchCereri = () => async (dispatch) => {
  // for istoric
  try {
    dispatch({ type: FETCH_CERERI_REQUESTED });
    const res = await get(`/api/cereri-cazare`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CERERI_SUCCESS,
          payload: normalize(res.content, schema.cereri),
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

export const fetchCerereById = (cerereId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CERERI_REQUESTED });
    const res = await get(`/api/cereri-cazare/${cerereId}`);
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CERERE_BY_ID,
          payload: res.content,
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

export const fetchContracandidatiBySesiuneId = (sesiuneId, cerereId) => async (
  dispatch
) => {
  dispatch({ type: FETCH_CERERI_REQUESTED });
  try {
    const res = await get(
      `/api/cereri-cazare/contracandidati?sesiuneId=${sesiuneId}`
    );
    switch (res.status) {
      case 200:
        dispatch({
          type: FETCH_CONTRACANDIDATI_BY_SESIUNE_ID,
          payload: { contracandidati: res.content, cerereId },
        });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

// operatii cerere

export const creareCerere = (data) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CERERI_REQUESTED });
    const res = await post(`/api/cereri-cazare/`, data);
    switch (res.status) {
      case 200:
      case 201:
        dispatch({ type: CREARE_CERERE_SUCCESS, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

export const modificareCerere = (cerereId, data) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CERERI_REQUESTED });
    const res = await put(`/api/cereri-cazare/${cerereId}`, data);
    switch (res.status) {
      case 200:
      case 201:
        dispatch({ type: MODIFICARE_CERERE_SUCCESS, payload: res.content });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};

export const stergereCerere = (cerereId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CERERI_REQUESTED });
    const res = await del(`/api/cereri-cazare/${cerereId}`);
    switch (res.status) {
      case 200:
      case 204:
        dispatch({ type: STERGERE_CERERE_SUCCESS, payload: cerereId });
        break;
      default:
        throw new Error();
    }
  } catch (err) {
    dispatch(errorObject);
    return Promise.reject(strings.REQUEST_ERROR);
  }
};
