import { Box, Button, Fade, Icon, makeStyles, Typography } from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import HistoryIcon from "@material-ui/icons/History";
import InfoIcon from "@material-ui/icons/Info";
import ListIcon from "@material-ui/icons/List";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setTitle } from "../actions/app";
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";
import authService from "../components/api-authorization/AuthorizeService";
import Chat from "../components/utils/Chat";
import ParticlesComponent from "../components/utils/ParticlesComponent";
import appRoutes from "../utils/appRoutes";
import strings from "../utils/strings";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: "center",
    // minWidth: 250,
    // color: "black",
    width: "80%",
    // border: "1px solid black",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10
    // backgroundColor: theme.palette.primary.light
  },
  marginTop: {
    marginTop: "2rem"
  },
  button: {
    // display: "block",
    marginTop: "1.5rem",
    width: "12rem"
  }
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAuthed, setIsAuthed] = useState(null);
  const [email, setEmail] = useState(null);
  const history = useHistory();

  const getIsAuthenticated = async () => {
    let isAuthenticated = await authService.isAuthenticated();
    setIsAuthed(isAuthenticated);
    if (isAuthenticated) {
      let user = await authService.getUser();
      if (user) {
        setEmail(user.name);
      }
    } else {
      setEmail(undefined);
    }
  };
  useEffect(() => {
    getIsAuthenticated();
    dispatch(setTitle(strings.APP_NAME));
  }, []);

  return (
    <>
      <Box textAlign="center">
        <Chat />
        <ParticlesComponent />

        <div className={classes.text}>
          <Fade in={true} timeout={{ enter: 800, exit: 0 }}>
            <Box padding="0rem">
              <Typography variant="h4">Cazare ASE</Typography>

              <Typography className={classes.marginTop} variant="body1">
                Portal pentru depunerea de cereri de cazare
              </Typography>

              {isAuthed !== null && email !== null ? (
                isAuthed === true ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignContent="center"
                  >
                    <Typography className={classes.marginTop} variant="body1">
                      Ești autentificat.
                    </Typography>
                    <Typography align="center" variant="body1">
                      {email}
                    </Typography>

                    <Box marginTop="2rem">
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        startIcon={<ListIcon />}
                        onClick={() => history.push(appRoutes.SESIUNI)}
                      >
                        {strings.ACTIVE_SESSIONS}
                      </Button>
                    </Box>
                    <div>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        startIcon={<LocationCityIcon />}
                        onClick={() => history.push(appRoutes.CAZARI)}
                      >
                        {strings.CAZARI}
                      </Button>
                    </div>

                    <Box>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        startIcon={<HistoryIcon />}
                        onClick={() => history.push(appRoutes.ISTORIC_CERERI)}
                      >
                        {strings.REQUESTS_HISTORY}
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        startIcon={<FaceIcon />}
                        onClick={() => history.push(appRoutes.PROFILE)}
                      >
                        {strings.YOUR_PROFILE}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        startIcon={<InfoIcon />}
                        onClick={() => history.push(appRoutes.INFO)}
                      >
                        {strings.INFO}
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box margin="2rem">
                    <Typography variant="body1">
                      Nu ești autentificat
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignContent="center"
                    >
                      <Box marginTop="2rem">
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="contained"
                          startIcon={<Icon>exit_to_app</Icon>}
                          onClick={() => history.push(ApplicationPaths.Login)}
                        >
                          {strings.LOGIN}
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="contained"
                          startIcon={<PersonAddIcon />}
                          onClick={() =>
                            history.push(ApplicationPaths.Register)
                          }
                        >
                          {strings.REGISTER}
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="contained"
                          startIcon={<InfoIcon />}
                          onClick={() => history.push(appRoutes.INFO)}
                        >
                          {strings.INFO}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )
              ) : null}
            </Box>
          </Fade>
        </div>
      </Box>
    </>
  );
};
