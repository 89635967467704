import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//MUI
import {
  Paper,
  TablePagination,
  Box,
  Fade,
  Divider,
  Grid,
  InputBase,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//others
import strings from "../../utils/strings";
import Search from "./Search";
import applyAllFilters from "../../utils/applyAllFilters";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    //paddingBottom: "1rem",
    //padding: 20,
    minHeight: "calc(100vh - 12rem)",
    // minWidth: 300,
    display: "flex",
    flexDirection: "column",
    // paddingBottom: 0,
    position: "relative",
  },
  tablePaginationSpacer: {
    display: "none",
  },
  tablePaginationToolbar: {
    paddingRight: 16,
    flexWrap: "wrap",
    justifyContent: "center",
  },
  tablePaginationActions: {
    marginRight: 20,
  },
  paginationContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    // flexGrow: 1,
    // alignItems: "flex-end"
  },
  itemsContainer: {
    marginBottom: "4rem",
    flexGrow: 1,
    // marginTop: 20,
    padding: 20,
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: 100
    // }
  },
}));

const Container = (props) => {
  const classes = useStyles();
  //received from props
  const {
    mapFunction,
    // reducedHeight,
    // hasFab,
    searchField,
    filters,
    // additionalTopComponent,
    // additionalTopButton,
    // tags,
    emptyStateTitle,
    emptyStateText,
    emptyStateAction,
    // title
  } = props;
  //internal items
  const [items, setItems] = useState([]);
  //pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //filtering info (salvate pentru momentul in care se apeleaza functia ce aplica toate filterele incremental)
  const [selectedSearch, setSelectedSearch] = useState("");

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onChangeSearch = (e) => {
    if (searchField) {
      setSelectedSearch(e.target.value);
      setItems(
        applyAllFilters(
          props.items, //default items
          { field: searchField, value: e.target.value }
        )
      );
      setPage(0);
    }
  };

  return (
    <>
      <Paper className={classes.paperContainer}>
        {/* ############# FILTER ############ */}
        <Box margin="-1.71875rem 1rem 1rem 1rem">
          <Search onChangeSearch={onChangeSearch} />
        </Box>

        {/* ############# ITEMS DISPLAY ############ */}
        {props.items && props.items.length ? (
          items && items.length ? (
            <div className={classes.itemsContainer}>
              {items
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(mapFunction)}
            </div>
          ) : (
            <Box padding="2rem">
              <Typography align="center" variant="h6">
                {strings.NO_FILTER_RESULTS}
              </Typography>
            </Box>
          )
        ) : (
          <Box padding="2rem">
            <Typography align="center" variant="h6">
              {emptyStateTitle ? emptyStateTitle : ""}
            </Typography>
            <Box padding="2rem">
              {emptyStateAction && (
                <Typography align="center" variant="body1">
                  <Link to={emptyStateAction}>
                    {emptyStateText ? emptyStateText : ""}
                  </Link>
                </Typography>
              )}
            </Box>
          </Box>
        )}

        {/* ############# PAGINATION ############ */}
        <Box className={classes.paginationContainer}>
          <TablePagination
            classes={{
              actions: classes.tablePaginationActions,
              spacer: classes.tablePaginationSpacer,
              toolbar: classes.tablePaginationToolbar,
            }}
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            labelRowsPerPage="Rânduri pe pagină"
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Container;
