import {
  Avatar,
  Chip,
  LinearProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import SchoolIcon from "@material-ui/icons/School";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchSesiuni, setTitle } from "../../actions";
import { getSesiuni } from "../../reducers";
import colorMode from "../../utils/colorMode";
import { TipSesiune } from "../../utils/enums";
import { formatMomentDate } from "../../utils/formatDate";
import strings from "../../utils/strings";
import Container from "../utils/Container";
import ListItemCustom from "../utils/ListItemCustom";

const esteCerintaAnul3Licenta = (cerinta) => {
  if (cerinta?.anStudiu?.id === 3 && cerinta?.tipCicluStudii?.id === 1) {
    return true;
  }
  return false;
}

const useStyles = makeStyles(theme => ({
  chip: {
    margin: "4px 4px 4px",
    fontSize: ".75rem",
    padding: "4px"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
}));

export default () => {
  const classes = useStyles();
  // const sessions = props.accommodationSessions;

  const dispatch = useDispatch();
  const history = useHistory();

  const sesiuni = useSelector(state => getSesiuni(state));
  const isFetching = useSelector(state => state.sesiuni.isFetching);
  const error = useSelector(state => state.sesiuni.error);
  const selectedColorMode = useSelector(state => state.app.colorMode);

  useEffect(() => {
    dispatch(setTitle(strings.ACTIVE_SESSIONS));
    dispatch(fetchSesiuni());
  }, []);

  const handleClickLocation = id => {
    history.push(`${history.location.pathname}/${id}`);
  };

  if (!isFetching && error && (!sesiuni || sesiuni.length === 0)) {
    return (
      <>
        <p>Eroare</p>
      </>
    );
  } else if (!isFetching || (isFetching && sesiuni && sesiuni.length > 0)) {
    return (
      <>
        <Container
          emptyStateTitle={strings.EMPTY_SESSIONS}
          searchField="denumire"
          items={sesiuni}
          mapFunction={(sesiune, i) => {
            // console.log("sesiune :>> ", sesiune);
            const arrayCerinte =
              sesiune.cerinte && sesiune.cerinte.length
                ? sesiune.cerinte
                  .sort(
                    (a, b) =>
                      a.tipCicluStudii.id - b.tipCicluStudii.id ||
                      a.anStudiu.id - b.anStudiu.id
                  )
                  .map(
                    cerinta =>
                      `${cerinta?.anStudiu?.id > 0 ? "An " : ""}${cerinta.anStudiu.denumire
                      } ${cerinta.tipCicluStudii.denumire}${esteCerintaAnul3Licenta(cerinta) && sesiune.estePentruAnulUniversitarUrmator ? ' DREPT' : ''}`
                  )
                : [];
            return (
              <ListItemCustom
                key={i}
                action={handleClickLocation}
                id={sesiune.id}
                avatar={
                  <Avatar className={classes.avatar}>
                    {sesiune.tip === TipSesiune.CAZARE.id ? "C" : "R"
                      // sesiune.denumire.charAt(0)
                    }
                  </Avatar>
                }
                primaryText={`[${TipSesiune.getName(sesiune.tip)}] ${sesiune.denumire
                  }`}
                secondaryText={
                  <>
                    <Typography component="span" variant="body2">
                      {`Start valabilitate: ${formatMomentDate(
                        sesiune.dataStartValabilitate
                      )}`}
                    </Typography>
                    <Typography
                      display="block"
                      component="span"
                      variant="body2"
                    >{`Stop valabilitate: ${formatMomentDate(
                      sesiune.dataStopValabilitate
                    )}`}</Typography>
                    {arrayCerinte.map(cerinta => (
                      <Chip
                        size="small"
                        className={classes.chip}
                        label={cerinta}
                        variant="outlined"
                        // variant={
                        //   selectedColorMode === colorMode.LIGHT
                        //     ? "outlined"
                        //     : undefined
                        // }
                        color="primary"
                        icon={<SchoolIcon />}
                      />
                    ))}
                  </>
                }
              />
            );
          }}
        />
      </>
    );
  } else {
    return <LinearProgress />;
  }
};
