import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CommentIcon from "@material-ui/icons/Comment";
import FaceIcon from "@material-ui/icons/Face";
import MessageIcon from "@material-ui/icons/Message";
import PublishIcon from "@material-ui/icons/Publish";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  creareCerere,
  fetchComplexeBySesiuneId,
  modificareCerere,
  setBackLocation,
  setTitle
} from "../../actions";
import appRoutes from "../../utils/appRoutes";
import arrayHasDistinctElements from "../../utils/arrayHasDistinctElements";
import { Motiv } from "../../utils/enums";
import {
  acceptedFileFormats,
  maxSize,
  uploadFile
} from "../../utils/filesUtils";
import { snackbarError, snackbarSuccess } from "../../utils/snackbarVariants";
import strings from "../../utils/strings";
import { RequestSchema } from "../../utils/validations";
import AlertDialog from "../utils/AlertDialog";
import ExtendedFab from "../utils/ExtendedFab";
import File from "../utils/File";
import FilesWrapper from "../utils/FilesWrapper";
import TextFieldCustom from "../utils/TextFieldCustom";
import HeaderWithDivider from "./../utils/HeaderWithDivider";
import SelectColeg from "./SelectColeg";
import SelectComplex from "./SelectComplex";
import DescriptionIcon from "@material-ui/icons/Description";
import Alert from "@material-ui/lab/Alert";
import LabelWithValue from "../utils/LabelWithValue";
import moment from 'moment'

const uploads = [
  {
    id: 1,
    format: "ALL"
  },
  {
    id: 2,
    format: "ALL"
  },
  {
    id: 3,
    format: "ALL"
  }
];

const useStyles = makeStyles(theme => ({
  formControl: {
    display: "flex",
    flexGrow: 1,
    margin: "0.5rem 1rem 0.5rem 1rem",
    minWidth: 200
  },
  paper: {
    padding: "1rem"
  },
  inputImage: {
    display: "none"
  },
  imageLabel: {
    margin: "0 0 .5rem"
  },
  labelImageButton: {
    marginBottom: 0,
    textAlign: "center"
    // width: "fit-content",
  },
  labelImageButtonSpan: {
    width: "160px"
  }
}));

const RequestForm = ({
  cerere,
  setIsEditing,
  numarMinimOptiuniCazare,
  medieCazare,
  formaFinantareSemestrul1,
  formaFinantareSemestrul2,
  actualizatLa,
  isUploadEnabled
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const sesiune = useSelector(state => state.sesiuni.byId[id])

  // const motiveOptions = Motiv.getOptions()
  // console.log(sesiune.motive)
  const motiveOptions = sesiune.motive?.length ? sesiune.motive.map(motiv => ({ value: motiv.id, label: motiv.denumire })).map(option => ({ ...option, icon: <DescriptionIcon /> })) : []
  const isMotivRequired = motiveOptions.length > 0

  const [motivId, setMotivId] = useState(cerere && cerere.motivId ? cerere.motivId : null);
  const complexes = useSelector(state => state.complexe.bySesiuneId[id]) || [];

  const [coleg1, setColeg1] = useState(
    cerere && cerere.preferinteColeg.length > 0
      ? cerere.preferinteColeg[0]
      : null
  );
  const [coleg2, setColeg2] = useState(
    cerere && cerere.preferinteColeg.length > 1
      ? cerere.preferinteColeg[1]
      : null
  );
  const [coleg3, setColeg3] = useState(
    cerere && cerere.preferinteColeg.length > 2
      ? cerere.preferinteColeg[2]
      : null
  );
  const [coleg4, setColeg4] = useState(
    cerere && cerere.preferinteColeg.length > 3
      ? cerere.preferinteColeg[3]
      : null
  );

  const [complex1, setComplex1] = useState(
    cerere && cerere.optiuniCazare.length > 0
      ? cerere.optiuniCazare[0].id
      : null
  );
  const [camine1, setCamine1] = useState(
    cerere && cerere.optiuniCazare.length > 0
      ? cerere.optiuniCazare[0].spatiiCazare
      : null
  );
  const [complex2, setComplex2] = useState(
    cerere && cerere.optiuniCazare.length > 1
      ? cerere.optiuniCazare[1].id
      : null
  );
  const [camine2, setCamine2] = useState(
    cerere && cerere.optiuniCazare.length > 1
      ? cerere.optiuniCazare[1].spatiiCazare
      : null
  );
  const [complex3, setComplex3] = useState(
    cerere && cerere.optiuniCazare.length > 2
      ? cerere.optiuniCazare[2].id
      : null
  );
  const [camine3, setCamine3] = useState(
    cerere && cerere.optiuniCazare.length > 2
      ? cerere.optiuniCazare[2].spatiiCazare
      : null
  );

  const [observatii, setObservatii] = useState(
    cerere && cerere.observatii ? cerere.observatii : ""
  );

  const [observatiiError, setObservatiiError] = useState("");
  const [gdpr, setGdpr] = useState(false);

  // dialog
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [dialogIsLoading, setDialogIsLoading] = useState(false);
  const [caminError, setCaminError] = useState("");
  const [spatiiCazareIsLoaded, setSpatiiCazareIsLoaded] = useState(false);
  const [motivIdError, setMotivIdError] = useState("");

  //upload
  // const [uploadedFiles, setUploadedFiles] = useState([]); //{id: ..., uri: ..., file: ...} id === uploadId, uri === id from the uploadFile, file === file for upload
  // const [uploadedFilesError, setUploadedFilesError] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]); //the NEWLY uploaded files from user's computer; [{file: ..., src: ...}] --- src is ONLY for preview
  const [mediaContentError, setMediaContentError] = useState("");
  // const [mediaContents, setMediaContents] = useState([])

  // CDM
  useEffect(() => {
    const fetchData = async () => {
      // get complexe
      await dispatch(fetchComplexeBySesiuneId(id));

      setSpatiiCazareIsLoaded(true);
    };
    fetchData();
    if (cerere) {
      dispatch(setTitle("Modifică cererea"));
      dispatch(setBackLocation(() => setIsEditing(false)));
    } else {
      // creating
      dispatch(setTitle("Depune cererea"));
      dispatch(setBackLocation(appRoutes.SESIUNI));
    }
    return () => {
      dispatch(setBackLocation(null));
    };
  }, []);

  let countColegi = 0;
  if (coleg1) {
    countColegi++;
  }
  if (coleg2) {
    countColegi++;
  }
  if (coleg3) {
    countColegi++;
  }
  if (coleg4) {
    countColegi++;
  }

  useEffect(() => {
    if (coleg1 || coleg2 || coleg3 || coleg4) {
      let array = new Array();
      array.push(coleg1);
      array.push(coleg2);
      array.push(coleg3);
      array.push(coleg4);
      let myArr = array.filter(e => e !== null);
      myArr[0] ? setColeg1(myArr[0]) : setColeg1(null);
      myArr[1] && myArr[1].id !== myArr[0].id
        ? setColeg2(myArr[1])
        : setColeg2(null);
      myArr[2] && myArr[2].id !== myArr[1].id
        ? setColeg3(myArr[2])
        : setColeg3(null);
      myArr[3] && myArr[3].id !== myArr[2].id
        ? setColeg4(myArr[3])
        : setColeg4(null);
    }
  }, [coleg1, coleg2, coleg3, coleg4]);

  const catchErrors = err => {
    setDialogIsOpen(false);
    enqueueSnackbar(strings.VALIDATION_ERROR, snackbarError);
    const errors = err.inner;
    let pathMarker = "";
    errors.forEach(error => {
      if (error.path !== pathMarker) {
        switch (error.path) {
          case "observatii":
            setObservatiiError(error.message);
            break;
          default:
            break;
        }
      }
      pathMarker = error.path;
    });
  };

  const handleSubmit = async () => {
    let enoughOptionsSelected = false;
    switch (numarMinimOptiuniCazare) {
      case 3:
        enoughOptionsSelected = camine1 && camine2 && camine3;
        break;
      case 1:
      default:
        enoughOptionsSelected = !!camine1
        break;
    }

    if (!enoughOptionsSelected) {
      let errorText = ''
      if (numarMinimOptiuniCazare === 1) {
        errorText = "Selectează cel puțin o opțiune de cazare, pornind cu \"Prioritate 1\"";
      } else {
        errorText = `Selectează cel puțin ${numarMinimOptiuniCazare ?? 1} opțiuni de cazare`
      }
      setCaminError(errorText);
      enqueueSnackbar(errorText, snackbarError);
      setDialogIsOpen(false);
      return;
    }

    let arrayCamine = [];
    if (camine1) {
      arrayCamine.push(...camine1);
    }
    if (camine2) {
      arrayCamine.push(...camine2);
    }
    if (camine3) {
      arrayCamine.push(...camine3);
    }

    const camineIds = arrayCamine.filter(e => e !== null).map(e => e.id);
    if (!arrayHasDistinctElements(camineIds)) {
      const errorText = strings.COMPLETE_ACCOMMODATION_REQUEST_SPACES_DISTINCT
      setCaminError(errorText);
      enqueueSnackbar(errorText, snackbarError);
      setDialogIsOpen(false);
      return
    }

    if (isMotivRequired && !motivId) {
      const errorText = strings.REASON_REQUIRED
      setMotivIdError(errorText);
      enqueueSnackbar(errorText, snackbarError);
      setDialogIsOpen(false);
      return
    }

    RequestSchema.validate({ observatii }, { abortEarly: false })
      .then(submit)
      .catch(catchErrors);
  };

  const submit = async res => {
    let arrayCamine = [];
    if (camine1) {
      arrayCamine.push(...camine1);
    }
    if (camine2) {
      arrayCamine.push(...camine2);
    }
    if (camine3) {
      arrayCamine.push(...camine3);
    }
    const data = {
      sesiuneCazareId: Number(id),
      optiuniCazare: arrayCamine.filter(e => e !== null).map(e => e.id),
      preferinteColeg: [coleg1, coleg2, coleg3, coleg4]
        .filter(e => e && e.id)
        // .map((e) => e.id),
        .map(e => ({
          studentId: e.id,
          isFrate: e.isFrate,
          isNeaparat: e.isNeaparat
        })),
      observatii: res.observatii,
      motivId: isMotivRequired ? motivId : undefined
    };
    if (cerere) {
      //update
      setDialogIsLoading(true);

      await dispatch(modificareCerere(cerere.id, data))
        .then(() => {
          enqueueSnackbar("Cerere modificată", snackbarSuccess);
          setIsEditing(false);
          setDialogIsOpen(false);
        })
        .catch(err => {
          enqueueSnackbar(err, snackbarError);
        });
      setDialogIsLoading(false);
    } else {
      //create
      setDialogIsLoading(true);

      const uploads = await uploadFiles();

      await dispatch(creareCerere({ ...data, uploads }))
        .then(res => {
          enqueueSnackbar("Cerere creată", snackbarSuccess);
        })
        .catch(err => {
          console.log("err :>> ", err);
          enqueueSnackbar(err, snackbarError);
        });
      setDialogIsLoading(false);
    }
  };

  const uploadFiles = async () => {
    let uploads = [];
    if (uploadedFiles && uploadedFiles.length > 0) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i].file) {
          // console.log("uploadedFiles[i].file :>> ", uploadedFiles[i].file);
          await uploadFile("/api/info/documente", uploadedFiles[i].file)
            .then(res => {
              // console.log("res :>> ", res);
              if (res && res.id) {
                uploads.push({ id: res.id, comentariu: "" });

                //   r.uploads[i].uri = res.id;
                //   r.uploads[i].name = res.name;
                // } else {
                //   r.uploads[i].uri = "";
                //   r.uploads[i].name = "";
              }

              // delete r.uploads[i]["file"];
            })
            .catch(err => {
              console.log("err :>> ", err);
              // delete r.uploads[i]["file"];
              // r.uploads[i].uri = "";
              // r.uploads[i].name = "";
            });
        } else {
          // delete r.uploads[i]["file"];
          // r.uploads[i].name = "";
        }
      }
    }

    return uploads;
  };

  //upload
  const onChangeFile = async e => {
    // console.log("e.target :", e.target.files);
    let fileList = e.target.files;

    // setMediaContentError("");

    if (fileList && fileList.length) {
      if (uploadedFiles.length + fileList.length > 3) {
        //mediaContents.length +
        setMediaContentError("Max 3 fișiere");
        enqueueSnackbar("Max 3 fișiere", snackbarError);
      } else {
        function getBase64 (file) {
          const reader = new FileReader();
          return new Promise(resolve => {
            reader.onload = ev => {
              resolve(ev.target.result);
            };
            reader.readAsDataURL(file);
          });
        }

        // const promises = [];
        let src = "";
        let newUploadedFiles = [...uploadedFiles];
        let alreadyUploadedByNameNew = false;
        // let alreadyUploadedByNameExisting = false;
        let alreadyUploadedBySrc = false;
        for (let i = 0; i < fileList.length; i++) {
          alreadyUploadedByNameNew = false;
          // alreadyUploadedByNameExisting = false;
          alreadyUploadedBySrc = false;

          if (fileList[i].size > maxSize.size) {
            setMediaContentError(`Max ${maxSize.name} / fișier`);
            enqueueSnackbar(`Max ${maxSize.name} / fișier`);
          } else {
            if (acceptedFileFormats.ALL.includes(fileList[i].type)) {
              // promises.push(getBase64(fileList[i]));
              src = await Promise.resolve(getBase64(fileList[i]));

              alreadyUploadedByNameNew = newUploadedFiles.find(
                f => f.file.name === fileList[i].name
              );

              // alreadyUploadedByNameExisting = mediaContents.find(
              //   (m) => m.name === fileList[i].name
              // );

              if (!alreadyUploadedByNameNew) {
                // && !alreadyUploadedByNameExisting
                alreadyUploadedBySrc = newUploadedFiles.find(
                  f => f.src === src
                );

                if (!alreadyUploadedBySrc) {
                  newUploadedFiles.push({ file: fileList[i], src });
                }
              }
            }
          }
        }

        // console.log("newUploadedFiles :>> ", newUploadedFiles);

        setUploadedFiles(newUploadedFiles);
      }
    }
  };

  const handleDeleteFile = (existingOrNewMediaContent, type) => {
    setMediaContentError("");
    // console.log("existingOrNewMediaContent", existingOrNewMediaContent);
    if (type === "existing") {
      // console.log("existing :>> ");
      // newMediaContents = [...mediaContents];
      // newMediaContents = newMediaContents.filter(
      //   (m) => m.id !== existingOrNewMediaContent.id
      // );
      // setMediaContents(newMediaContents);
    } else if (type === "new") {
      // console.log("uploadedFiles", uploadedFiles);
      let newUploadedFiles = [...uploadedFiles];

      newUploadedFiles = newUploadedFiles.filter(
        f => f.src !== existingOrNewMediaContent.src
      );
      setUploadedFiles(newUploadedFiles);
    }
  };

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Box marginBottom="1rem">
          <Typography variant="h5">Depunerea cererii</Typography>
        </Box>

        <Alert severity='warning'>
          <div>
            Atenție, verifică corectitudinea mediei de cazare și a formei de finanțare înainte de a trimite cererea de cazare.
          </div>
          <div style={{ marginTop: '.5rem' }}>
            Media și formele de finanțare mai pot suferi modificări în urma unor sincronizări ulterioare.
          </div>
        </Alert>
        {medieCazare && <LabelWithValue margin label={'Media ta de cazare'} value={medieCazare} />}
        {formaFinantareSemestrul1 &&
          <LabelWithValue margin label={strings.FORMA_FINANTARE_SEMESTRUL_1} value={formaFinantareSemestrul1} />}
        {formaFinantareSemestrul2 &&
          <LabelWithValue margin label={strings.FORMA_FINANTARE_SEMESTRUL_2} value={formaFinantareSemestrul2} />}
        {actualizatLa && <LabelWithValue margin label={strings.ACTUALIZAT_LA} value={moment(actualizatLa).format('DD.MM.YYYY')} />}

        {/* {formaFinantareSemestrul1 && <div>
          {`${strings.FORMA_FINANTARE_SEMESTRUL_1}: ${formaFinantareSemestrul1}`}</div>}
        {formaFinantareSemestrul2 && <div>
          {`${strings.FORMA_FINANTARE_SEMESTRUL_2}: ${formaFinantareSemestrul2}`}</div>} */}

        <HeaderWithDivider
          title="Opțiuni de cazare"
          icon={ApartmentIcon}
          // secondaryTitle={
          //   medieCazare ? `Media ta de cazare: ${medieCazare} ` : undefined
          // }
          description={`Alege minim ${numarMinimOptiuniCazare} ${numarMinimOptiuniCazare > 1
            ? " spații de cazare"
            : " spațiu de cazare"
            } în ordinea priorității`}
        />
        {/* ########## LISTA OPTIUNI CAMINE ########## */}

        <Grid container justifyContent="space-around">
          {spatiiCazareIsLoaded ? (
            <>
              {/* <p>Camine incarcate</p> */}
              <Grid item xs={12} md={4}>
                <SelectComplex
                  label={strings.PRIORITY_1}
                  options={complexes}
                  fullWidth={true}
                  value={complex1}
                  setValue={setComplex1}
                  camine={camine1}
                  setCamine={setCamine1}
                  setCaminError={setCaminError}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {complexes.length > 1 && (
                  <SelectComplex
                    label={strings.PRIORITY_2}
                    options={complexes}
                    fullWidth={true}
                    value={complex2}
                    setValue={setComplex2}
                    camine={camine2}
                    setCamine={setCamine2}
                    setCaminError={setCaminError}
                    disabled={complexes.length < 2}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {complexes.length > 1 && (
                  <SelectComplex
                    label={strings.PRIORITY_3}
                    options={complexes}
                    fullWidth={true}
                    value={complex3}
                    setValue={setComplex3}
                    camine={camine3}
                    setCamine={setCamine3}
                    setCaminError={setCaminError}
                    disabled={complexes.length < 2}
                  />
                )}
              </Grid>

              {/* <SelectComplex options={props.complexes} />
                <SelectComplex options={props.complexes} /> */}
            </>
          ) : (
            <CircularProgress disableShrink />
          )}
        </Grid>

        <Box margin="0.5rem">
          <Typography variant="body2" align="center" color="error">
            {caminError}
          </Typography>
        </Box>

        <HeaderWithDivider
          title={`${strings.COLEGI_DE_CAMERA}`}
          icon={FaceIcon}
          secondaryTitle={countColegi ? countColegi : undefined}
          description={`${strings.COMPLETE_ACCOMMODATION_REQUEST_COLLEAGUES}. ${strings.COD_COLEG_LOCATIE}`}
        />

        {/* ########## LISTA PREFERINTE COLEGI ########## */}
        <Box marginTop="1rem" textAlign="center">
          {/* <Typography variant="body1" gutterBottom color="textSecondary">
            {strings.COMPLETE_ACCOMMODATION_REQUEST_COLLEAGUES}
          </Typography>
          <Typography variant="body2" gutterBottom color="textSecondary">
            {strings.COMPLETE_ACCOMMODATION_REQUEST_COLLEAGUES_2}
          </Typography> */}

          <SelectColeg coleg={coleg1} setColeg={setColeg1} />
          <SelectColeg
            coleg={coleg2}
            setColeg={setColeg2}
            disabled={!(coleg1 && coleg1.id)}
          />
          <SelectColeg
            coleg={coleg3}
            setColeg={setColeg3}
            disabled={!(coleg2 && coleg2.id)}
          />
          <SelectColeg
            coleg={coleg4}
            setColeg={setColeg4}
            disabled={!(coleg3 && coleg3.id)}
            isLast
          />
          {/* <Box margin="-10px 22px 0">
            <FormHelperText>{strings.COD_COLEG_LOCATIE}</FormHelperText>
          </Box> */}
        </Box>

        {/* ############# Observatii ########### */}

        <HeaderWithDivider title={strings.OBSERVATII} icon={MessageIcon} />
        {/* <Box margin="1rem .5rem 1rem"> */}
        <TextFieldCustom
          error={observatiiError || observatii.length > 100}
          fullWidth
          helperText={`Opțional - Mențiuni suplimentare pentru cerere. Atenție, colegii luați în considerare sunt cei validați în secțiunea "Colegi de cameră". ${observatii.length > 80 ? `${observatii.length}/100 caractere` : ""
            }`}
          label="Observații"
          setError={setObservatiiError}
          setValue={setObservatii}
          value={observatii}
          multiline
          variant="outlined"
          rows={2}
          rowsMax={5}
        // icon={MessageIcon}
        />
        {/* </Box> */}

        {/* ################ Motiv ######################## */}
        {isMotivRequired &&
          <>
            <HeaderWithDivider title={strings.REASON_SESSION} icon={MessageIcon} />
            <FormControl error={Boolean(motivIdError.length)} component="fieldset">
              {/* <FormLabel component="legend">Motiv</FormLabel> */}
              <RadioGroup aria-label="motiv" name="motiv" value={motivId} onChange={(ev) => {
                setMotivIdError(""); setMotivId(Number(ev.target.value))
              }}>
                {motiveOptions.map((option, indexOption) =>
                  <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                )}
              </RadioGroup>
              <FormHelperText>{motivIdError}</FormHelperText>
            </FormControl>
          </>
        }


        {/* ########## UPLOADS ########## */}
        {!cerere && ( //not editing
          <>
            <HeaderWithDivider
              title={strings.FISIERE}
              icon={CloudUploadIcon}
              description={strings.UPLOADS_DESCRIPTION}
            />
            <Box margin="1rem">
              <Box display="flex" alignItems="center">
                <Box
                  // width="fit-content"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <input
                    id="upload"
                    accept={acceptedFileFormats.ALL.join(",")}
                    className={classes.inputImage}
                    multiple
                    type="file"
                    onChange={onChangeFile}
                  />
                  <label htmlFor="upload" className={classes.labelImageButton}>
                    {/* <IconButton color="secondary" component="span">
                  <AttachFileIcon fontSize="large" />
                </IconButton> */}
                    <Button
                      variant="outlined"
                      color="secondary"
                      component="span"
                      startIcon={<CloudUploadIcon />}
                      className={classes.labelImageButtonSpan}
                    >
                      Upload
                    </Button>
                  </label>
                  <Typography component="p" variant="caption">
                    <Box component="span" color="success.main">
                      {Object.keys(acceptedFileFormats)
                        .filter(k => k !== "ALL")
                        .map(
                          k => k.substring(0, 1) + k.substring(1).toLowerCase()
                        )
                        .join(", ")}
                    </Box>
                  </Typography>
                  <Typography
                    component="p"
                    variant="caption"
                    color="error"
                  >{`Max ${maxSize.name} / fișier`}</Typography>
                </Box>

                <Box display="flex" alignItems="center" flexWrap="wrap">
                  <FilesWrapper
                    type="existing"
                    files={[
                      // ...mediaContents,
                      ...uploadedFiles.map(f => ({ ...f, isNew: true }))
                    ]}
                    handleDeleteFile={handleDeleteFile}
                  // questionId={questionView ? questionView.id : null}
                  />
                </Box>
              </Box>
            </Box>

            {/* <Button onClick={uploadFiles} variant="contained">
              TEST upload
            </Button> */}
          </>
        )}

        {/* <Box marginTop="1rem" display="flex" justifyContent="center">
          {uploads.map((e, i) => (
            <div key={i}>
              {!uploadedFiles.includes(e.id) ? (
                <FilesWrapper
                  type="placeholder"
                  files={[{ ...e, description: "" }]}
                  actionable={true}
                  onChangeFile={onChangeFile}
                  showMaxSize
                />
              ) : (
                <FilesWrapper
                  type="existing"
                  files={uploadedFiles
                    .filter((u) => u.id === e.id)
                    .map((u) => ({
                      ...u,
                      src: u.uri,
                      isNew: true,
                    }))}
                  // questionId={question.id}
                  handleDeleteFile={handleDeleteFile}
                />
              )}
            </div>
          ))}
        </Box> */}

        <Box marginTop="1rem" textAlign="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={gdpr}
                onChange={e => setGdpr(e.target.checked)}
              />
            }
            label={
              <Typography variant="body2">
                Ești de acord cu procesarea datelor tale cu caracter personal
              </Typography>
            }
          />
        </Box>
      </Paper>

      <ExtendedFab
        action={() => setDialogIsOpen(true)}
        text={strings.SUBMIT}
        icon={PublishIcon}
        disabled={dialogIsLoading || gdpr === false}
      />

      <AlertDialog
        isOpen={dialogIsOpen}
        setIsOpen={setDialogIsOpen}
        dialogTitle={strings.CONFIRM_CREATE_ACCOMMODATION_REQUEST}
        dialogContentText=""
        isLoading={dialogIsLoading}
        yesAction={handleSubmit}
      />
    </>
  );
};

export default RequestForm;
