import React from "react";
import { withRouter } from "react-router-dom";
import { ListItem, ListItemText, ListItemIcon } from "@material-ui/core";

const DrawerListItem = props => {
  const { path, text, setIsOpen } = props;
  const Icon = props.icon;

  const handleClick = () => {
    props.history.push(path);
    setIsOpen(false);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>

        <ListItemText primary={text} />
      </ListItem>
    </>
  );
};

export default withRouter(DrawerListItem);
