import {
  Box,
  Button,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patchCovid } from "../../actions";
import covidStatus from "../../utils/covidStatus";
import { StatusCovid } from "../../utils/enums";
import { formatMomentDateWithoutHours } from "../../utils/formatDate";
import { snackbarError, snackbarSuccess } from "../../utils/snackbarVariants";
import strings from "../../utils/strings";
import CheckboxCustom from "../utils/CheckboxCustom";
import SelectCustom from "../utils/SelectCustom";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "2rem",
    marginTop: "1rem"
  },

  divider: {
    margin: "1rem 0 1rem"
  }
}));

const Covid = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  //selectors
  const identitate = useSelector(state => state.profile.identitate);
  const {
    statusCovid,
    acordColegNevaccinat,
    acordColocatarAltStatus,
    raportCovidLa
  } = identitate;

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [statusCovidLocal, setStatusCovidLocal] = useState(statusCovid ?? 0);
  const [acordColegNevaccinatLocal, setAcordColegNevaccinatLocal] = useState(
    acordColegNevaccinat ?? false
  );
  const [
    acordColocatarAltStatusLocal,
    setAcordColocatarAltStatusLocal
  ] = useState(acordColocatarAltStatus ?? false);
  // console.log("acordStatusCovid :>> ", statusCovid);
  // console.log("acordColegNevaccinat :>> ", acordColegNevaccinat);
  // console.log("acordColocatarAltStatus :>> ", acordColocatarAltStatus);
  const setCombined = value => {
    setStatusCovidLocal(value);
    // console.log("Value: ", value);
    setAcordColegNevaccinatLocal(value == 2);
  };

  const handleSave = async () => {
    setIsLoading(true);
    await dispatch(
      patchCovid({
        statusCovid: Number(statusCovidLocal),
        acordColegNevaccinat: acordColegNevaccinatLocal,
        acordColocatarAltStatus: acordColocatarAltStatusLocal
      })
    )
      .then(() => {
        enqueueSnackbar("Date salvate", snackbarSuccess);
      })
      .catch(err => {
        enqueueSnackbar(err, snackbarError);
      });

    setIsLoading(false);
  };
  // console.log("statusCovid :>> ", statusCovid === StatusCovid.VACCINAT.id);
  // console.log("acordColegNevaccinat :>> ", acordColegNevaccinat);
  // console.log("acordColocatarAltStatus :>> ", acordColocatarAltStatus);
  const formIsDisabled =
    statusCovid === StatusCovid.VACCINAT.id &&
    typeof acordColegNevaccinat === "boolean" &&
    typeof acordColocatarAltStatus === "boolean";

  return (
    <>
      <Paper className={classes.paper} elevation={4}>
        <Typography variant="h6">{strings.INFO_COVID}</Typography>
        <Divider variant="middle" className={classes.divider} />
        {raportCovidLa && (
          <>
            <Typography
              variant="body2"
              gutterBottom
            >{`Ultima raportare a fost pe ${formatMomentDateWithoutHours(
              raportCovidLa
            )}`}</Typography>
            <Divider variant="middle" className={classes.divider} />
          </>
        )}

        <Typography color="textSecondary" variant="caption">
          {strings.COVID_STATUS}
        </Typography>
        <SelectCustom
          // label={strings.COVID_STATUS}
          // options={Object.keys(covidStatus).map(e => ({
          //   value: e,
          //   text: covidStatus[e]
          // }))}
          options={StatusCovid.getOptions()}
          value={statusCovidLocal}
          disabled={formIsDisabled}
          setValue={setCombined}
          fullWidth
          margin="0 0 1rem"
          // defaultOption
        />

        <CheckboxCustom
          value={acordColegNevaccinatLocal}
          setValue={setAcordColegNevaccinatLocal}
          disabled={formIsDisabled}
          label={`Sunt de acord să fiu cazat${
            identitate.sex === "F" ? `ă` : ``
          } în cameră cu o persoană nevaccinată`}
        />

        <CheckboxCustom
          value={acordColocatarAltStatusLocal}
          disabled={formIsDisabled}
          setValue={setAcordColocatarAltStatusLocal}
          label={`Doresc să fiu cazat${
            identitate.sex === "F" ? `ă` : ``
          } împreună cu persoana menționată în cererea de cazare chiar dacă nu avem același status din punct de vedere al vaccinării împotriva Covid-19`}
        />
        <br></br>
        <Typography
          color="textSecondary"
          variant="caption"
          margin="3 0 0rem"
          display="inline"
        >
          {strings.COVID_CLARIFICARE1}
        </Typography>

        {Boolean(
          Number(statusCovidLocal) !== statusCovid ||
            acordColegNevaccinatLocal !== acordColegNevaccinat ||
            acordColocatarAltStatusLocal !== acordColocatarAltStatus
        ) && (
          <Box display="flex" justifyContent="center" marginTop="2rem">
            <Button
              variant="contained"
              disabled={isLoading || formIsDisabled}
              startIcon={<Icon>save</Icon>}
              color="primary"
              onClick={handleSave}
            >
              Salvează
            </Button>
          </Box>
        )}
      </Paper>
    </>
  );
};

export default Covid;
