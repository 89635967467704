import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default ({ codIdentificare, color, minWidth }) => {
  const useStyles = makeStyles((theme) => ({
    boxColorSecondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    boxColorPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
    boxColorOther: {
      backgroundColor: color ? color : theme.palette.primary.main,
    },

    boxCodIdentificare: {
      display: "inline-block",
      padding: "4px",
      margin: "8px",
      borderRadius: "4px",
      marginRight: ".5rem",
      textAlign: "center",
    },
    codIdentificare: {
      color: "white",
    },
  }));
  const classes = useStyles();

  return (
    <Box
      minWidth={minWidth || undefined}
      className={`${classes.boxCodIdentificare} ${
        color === "primary"
          ? `${classes.boxColorPrimary}`
          : color === "secondary"
          ? `${classes.boxColorSecondary}`
          : `${classes.boxColorOther}`
      }`}
    >
      <Typography
        variant="caption"
        className={classes.codIdentificare}
        display="inline"
        color="textSecondary"
      >{`${codIdentificare}`}</Typography>
    </Box>
  );
};
