import React, { useState, useEffect } from "react";
import { Drawer } from "@material-ui/core";
import authService from "../api-authorization/AuthorizeService";

// import itemsLoggedIn from "./itemsLoggedIn";
// import itemsNotLoggedIn from "./itemsNotLoggedIn";
import DrawerList from "./DrawerList";
import { loggedInItems, notLoggedInItems } from "./drawerItems";

const TemporaryDrawer = (props) => {
  const { isOpen, setIsOpen } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const getIsAuthenticated = async () => {
    setIsAuthenticated(await authService.isAuthenticated());
  };

  useEffect(() => {
    getIsAuthenticated();
  }, []);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DrawerList
          items={isAuthenticated === true ? loggedInItems : notLoggedInItems}
          setIsOpen={setIsOpen}
        />
      </Drawer>
    </>
  );
};

export default TemporaryDrawer;
