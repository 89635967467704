import { Box, Button, Card, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  card: {
    padding: "2rem",
    margin: "1rem 0 1rem"
  },
  buttonManageAccount: {
    width: "7rem",
    height: "2.5rem"
  }
});

const SectionCard = props => {
  const classes = useStyles();
  const { title, description, actionName, action } = props;

  return (
    <Card className={classes.card} elevation={4}>
      <div style={{ width: "100%" }}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2" color="textSecondary">
              {description}
            </Typography>
          </Box>

          <Box marginLeft=".5rem">
            <Button
              className={classes.buttonManageAccount}
              variant="contained"
              color="primary"
              onClick={action}
            >
              {actionName}
            </Button>
          </Box>
        </Box>
      </div>
    </Card>
  );
};

export default SectionCard;
