import React, { useEffect } from "react";

import { Button, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

import { makeStyles } from "@material-ui/core";
import strings from "../../utils/strings";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTitle } from "../../actions/app";
import appRoutes from "../../utils/appRoutes";

const useStyles = makeStyles((theme) => ({
  divContainer: {
    padding: "2rem",
  },
  title: {
    textAlign: "center",
  },
  divButtons: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
}));

const PaymentSuccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle(strings.APP_NAME));
  }, []);

  return (
    <>
      <div>
        <Typography className={classes.title} variant="h4">
          Plata a eșuat
        </Typography>

        <div className={classes.divButtons}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={() => {
              history.push(appRoutes.HOME);
            }}
          >
            {strings.INTOARCE_TE_LA_PAGINA_PRINCIPALA}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
