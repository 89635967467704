import React from "react";
//MUI
import { makeStyles } from "@material-ui/styles";
import { Paper, Box, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  searchContainer: {
    // backgroundColor: "#e8e8e8", //theme.palette.primary.light, //#e8e8e8
    padding: 10,
    // margin: "5px 10px 25px 10px",
    borderRadius: 10,
    // width: "300px"
    // alignItems: "center"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.75),
    backgroundColor: theme.palette.background.paper,
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 1),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    // backgroundColor: theme.palette.primary.light
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "5rem",
      "&:focus": {
        width: "10rem",
      },
    },
  },
}));

const Search = (props) => {
  const classes = useStyles();
  const { onChangeSearch } = props;

  return (
    <>
      <Box className={classes.container}>
        <Paper elevation={2} className={classes.searchContainer}>
          <Box display="flex" justifyContent="center">
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                onChange={onChangeSearch}
                placeholder="Caută..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default Search;
