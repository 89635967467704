const appRoutes = {
  HOME: "/",
  INFO: "/info",
  CONTACT: "/contact",

  // profile
  PROFILE: "/profil",
  COMPLETE_PROFILE: "/profil/confirmare",
  UPDATE_PROFILE: "/profil/actualizare",
  // VIEW_PROFILE: "/profil/vizualizare",

  // sesiuni
  SESIUNI: "/sesiuni-cazare",
  SESIUNE_ACCESATA: "/sesiuni-cazare/:id",

  // cazari
  CAZARI: "/cazari",
  CAZARE_ACCESATA: "/cazari/:cazareId",

  // istoric cereri

  ISTORIC_CERERI: "/istoric-cereri",
  ISTORIC_CERERE_ACCESATA: "/istoric-cereri/:id",

  AUTO_LOG_OUT: "/mesaj-logout",

  PLATA_SUCCES: "/plata-succes",
  PLATA_EROARE: "/plata-eroare"
};

export default appRoutes;
